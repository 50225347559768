import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import AnnualReturn from "./components/AnnualReturn";

import {
  Login,
  Home,
  ReturnRefund,
  Order,
  Registration,
  SearchPage,
  Success,
  Review,
  Rating,
  Support,
  SupportNonGlenService,
  InitateWarranty,
  ProtectedRoute,
  ContactUs,
  Blank,
  Profile,
  EditAddress,
  RequestProduct,
  PrivacyPolicy,
  TermsCondition,
  ReturnRefundCondition,
  ReturnReplacement,
  AboutUS,
  ShipppingPolicy,
  EnforceFeedback,
  ForgetPassword,
} from "./components";
import ReturnedItemList from "./components/ReturnedItemList";
import AnnualReturnList from "./components/AnnualReturnList";
import AnnualReturnItems from "./components/AnnualReturnItems";
import ViewReturnItems from "./components/ViewReturnItems";
import GsmaReturnItem from "./components/GsmaReturnItem";
import GsmaReturn from "./components/GsmaReturn";
import GsmaViewReturnCart from "./components/GsmaViewReturnCart";
import GsmaReturnedList from "./components/GsmaReturnedList";
import GsmaReturnedDetail from "./components/GsmaReturnedDetail";

const RouterMain = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <ProtectedRoute exact path="/home" component={Home} />
        <Route exact path="/whatsapp/:id" component={Blank} />
        <ProtectedRoute exact path="/home/new-order" component={Home} />
        <ProtectedRoute exact path="/home/track-order" component={Home} />
        <ProtectedRoute exact path="/home/complete-order" component={Home} />

        <ProtectedRoute
          exact
          path="/home/annual-return"
          component={AnnualReturn}
        />
        <ProtectedRoute
          exact
          path="/home/annual-return-list"
          component={AnnualReturnList}
        />
        <ProtectedRoute
          exact
          path="/annual-return/:id"
          component={AnnualReturnItems}
        />

        <ProtectedRoute
          exact
          path="/home/annual-return/:id"
          component={ReturnedItemList}
        />

        <ProtectedRoute
          exact
          path="/home/annual-return/:id/view-return"
          component={ViewReturnItems}
        />

        <ProtectedRoute
          exact
          path="/home/Gsma-return/GsmaReturnItem/:id"
          component={GsmaReturnItem}
        />
        <ProtectedRoute exact path="/home/GSMA-return" component={GsmaReturn} />
        <ProtectedRoute
          exact
          path="/GsmaViewReturnCart"
          component={GsmaViewReturnCart}
        />
        <ProtectedRoute
          exact
          path="/home/gsma-return/:id"
          component={GsmaReturnedDetail}
        />
        <ProtectedRoute
          exact
          path="/home/gsma-return-list"
          component={GsmaReturnedList}
        />

        <ProtectedRoute exact path="/order/:id" component={Order} />
        <ProtectedRoute exact path="/return/:id" component={Order} />
        <ProtectedRoute
          exact
          path="/return/:orderId/:id"
          component={ReturnRefund}
        />
        <Route path="/registration" component={Registration} />
        <ProtectedRoute path="/search" component={SearchPage} />
        <ProtectedRoute path="/success" component={Success} />
        <ProtectedRoute path="/review/:orderId" component={Review} />
        <ProtectedRoute path="/rating/:orderId" component={Rating} />
        <ProtectedRoute
          path="/support/glenService-product"
          component={Support}
        />
        <ProtectedRoute
          path="/support/non-glenService-warranty"
          component={SupportNonGlenService}
        />
        <ProtectedRoute
          path="/support/OW/Initate"
          component={InitateWarranty}
        />
        <ProtectedRoute
          path="/support/IW/Initate"
          component={InitateWarranty}
        />
        <ProtectedRoute path="/edit-profile" component={Profile} />
        <ProtectedRoute path="/edit-address" component={EditAddress} />
        <ProtectedRoute path="/request-product" component={RequestProduct} />
        <ProtectedRoute
          path="/return-replacement"
          component={ReturnReplacement}
        />
        <ProtectedRoute path="/enforce-feedback" component={EnforceFeedback} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-condition" component={TermsCondition} />
        <Route path="/return-refund-policy" component={ReturnRefundCondition} />
        <Route path="/about-us" component={AboutUS} />
        <Route path="/shipping-policy" component={ShipppingPolicy} />
      </Switch>
    </Suspense>
  );
};

export default RouterMain;
