import { useHistory, useParams } from "react-router";
import { Loader } from "../shared-components";
import React, { useState, useEffect } from "react";
import { apiCAll } from "../api/api";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

const GsmaReturnedDetail = (props): JSX.Element => {
  const params = useParams();
  const history = useHistory();
  // const gsmaReturnedListData = props.location.state;
  const authToken = localStorage.getItem("token") || "";
  const [gsmaReturnedListData, setGsmaReturnedListData] = useState({});
  const [numberOfBoxes, setNumberOfBoxes] = useState<number>(1);
  const [showLoader, setShowLoader] = useState(false);
  const [returnMessage, setReturnMessage] = useState("Return has been Cancelled Successfully!");
  const [shippingData, setShippingData] = useState({
    delivery_partner: "",
    awb_no: "",
    shipping_date: "",
    logisctic: "",
    contact_person: "",
    contact_person_number: "",
    gsma_annual_return_id: Number(
      gsmaReturnedListData && gsmaReturnedListData[0]?.id
    ),
    road_permit_no: "",
    remark: "",
  });

  const [requestData, setRequestData] = useState([
    {
      box_lenght: "",
      box_width: "",
      box_height: "",
      box_weight: "",
      no_of_boxes: 0,
    },
  ]);

  const [cancelOrderBox, setCancelOrderBox] = useState(false);
  const [textBoxInputValue, setTextBoxInputValue] = useState("");
  const [showCancelSuccessfully, setShowCancelSuccessfully] = useState(false);

  const cancelOrderAPI = (orderID) => {
    let obj = {
      return_id: orderID,
      cancel_reason: textBoxInputValue,
    };
    apiCAll(obj, "/return-cancel", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowCancelSuccessfully(true)
      }
      if (x?.data?.ErrorCode === -203) {
        setReturnMessage(x?.data?.ErrorMessage)
        setShowCancelSuccessfully(true)
      }

    });
  };


  const handleCancel = () => {
    setCancelOrderBox(true);
    setShowCancelSuccessfully(false);
    setTextBoxInputValue("");
  };

  useEffect(() => {
    if (params && params["id"]) {
      getGsmaReturnedListData();
    }
  }, [params && params["id"]]);

  const getGsmaReturnedListData = async () => {
    setShowLoader(true);
    const obj = { request_no: params && params["id"] };
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/gsma-annual-return-detail", authToken)
        .then((x) => {
          console.log(x);
          if (x?.status === 200) {
            setShowLoader(false);
            setGsmaReturnedListData(x?.data?.Response);
          }
          resolve("success");
        })
        .catch((err) => {
          setShowLoader(false);
          reject("error");
          console.log(err);
        });
    });
  };

  const handleBack = () => {
    history.push("/home/gsma-return-list");
  };

  const createRequestObject = (e: any, i: number) => {
    const { name, value } = e.target;

    let temp = [...requestData];
    if(e.target.files && e.target.files[0]){
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const srcData = fileReader.result;
        console.log(srcData)
        temp[i] = { ...temp[i], [name] : `${srcData}`, no_of_boxes: numberOfBoxes };
      };
      fileReader.readAsDataURL(e?.target?.files[0]);
      // console.log(fileReader)
    }
    else{
      temp[i] = { ...temp[i], [name]: Number(value), no_of_boxes: numberOfBoxes };
    }

    setRequestData(temp);
  };

  const addPackingBoxDetails = async () => {
    setShowLoader(true);
    const obj = {
      gsma_annual_return_id: gsmaReturnedListData && gsmaReturnedListData["id"],
      annual_return_packing: [...requestData],
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/gsma-annual-return-packing", authToken)
        .then((x) => {
          if (x.data.ErrorCode === 0) {
            setShowLoader(false);
            getGsmaReturnedListData();
          }
          resolve("success");
        })
        .catch((err) => {
          reject("error");
          console.log(err);
          setShowLoader(true);
        });
    });
  };

  const addshippingDetails = async () => {
    setShowLoader(true);
    const obj = {
      ...shippingData,
      gsma_annual_return_id: gsmaReturnedListData && gsmaReturnedListData["id"],
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/gsma-annual-return-shipping", authToken)
        .then((x) => {
          if (x.data.ErrorCode === 0) {
            setShowLoader(false);
            getGsmaReturnedListData();
          }
          resolve("success");
        })
        .catch((err) => {
          setShowLoader(false);

          reject("error");
          console.log(err);
          setShowLoader(true);
        });
    });
  };

  const handleNumberOfBoxes = (num: number) => {
    setNumberOfBoxes(num);
    setRequestData([
      {
        box_lenght: "",
        box_width: "",
        box_height: "",
        box_weight: "",
        no_of_boxes: 0,
      },
    ]);
  };
  let total=0;

  return (
    <>
      {!gsmaReturnedListData || showLoader ? <Loader /> : null}
      <div className="annual-return">
        <div className="flex mx-2">
          <button className="self-center" onClick={handleBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
          Warranty Defective Return Detail
          </h3>
        </div>

        <div className="py-2 bg-white ">
          <div className=" float-right">
            {gsmaReturnedListData?.['status'] ==1 ? 
                <Popup
                closeOnDocumentClick={false}
                onClose={() => setCancelOrderBox(false)}
                trigger={
                  <button className="cursor-pointer hyperlink text-sm">
                    Cancel Request
                  </button>
                }
                modal
              >
                {(close) => (              
                    <>
                          <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                            Cancel Return Request
                            <button
                              className="close cursor-pointer  text-2xl"
                              onClick={close}
                            >
                              &times;
                            </button>
                          </div>
                          <div className="mx-2 py-1 font-bold text-base border-black  justify-between items-center">
                            {!showCancelSuccessfully ? (
                              <>
                                <div className="border-b ">
                                  <p className="text-sm display-flex self-center">
                                    Why do you want to cancel the order?{" "}
                                    {/* <button
                                      className="close cursor-pointer float-right text-2xl"
                                      onClick={close}
                                    >
                                      &times;
                                    </button> */}
                                  </p>
                                  <textarea
                                    name=""
                                    id=""
                                    value={textBoxInputValue}
                                    onChange={(e) =>
                                      setTextBoxInputValue(e?.target?.value)
                                    }
                                    placeholder="Please write the reason"
                                    className="w-full my-3  p-4 bg-white rounded-md  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                  ></textarea>
                                </div>
                                <button
                                  disabled={!textBoxInputValue}
                                  onClick={() => cancelOrderAPI(gsmaReturnedListData['id'])}
                                  className={`${
                                    !textBoxInputValue
                                      ? "disabled:opacity-50"
                                      : ""
                                  } rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full`}
                                >
                                  CONFIRM
                                </button>
                              </>
                            ) : (
                              <p>{returnMessage}</p>
                            )}
                          </div>
                        </>
                )}
              </Popup>
              :null}
          </div>
        </div>

        <div className="bg-white">
          <div className="py-10 flex justify-center">
            <div className="bg-white w-8/12">
              <b>
                Return Number - #{props?.match?.params?.id}
              </b>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="py-10 flex justify-center">
            <table className="shadow-primary bg-white w-8/12">
              {gsmaReturnedListData && gsmaReturnedListData["items"]?.length ? (
                <tr>
                  {gsmaReturnedListData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Sr.No.
                    </th>
                  ) : null}

                  {gsmaReturnedListData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Spare Name
                    </th>
                  ) : null}
                  {gsmaReturnedListData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Spare Code
                    </th>
                  ) : null}

                  {gsmaReturnedListData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Returned Quantity
                    </th>
                  ) : null}

                  {gsmaReturnedListData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      GSMA code
                    </th>
                  ) : null}
                  {gsmaReturnedListData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Price
                    </th>
                  ) : null}
                </tr>
              ) : null}
              {gsmaReturnedListData &&
                gsmaReturnedListData["items"]?.length &&
                gsmaReturnedListData["items"].map(
                  (item: any, index: number) => (
                    <tr className="tabe-row" key={index}>
                      {item ? (
                        <td className="border px-4 py-2 text-sm ">
                          {index + 1}{" "}
                        </td>
                      ) : null}

                      {item.item_name ? (
                        <td className="border px-4 py-2 text-sm">
                          {item.item_name}
                        </td>
                      ) : null}

                      {item.product_code ? (
                        <td className="border px-4 py-2 text-sm">
                          {item.product_code}
                        </td>
                      ) : null}

                      {item.quantity ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item.quantity}{" "}
                        </td>
                      ) : null}

                      {item?.gsma_id ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item.gsma_id}{" "}
                        </td>
                      ) : null}
                    <td className="border px-4 py-2 text-sm ">
                      {parseInt(item.discount_price)}{" "}
                    </td>
                    <td className="border px-4 py-2 text-sm hidden">
                    {total+=item.quantity*item.discount_price}
                    </td>                    
                    </tr>
                  )
                )}
                <tr className="tabe-row">
                  <td colSpan={5} className="border px-4 py-2 text-sm text-right">
                    Total
                  </td>
                  <td className="border px-4 py-2 text-sm ">
                    {total}                    
                  </td>
                </tr>
            </table>
          </div>
          <div className="py-10 flex justify-center" style={{zoom: "91%"}}>
            <div className="box_details_container w-8/12 ">
              <div className="box_header p-2">
                <p className="m-0">Box Details -</p>
              </div>
              <div className="box_body p-2">
                <div className="flex mt-2">
                  <p className="m-0 basic_text">No. of Boxes -</p>
                  <input
                    type="number"
                    className="ml-20 text-center input_box_width"
                    onChange={(e: any) => {
                      handleNumberOfBoxes(e.target.value);
                    }}
                    value={
                      gsmaReturnedListData &&
                      gsmaReturnedListData["box_details"] &&
                      gsmaReturnedListData["box_details"].length > 0
                        ? gsmaReturnedListData["box_details"].length
                        : numberOfBoxes
                    }
                  />
                </div>
                {gsmaReturnedListData &&
                gsmaReturnedListData["box_details"] &&
                gsmaReturnedListData["box_details"].length
                  ? gsmaReturnedListData["box_details"].map(
                      (val: {}, index: number) => {
                        return (
                          <div className="flex mt-3" key={index}>
                            <p className="m-0 mr-2 basic_text flex items-center">Box {index + 1} -</p>
                            <div className="flex ml-7 items-center">
                              <label htmlFor="" className="ml-2 basic_text">
                                {"Weight(grams)"}
                              </label>
                              <input
                                type="number"
                                name="box_weight"
                                value={val["weight"]}
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-3">
                                {"Length(cm)"}
                              </label>
                              <input
                                type="number"
                                name="box_lenght"
                                value={val["lenght"]}
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-3 basic_text">
                                {"Width(cm)"}
                              </label>
                              <input
                                type="number"
                                name="box_width"
                                value={val["width"]}
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-3 basic_text">
                                {"Height(cm)"}
                              </label>
                              <input
                                type="number"
                                name="box_height"
                                value={val["height"]}
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-2 basic_text">
                                {"Box Image"}
                              </label>                      
                              <img
                                className="ml-2 border rounded"
                                alt="Box"
                                width="100px"
                                height="90px"
                                src={val["image"]}
                              />
                            </div>
                          </div>
                        );
                      }
                    )
                  : numberOfBoxes > 0 &&
                    new Array(Number(numberOfBoxes))
                      .fill(0)
                      .map((value: number, index: number) => {
                        return (
                          <div className="flex mt-3" key={index}>
                            <div className="flex">
                              <label className="m-0 mr-2 basic_text">Box {index + 1} -</label>
                              <label htmlFor="" className="basic_text">
                                {"Weight(grams)"}
                              </label>
                              <input
                                type="number"
                                name="box_weight"
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-3 basic_text">
                                {"Length(cm)"}
                              </label>
                              <input
                                type="number"
                                name="box_lenght"
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-3 basic_text">
                                {"Width(cm)"}
                              </label>
                              <input
                                type="number"
                                name="box_width"
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="" className="ml-3 basic_text">
                                {"Height(cm)"}
                              </label>
                              <input
                                type="number"
                                name="box_height"
                                onChange={(e: any) =>
                                  createRequestObject(e, index)
                                }
                                className="ml-1 input_box_width text-center"
                              />
                              <label htmlFor="upload-button" className="ml-3 basic_text">
                                {"Image"}
                              </label>
                              <input
                                type="file"
                                name="image"
                                // style={{visibility: "hidden"}}
                                className="ml-1 image_box_width text-center"
                                id="upload-button"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={(e: any) =>{
                                  if(e?.target?.files && e.target?.files[0]?.size > 5120 * 1024){
                                    alert('Image size too big.')
                                  }else{
                                    createRequestObject(e, index)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}

                <button
                  onClick={addPackingBoxDetails}
                  disabled={
                    requestData[requestData.length - 1].box_lenght === "" ||
                    requestData[requestData.length - 1].box_width === "" ||
                    requestData[requestData.length - 1].box_height === "" ||
                    requestData[requestData.length - 1].box_weight === "" ||
                    Object.keys(requestData[requestData.length - 1]).length < 5
                  }
                  type="button"
                  className={`px-8 text-lg rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center
                  ${
                    requestData[requestData.length - 1].box_lenght === "" ||
                    requestData[requestData.length - 1].box_width === "" ||
                    requestData[requestData.length - 1].box_height === "" ||
                    requestData[requestData.length - 1].box_weight === "" ||
                    Object.keys(requestData[requestData.length - 1]).length < 5
                      ? "disabled:opacity-50"
                      : ""
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10">
                <button
                  onClick={() => history.push("/home/gsma-return-list")}
                  type="button"
                  className={`mr-5 px-8 text-lg rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center`}
                >
                  Back
                </button>
                {gsmaReturnedListData &&
                gsmaReturnedListData["delivery_challan"] === "" ? (
                  <button
                    className={`ml-5 px-8 text-lg rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center opacity-50 `}
                  >
                    Print & Download
                  </button>
                ) : (
                  <a
                    href={
                      gsmaReturnedListData &&
                      gsmaReturnedListData["delivery_challan"]
                    }
                    target="_blank"
                    className={`ml-5 px-8 text-lg rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center`}
                  >
                    Print & Download
                  </a>
                )}
          </div>
          <div className="py-10 flex justify-center" style={{zoom: "91%"}}>
            <div className="box_details_container w-8/12">
              <div className="box_header p-2">
                <p className="m-0">Shipping Details -</p>
              </div>
              <div className="box_body p-2">
                <div className="grid grid-rows-3 grid-cols-3 grid-flow-col gap-3">
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Delivery Method</p>
                    <select
                     className=" border grid-rows-6  w-1/2 rounded px-1"
                     value={gsmaReturnedListData?.["shipping_details"]?.[0]?.["delivery_method"]}       
                     onChange={(e) =>
                              setShippingData({
                                ...shippingData,
                                delivery_partner: e.target.value,
                              })
                            }
                          >
                            <option value = {"Self"}>Self</option>
                            <option value = {"Company"}>Company</option>
                    </select>
                  </div>
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">AWB No.</p>
                    <input
                      type="text"
                      className=" border w-1/2 rounded px-1"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          awb_no: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? gsmaReturnedListData["shipping_details"][0][
                              "docket_no"
                            ]
                          : shippingData.awb_no
                      }
                    />
                  </div>
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Shipping Date</p>
                    <input
                      type="date"
                      className="border rounded p-0"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          shipping_date: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? new Date(
                              `${gsmaReturnedListData["shipping_details"][0]["shipping_date"]}`
                            )
                              .toISOString()
                              .substring(0, 10)
                          : shippingData.shipping_date
                      }
                    />
                  </div>
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Delivery Partner</p>
                    <input
                      type="text"
                      className="border w-1/2 rounded px-1"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          logisctic: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? gsmaReturnedListData["shipping_details"][0][
                              "delivery_partner_name"
                            ]
                          : shippingData.logisctic
                      }
                    />
                  </div>
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Picker Name</p>
                    <input
                      type="text"
                      className="border w-1/2 rounded px-1"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          contact_person: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? gsmaReturnedListData["shipping_details"][0][
                              "contact_person"
                            ]
                          : shippingData.contact_person
                      }
                    />
                  </div>
                  {/* <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Road Permit No.</p>
                    <input
                      type="text"
                      className="border w-1/2 rounded px-1"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          road_permit_no: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? gsmaReturnedListData["shipping_details"][0][
                              "road_permit_no"
                            ]
                          : shippingData.road_permit_no
                      }
                    />
                  </div> */}
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Picker Number</p>
                    <input
                      type="number"
                      className="border w-1/2 rounded px-1"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          contact_person_number: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? gsmaReturnedListData["shipping_details"][0][
                              "contact_person_number"
                            ]
                          : shippingData.contact_person_number
                      }
                    />
                  </div>
                  <div className="flex mt-2 justify-between">
                    <p className="m-0 flex items-center basic_text">Remark</p>
                    <input
                      type="text"
                      className="border w-1/2 rounded px-1"
                      onChange={(e) =>
                        setShippingData({
                          ...shippingData,
                          remark: e.target.value,
                        })
                      }
                      value={
                        gsmaReturnedListData &&
                        gsmaReturnedListData["shipping_details"]?.length
                          ? gsmaReturnedListData["shipping_details"][0][
                              "remark"
                            ]
                          : shippingData.remark
                      }
                    />
                  </div>
                </div>
              </div>
              {/* {console.log(requestData)} */}
              <button
                type="button"
                className={`px-8 text-lg rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center
                ${
                  shippingData.delivery_partner === "" ||
                  shippingData.awb_no === "" ||
                  shippingData.shipping_date === "" ||
                  shippingData.logisctic === "" ||
                  shippingData.contact_person === "" ||
                  shippingData.contact_person_number === ""
                    ? "disabled:opacity-50"
                    : ""
                }`}
                onClick={addshippingDetails}
                disabled={
                  shippingData.delivery_partner === "" ||
                  shippingData.awb_no === "" ||
                  shippingData.shipping_date === "" ||
                  shippingData.logisctic === "" ||
                  shippingData.contact_person === "" ||
                  shippingData.contact_person_number === ""
                  // shippingData.road_permit_no === "" ||
                  // shippingData.remark === ""
                }
              >
                Save
              </button>              
            </div>
          </div>
        </div>
      </div>
      <div style={{clear:'both'}}></div>
      <style jsx>
        {`
          .basic_text{
            font-size:14px;
            line-height:30px;
            min-width:45px;
          }
          .input_alignment{
            height:30px;
            width:61%;
          }
          .input_box_width {
            width: 80px;
            height:30px;
            line-height:30px;
            border: 1px solid lightgray;
            border-radius: 6px;
          }

          .hyperlink {
            color: #0211c0;
          }
          //   .box-alignment{
          //     margin-left: 200px;
          //   }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245, 0.2);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .box_details_container .box_header {
            background: #d0e8fc;
          }
          .box_details_container .box_header p {
            font-weight: 600;
            color: blue;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
          .return_msg_box {
            outline: none !important;
            resize: none;
          }
        `}
      </style>
    </>
  );
};

export default GsmaReturnedDetail;
