import Axios from 'axios';
import { GLOBAL_API } from './constants';

export const apiCAll = async( obj, url, API_kEY = "") => {

    let header = {
        Authorization: `Bearer ${API_kEY}`
    }
    return Axios.post(`${GLOBAL_API}${url}`, obj, {headers:header}).then(x=>{
        return x
    }).catch(err=>{
        console.log('checking in api call',err)
        return err

    })
}