// export const GLOBAL_API = 'https://dev.techstreet.in/glen-service/public/api/v1';

export const GLOBAL_API = 'https://spares-admin.glenindia.com/api/v1';

export const REGEX_ONLY_DIGITS = /^\d+$/; 
export const REGEX_ONLY_DIGITS_NO = /^\d+$/; 
// export const REGEX_ONLY_DIGITS_NO = /^[6-9]{1}(?:\d+)?$/;
export const REGEX_ONLY_ALPHA = /^[a-zA-Z\s]+$/;
// export const REGEX_ONLY_EMAIL = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
export const REGEX_ONLY_EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
// export const REGEX_ONLY_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const INPUT_KEYS_NOT_ALLOWED = [
    // 'Enter',
    // 'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Meta',
    'Ctrl',
    'Delete'
]