import { useHistory } from "react-router";
import { apiCAll } from "../api/api";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { Loader } from "../shared-components"
import Delete from "../styles/icons/delete_icon.svg";

const GsmaViewReturnCart = (): JSX.Element => {
    const history = useHistory()
    const { id } = useParams() as any
    const authToken = localStorage.getItem("token") || "";
    const [showLoader, setShowLoader] = useState(false)
    const [gsmaReturnViewData, setGsmaReturnViewData] = useState([])
    const [returnMsg, setReturnMsg] = useState('')

    const handleBack = () => {
        history.push(`/home/Gsma-return`);
    };

    useEffect(() => {
        handleViewReturnCart()
    }, [])

    const handleViewReturnCart = async () => {
        const mypromise = await new Promise<String>((resolve, reject) => {
            apiCAll({}, "/gsma-cart-list", authToken)
                .then((x) => {
                    if (x.status === 200) {
                        setGsmaReturnViewData(x.data.Response)
                    }
                    resolve("success")
                })
                .catch((err) => {
                    console.log(err)
                    setShowLoader(true)
                })
        })
    }

    const handleEmptyCart = () => {
        setShowLoader(true);
        apiCAll({}, "/empty-gsma-cart", authToken).then((x) => {
            if (x?.data?.ErrorCode == 0) {

                window.location.reload();
                setShowLoader(false);
            }
        });
    };

    const HandleDeleteItem = async(itemNum) => {
        const mypromise = await new Promise((resolve, reject)=>{
            apiCAll({gsma_cart_id: itemNum}, "/gsma-product-delete", authToken)
            .then((x)=>{
                resolve("success")
            })
            .catch((err)=>{
                console.log(err)
                setShowLoader(true);
            })
        })
        if(mypromise === "success"){
            handleViewReturnCart()
        }
    }

    const HandlePlaceReturn = async() =>{
        const mypromise = await new Promise((resolve, reject)=>{
            setShowLoader(true)
            apiCAll({remark:returnMsg}, "/gsma-annual-return" , authToken)
            .then((x)=>{
                setShowLoader(false)
                // console.log(x)
                resolve("success")
            })
            .catch((err)=>{
                console.log(err)
            })
        })

        if(mypromise === "success"){
            history.push("/home/GSMA-return")
        }
    }
                
    return <>
        {showLoader ? <Loader /> : null}
        <div className="Gsma_return_cart_view">
            <div className="flex mx-2">
                <button className="self-center" onClick={handleBack}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        />
                    </svg>
                </button>
                <h3 className="text-2xl font-bold text-center py-4 flex-1">
                Warranty Defective Return Cart
                </h3>
            </div>
            <div className="bg-white h-screen pt-10">
                {gsmaReturnViewData?.length >= 0 ? (
                    <button
                        className="flex justify-end text-sm w-10/12 mb-1 hover:text-gray-600"
                        onClick={handleEmptyCart}
                    >
                        <img src="/empty-cart.png" width="20px" className="mr-1" />
                        Empty Cart
                    </button>
                ) : null}
                <div className="pb-1 flex justify-center">
                    <table className="shadow-primary bg-white w-8/12">
                        <tr>
                            <th className="border text-left px-4 py-2 text-sm">Sr.No.</th>

                            <th className="border text-left px-4 py-2 text-sm">Part Name</th>

                            <th className="border text-left px-4 py-2 text-sm">GSMA Complaint No.</th>

                            <th className="border text-left px-4 py-2 text-sm">Quantity</th>

                            <th className="border text-left px-4 py-2 text-sm">Action</th>

                        </tr>
                        {gsmaReturnViewData.length !== 0 ? gsmaReturnViewData.map((val : any, index : number) => {
                            return <tr className="tabe-row" key={index}>
                                <td className="border px-4 py-2 text-sm ">{index + 1} </td>

                                <td className="border px-4 py-2 text-sm hyperlink cursor-pointer">{val.product_name}</td>

                                <td className="border px-4 py-2 text-sm ">
                                    {/* <Link className="hyperlink" to={`/annual-return/${item.product_id}`}> */}
                                    {val.gsma_id}
                                    {/* </Link> */}
                                </td>

                                <td className="border px-4 py-2 text-sm ">{val.quantity}</td>

                                <td className="border px-4 py-2 text-sm ">
                                    <img src={Delete} alt="" className="h-6 w-full cursor-pointer"
                                        onClick={()=>{HandleDeleteItem(val.id)}}
                                    />
                                </td>
                            </tr>
                        }): <div className="tabe-row text-center py-5"> No data available</div>}
                    </table>
                </div>
                <div className="checkout return_msg_box mt-4 bg-active rounded shadow-primary p-3 mb-5 w-8/12 mx-auto">
                    <textarea name="returnMessage"
                        id="returnMessage"
                        value={returnMsg}
                        placeholder="Add a message....."
                        className="text-md w-full h-full return_msg_box"
                        onChange={(e)=>{setReturnMsg(e.target.value)}}
                    ></textarea>
                </div>
                <div className="flex justify-end mx-auto w-8/12">
                <button
                    type="button"
                    className="rounded-full bg-primary text-md h-11 text-white px-8 cursor-pointer flex items-center ml-auto mb-10"
                    onClick={HandlePlaceReturn}
                >
                    Place Return
                </button>
                </div>       
            </div>

        </div>
        <style jsx>
            {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245 , 0.20);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .Gsma_input:focus{
            outline:none !important;
          }
          .return_msg_box{
            outline:none !important;
            resize:none;
          }

          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
        </style>
    </>
}

export default GsmaViewReturnCart