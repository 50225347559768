import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import "reactjs-popup/dist/index.css";
import { apiCAll } from "../api/api";
import { useLocation, Link } from "react-router-dom";
import { Loader } from "../shared-components";
import Popup from "reactjs-popup";

const GsmaReturn = (): JSX.Element => {
  const myRef = useRef() as any;
  const [counter, setCounter] = useState({});
  const [currItem, setCurrItem] = useState({});
  const [annualReturnData, setAnnualReturnData] = useState([] as any);
  const [showLoader, setShowLoader] = useState(false);
  const [returnRequestData, setReturnRequestData] = useState([]);
  const [gsmaAnnualReturnList, setGsmaAnnualReturnList] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [returnModal, setReturnModal] = useState(false)
  const [responseMsg, setResponseMsg] = useState("")

  const [fromDate, setFromData] = useState("");
  const [toDate, setToData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const [tempData, setTempData] = useState([] as any);

  const authToken = localStorage.getItem("token") || "";

  useEffect(() => {
    handleAnnualReturn();
  }, []);

  const handlePopup = (count, item) => {
    setShowPopup(true);
    setTempData([]);
    let newData: any = [];

    setCurrItem(item);

    for (let i = 0; i < count; i++) {
      newData.push({
        id: Math.round(Math.random() * 100),
        partName: item.product_name,
        partCode: item?.product_code,
        partId: item?.product_id,
        GSMAcode: "",
        quantity: 1,
      });
    }

    setTempData(newData);
  };

  const history = useHistory();
  const handleBack = () => {
    history.push("/home");
  };

  const handleAnnualReturn = async () => {
    setShowLoader(true);
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-gsma-items-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            let response = x?.data?.Response;
            response = Object.values(response);
            let tempCount = {};
            for (let i = 0; i < response?.length; i++) {
              tempCount[i] = 0;
              response[i].GSMAcode = "";
            }

            setCounter(tempCount);
            setAnnualReturnData(response);
            setShowLoader(false);
          }
          resolve("success");
        })
        .catch((err) => {
          reject("error");
          setShowLoader(true);
        });
    });

    if (myPromise === "success") {
      handleAnnualReturnRequest();
      getGsmaAnnualReturnList();
    }
  };

  const handleAnnualReturnRequest = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setReturnRequestData(x?.data?.Response);
            resolve("success");
          }
        })

        .catch((err) => {
          reject("error");
          console.log(err);
          setShowLoader(true);
        });
    });
  };

  const getGsmaAnnualReturnList = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      setShowLoader(true);
      apiCAll({}, "/gsma-annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setShowLoader(false);
            setGsmaAnnualReturnList(x.data.Response);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(true);
        });
    });
  };
  const filteredList = async () => {
    setShowLoader(true);
    const myPromise = await new Promise<String>((resolve, reject) => {
      const obj = { from_date: fromDate, to_date: toDate };
      apiCAll(obj, "/gsma-annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setShowLoader(false);
            setGsmaAnnualReturnList(x.data.Response);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    });
  };

  const addToReturnCart = async (item: any, index) => {
    const obj = {
      order_id: item.order_id,
      product_id: counter[index],
      quantity: item.quantity,
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/return-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const updateEditCount = (product, index) => {
    setCounter({ ...counter, [index]: Number(inputValue) });

    // addToReturnCart(product, index);
  };

  const decrement = (product, index) => {
    if (counter[index] === 0) {
      setCounter({ ...counter, [index]: counter[index] });
    } else {
      setCounter({ ...counter, [index]: Number(counter[index]) - 1 });
    }
    // addToReturnCart(product, index);
  };

  const increment = (product, index) => {
    if (product?.quantity > counter[index]) {
      setCounter({ ...counter, [index]: Number(counter[index]) + 1 });
    } else {
      setCounter({ ...counter, [index]: counter[index] });
    }

    // addToReturnCart(product, index);
  };

  const handleGSMAcode = (val: any, index, productCode) => {
    const updatedTempData = [...tempData] as any;
    const selectedObj = updatedTempData[index];
    selectedObj.GSMAcode = val;
    setTempData(updatedTempData);
    let gsmacodeList = updatedTempData?.map((val, i) => val?.GSMAcode);
    let copyData = [...annualReturnData];
    console.log(productCode);
    for (let i = 0; i < annualReturnData?.length; i++) {
      if (annualReturnData[i]?.product_code === productCode) {
        copyData[i].GSMAcode = gsmacodeList;
      } else {
        // copyData[i].GSMAcode = "";
        copyData[i].GSMAcode = copyData[i].GSMAcode ? copyData[i].GSMAcode : ''        
      }
    }

    setAnnualReturnData(copyData);
  };

  const handleDataFilter = (e) => {
    if (e) {
      const copyAnnualReturnData = [...annualReturnData];
      const data = copyAnnualReturnData?.filter((val: any, index) =>
        e ? 
        (val?.product_name?.toLowerCase().indexOf(e.toLowerCase()) >= 0 || val?.product_code?.indexOf(e) >= 0)
        : true 
      );
      setAnnualReturnData(data);
    } else {
      handleAnnualReturn();
    }
  };

  const handleGsmaReturn = async (item) => {
    console.log(item)
    setShowPopup(false);
    const data: any = [];

    for (let i = 0; i < tempData?.length; i++) {
      data.push({
        product_id: item?.product_id,
        gsma_number: tempData[i].GSMAcode,
      });
    }

    const obj = {
      gsma_product: [...data],
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/gsma-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(true);
        });
    });

    if (myPromise === "success") {
      // handleAnnualReturn();
      setTimeout(() => {
        // setCartUpdated(true);
      }, 1200);
    }
  };

  const returnSpareRequest = async () => {
    var obj = {
      gsma_return_items: annualReturnData,
    };

    const mypromise = await new Promise<String>((resolve, response) => {
      apiCAll(obj, "/gsma-annual-return", authToken)
        .then((x) => {
          if (x.data.ErrorCode === 103) {
            setResponseMsg(x.data.ErrorMessage +" Request No is " +x.data.Response.request_no)
            setReturnModal(true)
          }
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="annual-return">
        <div className="flex mx-2">
          <button
            className="self-center"
            onClick={handleBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Warranty Defective Spare Return Request Raise
          </h3>
        </div>
        <div className="bg-white pt-4">
          <div className="w-8/12 mx-auto block">
            <label
              htmlFor="#"
              className="mr-3"
            >
              Search Spare name/code:
            </label>
            <input
              type="text"
              className="border w-1/4 rounded px-4 input_style"
              placeholder="Search....."
              onChange={(e) => handleDataFilter(e.target.value)}
            />
            <div className="text-right">
              <Link
                className="text-sm hyperlink underline cursor-pointer"
                to={{
                  pathname: "/home/gsma-return-list",
                }}
              >
                Warranty Defective Return List
              </Link>
            </div>
          </div>
          <div className="py-10 flex justify-center">
            <table className="shadow-primary bg-white w-8/12">
              {annualReturnData?.length ? (
                <tr>
                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Sr.No.
                    </th>
                  ) : null}

                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Spare Name
                    </th>
                  ) : null}
                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Spare Code
                    </th>
                  ) : null}

                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Annual Quantity
                    </th>
                  ) : null}
                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Return Quantity
                    </th>
                  ) : null}
                  {annualReturnData?.some(
                    (val, indx) => val?.GSMAcode !== ""
                  ) ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      GSMA code
                    </th>
                  ) : null}
                </tr>
              ) : (
                <span>No Data Found </span>
              )}
              {annualReturnData.length
                ? annualReturnData.map((item: any, index) => (
                    <tr
                      className="tabe-row"
                      key={index}
                    >
                      {item.product_id ? (
                        <td className="border px-4 py-2 text-sm ">
                          {index + 1}{" "}
                        </td>
                      ) : null}

                      {item.product_name ? (
                        <td
                          className="border px-4 py-2 text-sm hyperlink cursor-pointer"
                          onClick={() => {
                            history.push({
                              // pathname: `/home/Gsma-return/GsmaReturnItem/${item.product_id}`,
                              pathname: `/GsmaViewReturnCart`,
                              state: item,
                            });
                          }}
                        >
                          {item.product_name}
                        </td>
                      ) : null}
                      {item.product_code ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item?.product_code}
                        </td>
                      ) : null}

                      {item.quantity ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item.quantity}{" "}
                        </td>
                      ) : null}
                      {
                        <td className="border-x-1 sm: border text-center  px-2 py-2 text-sm pt-4 w-64">
                          <div className="text-center flex justify-around">
                            <span
                              className="cursor-pointer self-center"
                              onClick={() => decrement(item, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M20 12H4"
                                />
                              </svg>
                            </span>
                            {/* {item.quantity} */}
                            <Popup
                              className="search-popup"
                              trigger={
                                <button className="text-primary  cursor-pointer text-sm">
                                  {counter[index] || 0}
                                </button>
                              }
                              modal
                            >
                              {(close) => (
                                <div className="modal">
                                  <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                    Enter the Quantity here{" "}
                                    <button
                                      className="close cursor-pointer  text-2xl"
                                      onClick={close}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="content mx-2 my-2">
                                    <div className="mb-3">
                                      <input
                                        min="0"
                                        type="number"
                                        className="phone bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                        placeholder="Quantity"
                                        value={inputValue}
                                        onChange={(e) =>
                                          setInputValue(e?.target?.value)
                                        }
                                      />
                                    </div>

                                    <button
                                      className={
                                        " rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full " +
                                        (inputValue > item.quantity
                                          ? "opacity-20"
                                          : null)
                                      }
                                      onClick={() => {
                                        if (inputValue) {
                                          updateEditCount(item, index);
                                        }
                                        close();
                                        setInputValue("");
                                      }}
                                      disabled={inputValue > item.quantity}
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                            <span
                              className="cursor-pointer self-center"
                              onClick={() => increment(item, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 4v16m8-8H4"
                                />
                              </svg>
                            </span>
                            <button
                              type="button"
                              className={`px-3 text-sm rounded bg-primary text-white font-semibold py-1 d-flex content-center rounded`}
                              onClick={() => handlePopup(counter[index], item)}
                              disabled={counter[index] === 0}
                            >
                              Fill Code
                            </button>
                            <Popup
                              className="search-popup"
                              contentStyle={{ width: "55%" }}
                              ref={myRef}
                              open={showPopup}
                              modal
                            >
                              {showPopup ? (
                                <div className="modal">
                                  <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                    Enter the GSMA code here{" "}
                                    <button
                                      className="close cursor-pointer  text-2xl"
                                      onClick={() => setShowPopup(false)}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="content mx-2 my-2">
                                    <div className="py-4 flex justify-center">
                                      <table className="shadow-primary bg-white w-full">
                                        <tr>
                                          <th className="border text-left px-4 py-2 text-sm">
                                            Sr.No.
                                          </th>

                                          <th className="border text-left px-4 py-2 text-sm">
                                            Spare Name
                                          </th>

                                          <th className="border text-left px-4 py-2 text-sm">
                                            Spare Code
                                          </th>

                                          <th className="border text-left px-4 py-2 text-sm">
                                            Quantity
                                          </th>

                                          <th className="border text-left px-4 py-2 text-sm">
                                            {" "}
                                            GSMA Service Booking No.
                                          </th>
                                        </tr>
                                        {item ? (
                                          tempData.map((val, i) => {
                                            return (
                                              <tr
                                                className="tabe-row"
                                                key={i}
                                              >
                                                <td className="border px-4 py-2 text-sm ">
                                                  {i + 1}{" "}
                                                </td>

                                                <td className="border px-4 py-2 text-sm hyperlink cursor-pointer">
                                                  {val.partName}
                                                </td>

                                                <td className="border px-4 py-2 text-sm ">
                                                  {val.partCode}
                                                </td>

                                                <td className="border px-4 py-2 text-sm ">
                                                  {val.quantity}
                                                </td>
                                                <td className="border px-4 py-2 text-sm ">
                                                  <input
                                                    type="text"
                                                    className="Gsma_input bg-transparent w-full"
                                                    style={{
                                                      borderBottom:
                                                        "1px solid gray",
                                                    }}
                                                    value={val.GSMAcode}
                                                    onChange={(e) => {
                                                      handleGSMAcode(
                                                        e.target.value,
                                                        i,
                                                        val.partCode
                                                      );
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <div className="my-4 bg-white text-center">
                                            No Data Available
                                          </div>
                                        )}
                                      </table>
                                    </div>
                                    <div className="w-full mx-auto">
                                      {console.log(tempData)}
                                      {tempData?.every(
                                        (val, i) => val.GSMAcode != ""
                                      ) ? (
                                        
                                        <button
                                          type="button"
                                          onClick={() => handleGsmaReturn(currItem)}
                                          className="rounded-full bg-primary text-md h-11 text-white px-8 cursor-pointer ml-auto flex items-center mr-5"
                                        >
                                          Add to return
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </Popup>
                          </div>
                        </td>
                      }
                      {item?.GSMAcode ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item?.GSMAcode.map(
                            (val, ind) =>
                              `${
                                ind === item.GSMAcode.length - 1
                                  ? item?.GSMAcode[ind] && item?.GSMAcode[ind]
                                  : item?.GSMAcode[ind] &&
                                    item?.GSMAcode[ind] + ", "
                              }`
                          )}{" "}
                        </td>
                      ) : null}
                    </tr>
                  ))
                : null}
            </table>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="px-8 text-sm rounded bg-primary text-white font-semibold py-2 my-5 d-flex content-center  rounded input_style"
              onClick={returnSpareRequest}
            >
              Place GSMA Retun
            </button>
          </div>
          {returnModal ? (
              <Popup
                  onClose={handleAnnualReturn}
                  open
                  modal
              >
                  {() => (
                      <div className="modal">
                          <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                              Status
                              <button
                                  className="close cursor-pointer  text-2xl"
                                  onClick={() => { setReturnModal(false);handleAnnualReturn(); }}
                              >
                                  &times;
                              </button>
                          </div>

                          <div className="content mx-2 my-2 py-10">
                              <p className="text-center">{responseMsg}</p>
                              <div className="button-list flex  justify-around">
                                  <button className="contact-support rounded-full bg-white text-xs h-10 w-1/3 mt-4 font-bold shadow-secondary text-primary">
                                      <Link to="/home/gsma-return-list">View GSMA Returns</Link>
                                  </button>
                              </div>
                          </div>
                      </div>
                  )}
              </Popup>
          ) : null}
          {cartUpdated ? (
            <div
              className="green-alert text-center w-full bottom-16 fixed px-4 py-3 rounded  animate__animated animate__fadeOut animate__delay-1s"
              role="alert"
            >
              <strong>Cart updated successfully!</strong>
            </div>
          ) : null}
        </div>
      </div>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245, 0.2);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .link_text {
            color: #0211c0;
          }
          .input_style {
            height: 40px;
            line-height: 25px;
            margin-right: 10px;
          }

          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default GsmaReturn;
