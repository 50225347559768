import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { Loader } from "../../src/shared-components"
import { Link } from "react-router-dom"
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { apiCAll } from '../api/api'

const ViewReturnItems = (): JSX.Element => {

    const authToken = localStorage.getItem("token") || "";

    let { id }: any = useParams();

    const [showLoader, setShowLoader] = useState(false)
    const [returnCartlistData, setReturnCartlistData] = useState([]) as any
    const [returnModal, setReturnModal] = useState(false)
    const [responseMsg, setResponseMsg] = useState("")
    const [inputValue, setInputValue] = useState('')

    const history = useHistory()

    const handleBack = () => {
        history.push(`/annual-return/${id}`)
    }

    useEffect(() => {
        getCartlistData()
    }, [])

    const getCartlistData = async () => {
        const mypromise = await new Promise<String>((resolve, reject) => {
            apiCAll({}, "/return-cart-list", authToken).then((x) => {
                if (x.status === 200) {
                    let createAnnualItemArr = x?.data?.Response.map((v: any) => {
                        return {
                            ...v,
                            returnQuantity: v.quantity
                        }
                    })
                    setReturnCartlistData(createAnnualItemArr)
                }
                resolve('success')

            })
                .catch((err) => {
                    console.log(err)
                    setShowLoader(true)
                })
        })
    }

    const setReturnItems = async () => {

        let returnItemsArr: any = [];
        returnCartlistData.map((v) => {
            let newObj = {
                order_id: v.order_id,
                item_id: v.product_id,
                quantity: v.quantity,
                return_type: 3
            }

            returnItemsArr.push(newObj)
        })

        var obj = {
            annual_return_items: returnItemsArr
        }

        const mypromise = await new Promise<String>((resolve, response) => {
            apiCAll(obj, "/return-placeorder", authToken)
                .then((x) => {
                    if (x.data.ErrorCode === 103) {
                        setResponseMsg(x.data.ErrorMessage +" Request No is " +x.data.Response.request_no)
                    }
                    resolve("success")
                })
                .catch((err) => {
                    console.log(err)
                })
        })

        if (mypromise === "success") {

            setReturnModal(true)
        }
    }

    const getTotalQuantity = () => {
        let copyReturnCartlistData = [...returnCartlistData];
        let findQuantity = copyReturnCartlistData.map((v: any) => Number(v.quantity));
        let finalQuantityResult = findQuantity.reduce((partialSum, a) => partialSum + a, 0);
        return finalQuantityResult;
    }

    const increment = (index: number) => {

        let copyReturnCartlistData = [...returnCartlistData]
        let findItemByIndex = copyReturnCartlistData[index];
        findItemByIndex.quantity = Number(findItemByIndex.returnQuantity) <= Number(findItemByIndex.quantity) ? Number(findItemByIndex.quantity) : Number(findItemByIndex.quantity) + 1;
        setReturnCartlistData(copyReturnCartlistData)
    };

    const decrement = (index: number) => {
        let copyReturnCartlistData = [...returnCartlistData]
        let findItemByIndex = copyReturnCartlistData[index];
        findItemByIndex.quantity = Number(findItemByIndex.quantity) > 0 ? Number(findItemByIndex.quantity) - 1 : 0;
        setReturnCartlistData(copyReturnCartlistData)
    };

    const updateEditCount = (index) => {
        let copyReturnCartlist = [...returnCartlistData]
        let findDataByIndex = copyReturnCartlist[index]
        if (findDataByIndex.returnQuantity >= inputValue) {
            findDataByIndex.quantity = inputValue;
            setReturnCartlistData(copyReturnCartlist)
            setInputValue('');
        }
    }

    return (
        <>
            {showLoader ? <Loader /> : null}
            <div className="annual-return_item">
                <div className="flex mx-2">
                    <button className="self-center" onClick={handleBack}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                        </svg>
                    </button>
                    <h3 className="text-2xl font-bold text-center text-dark py-4 flex-1">
                        Return Item's Cartlist
                    </h3>
                </div>

                <div className="bg-white h-screen">
                    <div className="pt-10 flex justify-center">
                        <table className="shadow-primary bg-white w-8/12">
                            {returnCartlistData ? (
                                <tr>
                                    {returnCartlistData ? (
                                        <th className="border text-left px-4 py-2 text-sm">Sr.No.</th>
                                    ) : null}

                                    {returnCartlistData ? (
                                        <th className="border text-left px-4 py-2 text-sm">Order Id</th>
                                    ) : null}

                                    {returnCartlistData ? (
                                        <th className="border text-left px-4 py-2 text-sm">Spare Name</th>
                                    ) : null}

                                    {returnCartlistData ? (
                                        <th className="border text-left px-4 py-2 text-sm">Return Quantity</th>
                                    ) : null}
                                </tr>
                            ) : null}
                            {returnCartlistData.map((item: any, index) => (
                                <tr className="tabe-row" key={index}>
                                    {returnCartlistData.length ? (
                                        <td className="border px-4 py-2 text-sm ">{index + 1} </td>
                                    ) : null}

                                    <td className="border px-4 py-2 text-sm">
                                        {item.order_id}
                                    </td>
                                    <td className="border px-4 py-2 text-sm">
                                        {item.product_name}
                                    </td>

                                    <td className="border px-4 py-2 text-sm ">
                                        <div className="text-center flex justify-around">
                                            {item.quantity}
                                            {/* <span
                                                className="cursor-pointer self-center"
                                                onClick={() => decrement(index)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4 cursor-pointer"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M20 12H4"
                                                    />
                                                </svg>
                                            </span>
                                            <Popup
                                                className="search-popup"
                                                trigger={
                                                    <button className="text-primary  cursor-pointer text-sm">
                                                        {item.quantity}
                                                    </button>
                                                }
                                                modal
                                            >
                                                {(close) => (
                                                    <div className="modal">
                                                        <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                                            Enter the Quantity here{" "}
                                                            <button
                                                                className="close cursor-pointer  text-2xl"
                                                                onClick={close}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                        <div className="content mx-2 my-2">
                                                            <div className="mb-3">
                                                                <input
                                                                    min="0"
                                                                    type="number"
                                                                    className="phone bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                                                    placeholder="Quantity"
                                                                    value={inputValue}
                                                                    onChange={(e) =>
                                                                        setInputValue(e?.target?.value)
                                                                    }
                                                                />
                                                                {responseMsg ? (
                                                                    <p
                                                                        className="text-green-500"
                                                                    >
                                                                        {responseMsg}
                                                                    </p>
                                                                ) : null}
                                                            </div>

                                                            <button
                                                                className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full"
                                                                onClick={() => {
                                                                    if (inputValue) {
                                                                        updateEditCount(index);
                                                                    }
                                                                    close();
                                                                    setInputValue('');
                                                                }

                                                                }
                                                            >
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                            <span
                                                className="cursor-pointer self-center"
                                                onClick={() => {
                                                    increment(index);
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4 cursor-pointer"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 4v16m8-8H4"
                                                    />
                                                </svg>
                                            </span> */}
                                        </div>
                                    </td>

                                </tr>
                                
                                
                                ))}
                                {returnCartlistData.length === 0 ? <><tr></tr><tr className='py-5 text-sm'>No Data Available</tr><tr></tr></> : null}
                      </table>
                    </div>
                    <div className="pb-10 flex justify-center">
                        <div className=" w-8/12">
                            <div className="checkout return_msg_box mt-4 bg-active rounded shadow-primary p-3">
                                <textarea name="returnMessage" id="returnMessage" placeholder="Add a message....." className="text-md w-full h-full return_msg_box"></textarea>
                            </div>
                            <div className="checkout mt-4 bg-active rounded shadow-primary p-3 flex justify-between">
                                <div className="amount flex items-center">
                                    <p className="text-lg">
                                        Total Quantity <span className="font-bold text-xl"></span>
                                    </p>
                                    <span className=" text-lg ml-10">{getTotalQuantity()}</span>
                                </div>
                                <button
                                    className="rounded-full bg-primary text-md px-8 py-3 text-white"
                                    onClick={setReturnItems}

                                >
                                    {" "}
                                    Return Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {returnModal ? (
                    <Popup
                        onClose={setReturnItems}
                        open
                        modal
                    >
                        {() => (
                            <div className="modal">
                                <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                    Status
                                    <button
                                        className="close cursor-pointer  text-2xl"
                                        onClick={() => { setReturnModal(false) }}
                                    >
                                        &times;
                                    </button>
                                </div>

                                <div className="content mx-2 my-2 py-10">
                                    <p className="text-center">{responseMsg}</p>
                                    <div className="button-list flex  justify-around">
                                        <button className="contact-support rounded-full bg-white text-xs h-10 w-1/3 mt-4 font-bold shadow-secondary text-primary">
                                            <Link to="/home/annual-return">Continue</Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>
                ) : null}
            </div>
            <style jsx>
                {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245 , 0.20);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
          .return_msg_box{
            outline:none !important;
            resize:none;
          }
        `}
            </style>
        </>
    )
}

export default ViewReturnItems