import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import React from "react";
import ReactStars from "react-rating-stars-component";
import { Footer, Loader } from "../shared-components";

const EnforceFeedback = (): JSX.Element => {
  let history = useHistory();
  const authToken = localStorage.getItem("token") || "";
  const [feedbackList, setFeedbackList] = useState<any[]>([]);
  const [reviewAnswer, setReviewAnswer] = React.useState({
    question_id: null,
    question_type_id: null,
    event: null,
  });
  const [category_check_list, setCategoryCheckList] = useState<string[]>([]);
  const [arr, setArr] = useState([{}]);
  const [ratingAnswer, setRatingAnswer] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  const checkPreviousFeedbackData = () => {
    apiCAll({}, "/enforce-feedback-list", authToken)
      .then((x) => {
        if (x?.data?.Response?.length >= 1) {
          setFeedbackList(x?.data?.Response[0]);
        }else{
            history.push('/home')
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    checkPreviousFeedbackData();
  }, []);

  const handleInputChange = (e, question_id, question_type_id) => {
    // if (e?.target?.checked === true) {
    if (question_type_id === 2) {
      setCategoryCheckList([...category_check_list, e.target.value]);
    }
    // } else {
    //   const check_list: any = [];
    //   category_check_list.map((check) => {
    //     if (check != e.target.value) {
    //       check_list.push(check);
    //     }
    //   });

    //   setCategoryCheckList(check_list);
    // }
  };
  const handleBlur = (e, question_id, question_type_id) => {
    let obj = {};
    obj = {
      question_id: question_id,
      question_type_id: question_type_id,
      answer: question_type_id === 2 ? category_check_list : e?.target?.value,
    };

    let updatedCarsArray = { ...arr, [e?.target?.name]: obj };

    setArr(updatedCarsArray);
  };
  let newObject = Object.values(arr);
  let finalArr = newObject.filter((value) => Object.keys(value).length !== 0);
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = () => {
    setShowLoader(true);
    let obj = {
      order_number: feedbackList["order_id"],
      answers: finalArr,
      rating: ratingAnswer,
    };
    console.log(ratingAnswer.length, feedbackList?.["feedback_question"]?.length, finalArr?.length)
    apiCAll(obj, "/enforce-feedback-submit", authToken).then((x) => {
      if(x?.data?.ErrorCode === 0){
        setShowLoader(false);
        if(x?.data?.Response !== []){
          setShowLoader(false);
            setFeedbackList(x?.data?.Response[0]);
            window.location.reload();
            
        }else{
          setShowLoader(false);
            history.push('/home')
        }
      }else{
        setShowLoader(false);
      }
    });
  };

  const handleSkipForNow = () => {
    localStorage.setItem("enableSkipForNow", "true");
    history.push("/home");
  };

  const ratingChanged = (newRating) => {
    setShowPopup(false);
    apiCAll({}, "/rating/list", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
           x?.data?.Response.filter((x) => {
          if (x?.id == newRating) {
            setRatingAnswer(x);
          }
        });
      }
    });
    let obj = {
      order_id: feedbackList["order_id"],
      emoji_rating: newRating,
    };
    apiCAll(obj, "/userrating/add", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowPopup(true);
      }
    });
  };

  return (
    <>
          {showLoader ? <Loader /> : null}

      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        {/* <Link to="/home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </Link> */}
        <h3 className="text-2xl font-bold text-center py-4 flex-1">FeedBack</h3>
        <div className="flex flex-col font-bold mb-3">
          <label>Order Id: {feedbackList?.["order_id"]}</label>
          <label>Order date: {feedbackList?.["order_date"]}</label>
          <label>Sub Total: {feedbackList?.["sub_total"]}</label>
        </div>
        <div className="bg-white p-4 px-6 relative">
          <h3 className="text-xl font-bold text-center pt-4 pb-2 flex-1">
            Please rate your experience here.
          </h3>
          <div className="flex justify-center w-full">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={50}
              activeColor="#ffd700"
              classNames="rating-component justify-around flex"
            />
          </div>
          <p className="text-center">{ratingAnswer["title"]}</p>
          {showPopup ? (
            <div className="text-center flex justify-center mt-2">
              <img
                className="animate__animated animate__zoomIn w-1/4"
                src={ratingAnswer["icon"]}
              />
            </div>
          ) : null}
        </div>
        <div className="wrapper mt-4">
          <div className="order-quantity flex justify-between">
            <div className="left w-full">
              <div className="order-item flex flex-col">
                <>
                  {feedbackList?.["feedback_question"]?.map((item, i) => (
                    <>
                      <label className="mt-10">{item["question"]}</label>
                      <div className="pl-4  my-3 flex justify-between flex-col md:flex-row">
                        {item["question_type_id"] === 1 ? (
                          <>
                            {item["question_option"].map((x, index) => (
                              <div className=" flex mt-2">
                                <div className="radio flex items-center">
                                  <input
                                    id={`radio${i}-${index + 1}`}
                                    name={`radio${i}`}
                                    type="radio"
                                    value={x["options"]}
                                    className="cursor-pointer mr-2"
                                    onChange={(e): void => {
                                      handleInputChange(
                                        e,
                                        item?.q_id,
                                        item?.question_type_id
                                      );
                                    }}
                                    onClick={(e): void => {
                                      handleBlur(
                                        e,
                                        item?.q_id,
                                        item?.question_type_id
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`radio${i}-${index + 1}`}
                                    className="radio-label cursor-pointer"
                                  >
                                    {" "}
                                    {x["options"]}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                        {item["question_type_id"] === 2 ? (
                          <>
                            {item["question_option"].map((x, index) => (
                              <div className="w-full flex  mt-2">
                                <div className="checkbox flex items-center">
                                  <input
                                    id={`checkbox-${index + 1}`}
                                    name="checkbox"
                                    type="checkbox"
                                    value={x["options"]}
                                    className="cursor-pointer mr-2"
                                    onChange={(e): void => {
                                      handleInputChange(
                                        e,
                                        item?.q_id,
                                        item?.question_type_id
                                      );
                                    }}
                                    onClick={(e): void => {
                                      handleBlur(
                                        e,
                                        item?.q_id,
                                        item?.question_type_id
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor={`checbox-${index + 1}`}
                                    className="checkbox-label cursor-pointer"
                                  >
                                    {" "}
                                    {x["options"]}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                        {item["question_type_id"] === 3 ? (
                          <>
                            <textarea
                              onChange={(e): void => {
                                handleInputChange(
                                  e,
                                  item?.q_id,
                                  item?.question_type_id
                                );
                              }}
                              onClick={(e): void => {
                                handleBlur(
                                  e,
                                  item?.q_id,
                                  item?.question_type_id
                                );
                              }}
                              name="textbox"
                              className="my-3 w-full  p-4 bg-white rounded-sm  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            ></textarea>
                          </>
                        ) : null}
                        {item["question_type_id"] === 4 ? (
                          <select
                            onChange={(e): void => {
                              handleInputChange(
                                e,
                                item?.q_id,
                                item?.question_type_id
                              );
                            }}
                            onClick={(e): void => {
                              handleBlur(e, item?.q_id, item?.question_type_id);
                            }}
                            className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                          >
                            <option value="select">Select</option>
                            {item["question_option"].map((x) => (
                              <>
                                <option value={x["options"]}>
                                  {x["options"]}
                                </option>
                              </>
                            ))}
                          </select>
                        ) : null}
                      </div>
                    </>
                  ))}
                  <footer className="text-center flex justify-around mt-20 mb-20">
                    {/* <button
                      onClick={handleSkipForNow}
                      className="w-1/4 flex justify-center items-center rounded-full bg-white text-sm md:text-md h-10 md:h-12 text-primary  shadow-secondary"
                    >
                      Skip for now
                    </button> */}
                    <button
                      className={`${
                        ratingAnswer == [] ||
                        feedbackList?.["feedback_question"]?.length !=
                          finalArr?.length 
                          ? "disabled:opacity-50"
                          : ""
                      } w-1/4 rounded-full bg-primary text-sm md:text-md h-10 md:h-12 text-white`}
                      onClick={handleSubmit}
                      disabled={
                        ratingAnswer.length == 0 ||
                        (feedbackList?.["feedback_question"]?.length !=
                          finalArr?.length )
                      }
                    >
                      Submit
                    </button>
                  </footer>
                </>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default EnforceFeedback;
