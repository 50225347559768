import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";

const Profile = () => {
    const authToken = localStorage.getItem("token") || "";
    const [userDetails, setUserDetails] = useState({});
    

    useEffect(() => {
        apiCAll({}, "/profile", authToken).then((x) => {
            if (x?.data?.ErrorCode === 0) {
                setUserDetails(x?.data?.Response)
            }
        })
    }, [])

    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e?.target?.name]: e?.target?.value });
    }

    const submitProfileDetails = () =>{
        let obj = {
            doctor_name: userDetails["username"],
            clinic_name: userDetails["clinic_name"],
            email: userDetails["email"],
            gstin: userDetails["gstin"]
        }
        apiCAll(obj, "/user/profile-update", authToken).then((x) => {
            if(x?.data?.ErrorCode === 0) {
              alert("Profile has been updated Successfully")
            }
        })
    }

   

    return (
        <>
            <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
                <div className="flex mx-2">
                    <Link to="/home/complete-order" className="self-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                        </svg>
                    </Link>
                    <h3 className="text-2xl font-bold text-center py-4 flex-1">
                        Edit Profile
                    </h3>
                </div>
                <div className="wrapper bg-white p-4 px-6">
                 
                        <div className="order-id flex flex-col">
                            <label>Name*</label>
                            <input
                                minLength={3}
                                value={userDetails["username"]}
                                onChange={handleChange}
                               
                                name="username"
                                placeholder="Enter your name here"
                                className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                              {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                        </div>
                        <div className="order-id flex flex-col">
                            <label>Store Name*</label>
                            <input
                                minLength={3}
                                onChange={handleChange}
                                name="clinic_name"
                                value={userDetails["clinic_name"]}
                                placeholder="Enter your clinic name here"
                                className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                            {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                        </div>
                        <div className="order-id flex flex-col">
                            <label>GSTIN*</label>
                            <input
                                value={userDetails["gstin"]}
                                minLength={16}
                                maxLength={16}
                                name="gstin"
                                onChange={handleChange}
                                placeholder="Enter your GSTIN here"
                                className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                             {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                        </div>
                        <div className="order-id flex flex-col">
                            <label>Email*</label>
                            <input
                                value={userDetails["email"]}
                                onChange={handleChange}
                                name="email"
                                placeholder="Enter your email here"
                                className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                             {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                        </div>
                        <div className="order-id flex flex-col">
                            <label>Phone*</label>
                            <input
                                readOnly
                                value={userDetails["mobile"]}
                                placeholder="Enter your phone here"
                                className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                        </div>
                        <footer className="text-center mt-10">
                            <button
                                className="w-3/6 rounded-full bg-primary text-md h-12 text-white cursor-pointer"
                            //   disabled={!returnType || !returnReason}
                              onClick={submitProfileDetails}
                            >
                                CONFIRM
                            </button>
                            <p className="footer opacity-75 mt-5 text-center">
                            Copyright© 2022 <b>GlenService</b>. Designed By{" "}
                              <b><a href="https://techstreet.in">TechStreet.in</a></b>
                            </p>
                        </footer>
                 
                </div>
            </div>
        </>
    )
}

export default Profile;