import { useEffect, useState } from "react";
import { apiCAll } from "../api/api";
import { useHistory } from "react-router";
import { Loader } from "../shared-components";

const Blank = (): JSX.Element => {
  let history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    let whatsappActionKey = history.location.pathname.split("/");
    let action = whatsappActionKey[whatsappActionKey.length - 1];

    let obj = {
      action: action,
    };
    apiCAll(obj, "/whatsapp-action").then((x) => {
      if (x?.data?.ErrorCode === 0) {
        localStorage.setItem("token", x?.data?.Response?.token);
        localStorage.setItem("phone", x?.data?.Response?.phone);
        if (localStorage.getItem("token")) {
          setShowLoader(false);
          history.push(`${x?.data?.Response?.redirect_url}`);
        }
      }
    });
  }, []);

  return (
    <>
      {/* <Loader data={showLoader} /> */}
      <h1>Testing...</h1>
    </>
  );
};

export default Blank;
