import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import "reactjs-popup/dist/index.css";
import { apiCAll } from "../api/api";
import { useLocation, Link } from "react-router-dom";
import { Loader } from "../shared-components";
import Popup from "reactjs-popup";

const GsmaReturnList = (): JSX.Element => {
  const myRef = useRef() as any;
  const [counter, setCounter] = useState({ 0: 0 });
  const [annualReturnData, setAnnualReturnData] = useState([] as any);
  const [showLoader, setShowLoader] = useState(false);
  const [returnRequestData, setReturnRequestData] = useState([]);
  const [gsmaAnnualReturnList, setGsmaAnnualReturnList] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);

  const [fromDate, setFromData] = useState("");
  const [toDate, setToData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const [tempData, setTempData] = useState([] as any);

  const authToken = localStorage.getItem("token") || "";

  useEffect(() => {
    handleAnnualReturn();
  }, []);

  // useEffect(()=>{
  //   let temp = {...counter}
  //   for(let i = 0; i < annualReturnData?.length; i++){
  //     temp.i = 0
  //   }
  // },[])

  const handlePopup = (count, item) => {
    setShowPopup(true);
    setTempData([]);
    let newData: any = [];

    for (let i = 0; i < count; i++) {
      newData.push({
        id: Math.round(Math.random() * 100),
        partName: item.product_name,
        partCode: "CP001",
        quantity: 1,
        GSMAcode: "",
      });
    }

    setTempData(newData);
  };
  console.log(tempData);
  const history = useHistory();
  const handleBack = () => {
    history.push("/home");
  };

  const handleAnnualReturn = async () => {
    setShowLoader(true);
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-items-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            let response = x?.data?.Response;
            response = Object.values(response);
            setAnnualReturnData(response);
            setShowLoader(false);
          }
          resolve("success");
        })
        .catch((err) => {
          reject("error");
          setShowLoader(true);
        });
    });

    if (myPromise === "success") {
      handleAnnualReturnRequest();
      getGsmaAnnualReturnList();
    }
  };

  const handleAnnualReturnRequest = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setReturnRequestData(x?.data?.Response);
            resolve("success");
          }
        })

        .catch((err) => {
          reject("error");
          console.log(err);
          setShowLoader(true);
        });
    });
  };

  const getGsmaAnnualReturnList = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      setShowLoader(true);
      apiCAll({}, "/gsma-annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setShowLoader(false);
            setGsmaAnnualReturnList(x.data.Response);
            console.log(gsmaAnnualReturnList);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(true);
        });
    });
  };
  const filteredList = async () => {
    setShowLoader(true);
    const myPromise = await new Promise<String>((resolve, reject) => {
      const obj = { from_date: fromDate, to_date: toDate };
      apiCAll(obj, "/gsma-annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setShowLoader(false);
            setGsmaAnnualReturnList(x.data.Response);
            console.log(gsmaAnnualReturnList);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    });
  };

  const addToReturnCart = async (item: any, index) => {
    const obj = {
      order_id: item.order_id,
      product_id: counter[index],
      quantity: item.quantity,
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/return-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const updateEditCount = (product, index) => {
    setCounter({ ...counter, [index]: Number(inputValue) });

    // addToReturnCart(product, index);
  };

  const decrement = (product, index) => {
    if (counter[index] === 0) {
      setCounter({ ...counter, [index]: counter[index] });
    } else {
      setCounter({ ...counter, [index]: counter[index] - 1 });
    }
    // addToReturnCart(product, index);
  };

  const increment = (product, index) => {
    console.log(counter, product?.quantity);

    if (product?.quantity > counter[index]) {
      setCounter({ ...counter, [index]: Number(counter[index]) + 1 });
    } else {
      setCounter({ ...counter, [index]: counter[index] });
    }

    // addToReturnCart(product, index);
  };

  const handleGSMAcode = (val: any, index) => {
    const updatedTempData = [...tempData] as any;
    const selectedObj = updatedTempData[index];
    selectedObj.GSMAcode = val;
    setTempData(updatedTempData);
  };

  console.log(annualReturnData);

  const handleDataFilter = (e) => {
    if (e) {
      const copyAnnualReturnData = [...annualReturnData];
      const data = copyAnnualReturnData?.filter((val: any, index) =>
        e
          ? val?.product_name?.toLowerCase().indexOf(e.toLowerCase()) >= 0
          : true || e
          ? val?.product_id?.indexOf(e) >= 0
          : true
      );
      setAnnualReturnData(data);
    } else {
      handleAnnualReturn();
    }
  };

  const handleGsmaReturn = async (item) => {
    setShowPopup(false);
    const data: any = [];

    for (let i = 0; i < tempData?.length; i++) {
      data.push({
        product_id: item?.product_id,
        gsma_number: tempData[i].GSMAcode,
      });
    }

    const obj = {
      gsma_product: [...data],
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/gsma-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(true);
        });
    });

    if (myPromise === "success") {
      handleAnnualReturn();
      setTimeout(() => {
        setCartUpdated(true);
      }, 1200);
    }
  };

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="annual-return">
        <div className="flex mx-2">
          <button
            className="self-center"
            onClick={handleBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Warranty Defective Return List
          </h3>
        </div>
        <div className="bg-white pt-4">

          <div className="py-10 flex justify-center">
            <div className="bg-white w-8/12 justify-left">
              <label className="w-1/6 rounded input_style font-bold ">
                From Date
              </label>
              <input
                type="date"
                className="border w-1/4 rounded px-12 input_style"
                onChange={(e) => setFromData(e.target.value)}
              />
              <label className="w-1/6 rounded input_style font-bold">
                To Date
              </label>
              <input
                type="date"
                className="border w-1/4 rounded px-12 input_style"
                onChange={(e) => setToData(e.target.value)}
              />
              <button
                type="button"
                className={`px-8 text-sm rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center w-1/6 rounded input_style`}
                onClick={filteredList}
              >
                Filter
              </button>
              <div className="text-right">          
                <Link className="text-sm hyperlink underline cursor-pointer"
                      to={{
                        pathname: "/home/gsma-return"
                      }}
                    >
                  Warranty Defective Item List
                </Link>        
              </div>
            </div>
          </div>

          <div className="pb-10 flex justify-center overflow-auto">
            <table className="shadow-primary bg-white w-8/12">
              {gsmaAnnualReturnList.length > 0 ? (
                <tr>
                  <th className="border text-left px-4 py-2 text-sm">Sr.No.</th>
                  <th className="border text-left px-4 py-2 text-sm">
                    Request No.
                  </th>
                  <th className="border text-left px-4 py-2 text-sm">
                    Quantity
                  </th>
                  {/* <th className="border text-left px-4 py-2 text-sm">Reason</th>
                  <th className="border text-left px-4 py-2 text-sm">Remark</th> */}
                  <th className="border text-left px-4 py-2 text-sm">
                    Request Date
                  </th>
                  <th className="border text-left px-4 py-2 text-sm">Status</th>
                </tr>
              ) : null}
              {gsmaAnnualReturnList.length > 0
                ? gsmaAnnualReturnList.map((data: any, index) => {
                    return (
                      <tr
                        className="tabe-row"
                        key={index}
                      >
                        <td className="border px-4 py-2 text-sm ">
                          {index + 1}{" "}
                        </td>
                        <td
                          className="border px-4 py-2 text-sm hyperlink cursor-pointer"
                          onClick={() => {
                            history.push({
                              pathname: `/home/gsma-return/${data.request_no}`,
                              state: data.items,
                            });
                          }}
                        >
                          {data.request_no}
                        </td>
                        <td className="border px-4 py-2 text-sm ">
                          {data.quantity ? data.quantity : "---"}{" "}
                        </td>
                        {/* <td className="border px-4 py-2 text-sm ">
                          {data.reason ? data.reason : "---"}{" "}
                        </td>
                        <td className="border px-4 py-2 text-sm ">
                          {data.qc_remark ? data.qc_remark : "---"}{" "}
                        </td> */}
                        <td className="border px-4 py-2 text-sm ">
                          {data.request_date ? data.request_date : "---"}{" "}
                        </td>
                        <td className="border px-4 py-2 text-sm ">
                          {data.status ? data.status : "---"}{" "}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </table>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245, 0.2);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .link_text {
            color: #0211c0;
          }
          .input_style {
            height: 40px;
            line-height: 25px;
            margin-right: 10px;
          }

          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default GsmaReturnList;
