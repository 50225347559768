import { useState } from "react";

type LoaderProps = {
  data: boolean;
};
const Loader = (): JSX.Element => {
  return (
    <>
    <div className="fixed w-full h-full block opacity-50 bg-black z-top left-0 bottom-0 right-0 top-0"></div>
      <img
        src={window.location.origin + "/loader.gif"}
        alt="Loader"
        width="60px"
        className="rounded fixed m-auto left-0 bottom-0 top-0 right-0 z-top"
      />
      <style>
        {`
        .z-top{
          z-index:100000; 
        }          
        `}
        </style>
    </>
  );
};

export default Loader;
