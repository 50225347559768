import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Loader } from "../shared-components";

const SupportNonGlenService = (): JSX.Element => {
  const authToken = localStorage.getItem("token") || "";
  const [startDate, setStartDate] = useState(new Date());
  const [brandList, setBrandList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [productQuestion, setProductQuestion] = useState<any[]>([]);
  const [addWarrantyRequest, setAddWarrantyRequest] = React.useState({
    // brand_id: '',
    name: "",
    mfg: "",
    purchased_date: "",
    brand_name: "",
  });
  const [warrantyProduct, setWarrantyProduct] = useState([]);
  const [srList, setSRList] = useState([]);

  const showFormHandler = () => {
    setShowForm(true);
  };

  const changeHandler = (event: { target: { name: any; value: any } }) => {
    setAddWarrantyRequest({
      ...addWarrantyRequest,
      [event?.target?.name]: event?.target?.value,
    });
  };
  const [showLoader, setShowLoader] = useState(false);


  const addWarrantyHandler = () => {
   
    setShowLoader(true)
    let obj = {
      name: addWarrantyRequest?.name,
      brand_name: addWarrantyRequest?.brand_name,
      purchase_date: addWarrantyRequest?.purchased_date,
      mfg_year: addWarrantyRequest?.mfg,
    };
    apiCAll(obj, "/non_glenService_product/add", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowForm(false);
        setShowLoader(false)
        window.location.reload();
      }else{
        setShowLoader(false);
        alert('Error Occured, Please try again later')
      }
    });
  };

  const existedWarrantyList = () => {
    apiCAll(
      { user_id: localStorage.getItem("user_id") },
      "/warranty_product/list",
      authToken
    ).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setWarrantyProduct(x?.data?.Response?.non_glenService_warranty_product);
        setProductQuestion(x?.data?.Response?.non_glenService_warranty_question);
      }
    });
  };

  const SRList = () => {
    apiCAll({}, "/warranty_request/list", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setSRList(
          x?.data?.Response.filter((X) => {
            return X.warranty_type === 3;
          })
        );
      }
    });
  };
  const BrandList = () => {
    apiCAll({}, "/brand/list", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setBrandList(x?.data?.Response);
      }
    });
  };

  useEffect(() => {
    existedWarrantyList();
    SRList();
    BrandList();
  }, []);

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="flex mx-2">
          <Link to="/home" className="self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Warranty and Repair{" "}
          </h3>
        </div>
        <div className="bg-white p-4 px-6">
          <div className="home-tabs mt-4">
            <ul className="flex justify-between shadow-secondary text-center rounded-full ">
              <Link
                to="/support/glenService-product"
                className="flex-1 p-2 py-3 sm:p-3 "
              >
                {" "}
                <li
                  className=" rounded-l-full  border-color  text-xs sm:text-sm hover: cursor-pointer
                  text-black"
                >
                  GlenService Product{" "}
                  <p className="text-small">(Purchased on - GlenService.in)</p>{" "}
                </li>{" "}
              </Link>

              <Link
                to="/support/non-glenService-warranty"
                className="rounded-r-full bg-primary text-white flex-1 p-2 py-3 sm:p-3"
              >
                <li
                  className="  border-color  text-xs sm:text-sm hover: cursor-pointer
               "
                >
                  Other Products
                  <p className="text-small">(Purchased on- Other Platforms )</p>
                </li>
              </Link>
            </ul>
          </div>
          <h3 className="text-xxs sm:text-sm font-bold py-4 flex-1">
            {" "}
            This service is on chargeable basis. We will notify the service
            charges once the technical inspection is done at our end.
          </h3>
          <div className="content mt-4">
            <button
              disabled={showForm}
              onClick={showFormHandler}
              className={`${
                showForm ? "opacity-50" : ""
              } rounded-full bg-white text-md h-10 mt-4 mb-1 text-black border px-12 hover:bg-primary hover:text-white`}
            >
              Add Product
            </button>
            {showForm ? (
              <>
                <div className="add-warranty-form mt-5">
                  <div className="order-id flex flex-col">
                    <label className="text-sm">Product Name*</label>
                    <input
                      placeholder="Enter product Name*"
                      name="name"
                      onChange={changeHandler}
                      value={addWarrantyRequest.name}
                      className="my-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                    />
                  </div>
                  <div className="order-id flex flex-col mt-1">
                    <label className="text-sm">Brand Name*</label>
                    <input
                      type="text"
                      placeholder="Enter Brand Name"
                      name="brand_name"
                      onChange={changeHandler}
                      value={addWarrantyRequest.brand_name}
                      className="my-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                    />

                    {/* <div className="px-4 shadow-primary rounded-full my-3">
                                        <select
                                          onChange={changeHandler}
                                          name="brand_id"
                                            className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs" >
                                            <option value="select">Select</option>
                                            {brandList?.map(
                                                (x) => (
                                                    <>
                                                        <option value={x["id"]}>
                                                            {x["name"]}
                                                        </option>
                                                    </>
                                                )
                                            )}
                                        </select>
                                    </div> */}
                  </div>
                  <div className="order-id flex flex-col mt-1">
                    <label className="text-sm">Purchased Date*</label>
                    <input
                      type="date"
                      placeholder="Enter purasched Date*"
                      name="purchased_date"
                      onChange={changeHandler}
                      value={addWarrantyRequest.purchased_date}
                      className="my-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                    />
                  </div>

                  <div className="order-id flex flex-col mt-1">
                    <label className="text-sm">Manufacture Year</label>
                    <input
                      placeholder="Enter manufacture year"
                      name="mfg"
                      maxLength={4}
                      onChange={changeHandler}
                      value={addWarrantyRequest.mfg}
                      className="my-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                    />
                  </div>

                  <button
                    onClick={addWarrantyHandler}
                    className={` ${
                      true ? "disabled:opacity-50" : ""
                    } w-full my-5 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                    disabled={
                      addWarrantyRequest.name === "" ||
                      addWarrantyRequest.brand_name === "" ||
                      addWarrantyRequest.purchased_date === ""
                    }
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="w-full mt-2">
            <div className="shadow-md">
              <div className="tab w-full overflow-hidden border-t">
                <input
                  className="absolute opacity-0"
                  id="tab-single-two"
                  type="radio"
                  name="tabs2"
                />
                <label
                  className="block p-5 leading-normal cursor-pointer"
                  htmlFor="tab-single-two"
                >
                  Your Service Requests
                </label>
                <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-primary leading-normal">
                  <ul className="search-suggestions mt-3 ml-1">
                    {srList &&
                      srList.map((product, index): any => (
                        <li className="bg-white flex border my-4 py-2 px-4 justify-between hover:bg-pink-50 cursor-pointer hover:shadow-secondary">
                          <div className="self-center">
                            <p>
                              Service Request: <b>{product["request_no"]}</b>
                            </p>
                            <p>
                              Name: <b>{product["item_name"]}</b>
                            </p>
                            <p>
                              Quantity: <b>{product["quantity"]}</b>
                            </p>
                            <p>
                              Reason: <div dangerouslySetInnerHTML={{ __html: product["reason"] }} />
                            </p>
                            <p>
                              Status: <b>{product["status"]}</b>
                            </p>
                            <p>
                              Total: <b>{product["total"]}</b>
                            </p>
                            <p>
                              Service Charge: <b>{product["service_charge"]}</b>{" "}
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="tab w-full overflow-hidden border-t">
                <input
                  className="absolute opacity-0"
                  id="tab-single-one"
                  type="radio"
                  name="tabs2"
                />
                <label
                  className="block p-5 leading-normal cursor-pointer"
                  htmlFor="tab-single-one"
                >
                  Your Product List
                </label>
                <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-primary leading-normal">
                  <ul className="search-suggestions mt-3 ml-1">
                    {warrantyProduct &&
                      warrantyProduct.map((product, index): any => (
                        <li className="bg-white text-xs sm:text-sm  pb-8 flex flex-col  md:flex-row  border my-4 py-2 px-4 justify-between hover:bg-pink-50 cursor-pointer hover:shadow-secondary">
                          <div className="self-left sm:self-center">
                            <p className="py-1">
                              Product Name: <b>{product["name"]}</b>
                            </p>
                            <p className="py-1">
                              Brand Name: <b>{product["brand_name"]}</b>
                            </p>
                            <p className="py-1">
                              Manufacture Year: <b>{product["mfg_year"]}</b>
                            </p>
                            <p className="py-1">
                              Purchased Date: <b>{product["purchase_date"]}</b>
                            </p>
                          </div>
                          <div className="self-center mt-5 ">
                            <Link
                              to={{
                                pathname: "/support/OW/Initate",
                                state: {
                                  active: 1,
                                  orderDetails: product,
                                  productQuestion: productQuestion,
                                },
                              }}
                              className="rounded-full w-full px-20 sm:px-8 text-md py-2.5 text-black border  filter drop-shadow-sm hover:shadow-lg hover:border-gray-400"
                            >
                              Initate
                            </Link>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .tab-content {
            overflow-y: scroll;
            max-height: 0;
            -webkit-transition: max-height 0.35s;
            -o-transition: max-height 0.35s;
            transition: max-height 0.35s;
          }
          /* :checked - resize to full height */
          .tab input:checked ~ .tab-content {
            max-height: 100vh;
          }
          /* Label formatting when open */
          .tab input:checked + label {
            /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
            font-size: 1.25rem; /*.text-xl*/
            padding: 1.25rem; /*.p-5*/
            border-left-width: 2px; /*.border-l-2*/
            border-color: #9C1E60; /*.border-indigo*/
            background-color: #f8fafc; /*.bg-gray-100 */
            color: #9C1E60; /*.text-indigo*/
          }
          /* Icon */
          .tab label::after {
            float: right;
            right: 0;
            top: 0;
            display: block;
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5;
            font-size: 1.25rem;
            text-align: center;
            -webkit-transition: all 0.35s;
            -o-transition: all 0.35s;
            transition: all 0.35s;
          }
          .tab input[type="checkbox"] + label::after {
            content: "+";
            font-weight: bold; /*.font-bold*/
            border-width: 1px; /*.border*/
            border-radius: 9999px; /*.rounded-full */
            border-color: #b8c2cc; /*.border-grey*/
          }
          .tab input[type="radio"] + label::after {
            content: "+";
            font-weight: bold; /*.font-bold*/
            border-width: 1px; /*.border*/
            border-radius: 9999px; /*.rounded-full */
            border-color: #b8c2cc; /*.border-grey*/
          }
          .tab input[type="checkbox"]:checked + label::after {
            transform: rotate(315deg);
            background-color: #9C1E60; /*.bg-indigo*/
            color: #f8fafc; /*.text-grey-lightest*/
          }

          .tab input[type="radio"]:checked + label::after {
            transform: rotatez(225deg);
            background-color: #9C1E60; /*.bg-indigo*/
            color: #f8fafc; /*.text-grey-lightest*/
          }
        `}
      </style>
    </>
  );
};

export default SupportNonGlenService;
