import React from "react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { apiCAll } from "../api/api";

const Review = (params): JSX.Element => {
  let history = useHistory();
  const authToken = localStorage.getItem("token") || "";
  const [ReviewDetails, setReviewDetails] = useState<any[]>([]);
  const [counter, setCounter] = useState(0);
  const [category_check_list, setCategoryCheckList] = useState<string[]>([]);
  const [givenfeedback, setGivenFeedback] = useState(true);
  const [reviewAnswer, setReviewAnswer] = React.useState({
    question_id: null,
    question_type_id: null,
    event: null,
  });

  let order_id = params?.location?.state?.orderId;

  const ReviewQuestions = () => {
    apiCAll({}, `/feedbackquestion/${order_id}`, authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setReviewDetails(x?.data?.Response);
      }
    });
  };

  useEffect(() => {
    ReviewQuestions();
  }, []);

  const handleInputChange = (e, question_id, question_type_id) => {

    if (e?.target?.checked === true) {
      setCategoryCheckList([...category_check_list, e.target.value]);
    } else {
      const check_list: any = [];
      category_check_list.map((check) => {
        if (check != e.target.value) {
          check_list.push(check);
        }
      });
      setCategoryCheckList(check_list);
    }

    setReviewAnswer({
      question_id: question_id,
      question_type_id: question_type_id,
      event: e?.target?.value,
    });


  };

  // const [showQuestion, setShowQuestion] = useState(true);

  const handleNext = () => {


    if (reviewAnswer?.question_type_id === 2) {
      category_check_list.shift();
    }
    let obj = {
      question_id: reviewAnswer?.question_id,
      question_type_id: reviewAnswer?.question_type_id,
      answer:
        reviewAnswer?.question_type_id === 2
          ? category_check_list
          : reviewAnswer?.event,
      order_id: order_id,
    };
    // setShowQuestion(false);
    apiCAll(obj, "/userfeedback/add", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
      
        setReviewAnswer({
          question_id: null,
          question_type_id: null,
          event: null,
        });
        // setShowQuestion(true);
       
        if (counter >= ReviewDetails.length - 1) {
          setGivenFeedback(false);
          setCounter(counter);
          history.push(
            {
              pathname: `/rating/${order_id}`,
              state: {
                orderId: `${order_id}`,
              },
            }

          )
        } else {
      
            setCounter(counter + 1);
       
         
        }
      }
    });
  };

  return (
    <>
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="flex mx-2">
          <Link to="/home/complete-order" className="self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">Review</h3>
        </div>
        <div className="bg-white p-4 px-6">
          {  givenfeedback ? (
            <div className="wrapper">
              <div className="order-quantity flex justify-between">
                <div className="left w-full">
                  <div className="order-item flex flex-col">
                    <>
                      {/* counter:{ReviewDetails[counter]} */}
                      {/* id:{`radio${ReviewDetails[counter]?.q_id}`} */}

                      {/* {showQuestion ? <> */}
                      <label> {ReviewDetails[counter]?.question}</label>
                      <div className=" my-3 flex justify-between flex-col md:flex-row">
                        {  ReviewDetails[counter]?.["question_type_id"] === 1 ? (
                          <>
                            {ReviewDetails[counter]?.["question_option"].map(
                              (x, index) => (

                                <div className="w-full flex">

                                  <div className="radio">
                                    <input
                                      id={`radio${ReviewDetails[counter]?.q_id}-${index + 1}`}
                                      name={`radio${ReviewDetails[counter]?.q_id}`}
                                      type="radio"
                                      checked={reviewAnswer.event === x["options"]}
                                      value={x["options"]}
                                    
                                      className="cursor-pointer"
                                      
                                      onChange={(e): void => {
                                      
                                        handleInputChange(
                                          e,
                                          ReviewDetails[counter]?.q_id,
                                          ReviewDetails[counter]
                                            ?.question_type_id
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`radio${ReviewDetails[counter]?.q_id}-${index + 1}`}
                                      className="radio-label cursor-pointer"
                                    >
                                      {" "}
                                      {x["options"]}
                                    </label>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        ) : null}
                        {ReviewDetails[counter]?.["question_type_id"] === 2 ? (
                          <>
                            {ReviewDetails[counter]?.["question_option"].map(
                              (x, index) => (
                                <div className="flex">
                                  <div className="checkbox">
                                    <input
                                      id={`checkbox-${index + 1}`}
                                      name="checkbox"
                                      type="checkbox"
                                      value={x["options"]}
                                      className="cursor-pointer"
                                      onChange={(e): void => {
                                        handleInputChange(
                                          e,
                                          ReviewDetails[counter]?.q_id,
                                          ReviewDetails[counter]
                                            ?.question_type_id
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`checbox-${index + 1}`}
                                      className="checkbox-label cursor-pointer"
                                    >
                                      {" "}
                                      {x["options"]}
                                    </label>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        ) : null}
                        {ReviewDetails[counter]?.["question_type_id"] === 3 ? (
                          <>
                            <textarea
                              onChange={(e): void => {
                                handleInputChange(
                                  e,
                                  ReviewDetails[counter]?.q_id,
                                  ReviewDetails[counter]?.question_type_id
                                );
                              }}
                              className="my-3 w-full  p-4 bg-white rounded-sm  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            ></textarea>
                          </>
                        ) : null}
                        {ReviewDetails[counter]?.["question_type_id"] === 4 ? (
                          <select
                            onChange={(e): void => {
                              handleInputChange(
                                e,
                                ReviewDetails[counter]?.q_id,
                                ReviewDetails[counter]?.question_type_id
                              );
                            }}
                            className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                          >
                            <option value="select">Select</option>
                            {ReviewDetails[counter]?.["question_option"].map(
                              (x) => (
                                <>
                                  <option value={x["options"]}>
                                    {x["options"]}
                                  </option>
                                </>
                              )
                            )}
                          </select>
                        ) : null}
                      </div>
                    {/* </>
                    : null} */}
                    </>
                  </div>
                </div>
              </div>
            

              <footer className="text-center mt-20">
                <button
                  onClick={handleNext}
                  className={` ${!reviewAnswer?.event ? "disabled:opacity-50" : ""
                    } w-3/6 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                  disabled={!reviewAnswer?.event}
                >
                  {counter === ReviewDetails.length - 1 ? "Submit" : "Next"}
                </button>
                <p className="footer opacity-75 mt-5 text-center">
                  Copyright© 2022 <b>GlenService</b>. Designed By{" "}
                  <b><a href="https://techstreet.in">TechStreet.in</a></b>
                </p>
              </footer>
            </div>
          ) : (
            <div className="thanks-feedback"><h3 className="text-xl font-bold text-center pt-4 pb-2 flex-1">Thankyou for the feedback!</h3>
              <p className="text-l  text-center ">It helps us to improve</p></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Review;
