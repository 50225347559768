import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { apiCAll } from "../api/api";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { Loader } from "../shared-components";

const AnnualReturn = (): JSX.Element => {
  const [annualReturnData, setAnnualReturnData] = useState([]);
  const [annualReturnItems, setAnnualReturnItems] = useState([]) as any;
  const [showLoader, setShowLoader] = useState(false);
  const [returnRequestData, setReturnRequestData] = useState([]);
  const [returnModal, setReturnModal] = useState(false)
  const [responseMsg, setResponseMsg] = useState("")
  const [fromDate, setFromData] = useState("");
  const [toDate, setToData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [counter, setCounter] = useState({});
  const authToken = localStorage.getItem("token") || "";

  useEffect(() => {
    handleAnnualReturn();
  }, []);

  const history = useHistory();
  const handleBack = () => {
    history.push("/home");
  };

  const handleAnnualReturn = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-items-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            console.log(x?.data?.Response);
            let response = x?.data?.Response;
            response = Object.values(response);
            setAnnualReturnData(response);
          }
          resolve("success");
        })
        .catch((err) => {
          reject("error");
          console.log(err);
        });
    });

    if (myPromise === "success") {
      handleAnnualReturnRequest();
    }
  };

  const handleAnnualReturnRequest = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setReturnRequestData(x?.data?.Response);
          }
          resolve("success");
        })

        .catch((err) => {
          console.log(err);
        });
    });
  };
  const filteredList = async () => {
    setShowLoader(true);
    const myPromise = await new Promise<String>((resolve, reject) => {
      const obj = { from_date: fromDate, to_date: toDate };
      apiCAll(obj, "/annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setShowLoader(false);
            setReturnRequestData(x?.data?.Response);
          }
          resolve("success");
        })

        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });
    });
  };

  useEffect(() => {
    let count = {};
    for (var input = 0; input < annualReturnData?.length; input++) {
      count[input] = 0;
    }
    
    annualReturnData.map((item: any, index) => (
      count[index] = item["cart_quantity"]
    ))
    setCounter(count);

    console.log(counter);
  }, [annualReturnData]);

  const addToReturnCart = async (item: any, qty) => {
    const obj = {
      // order_id: item.order_id,
      product_id: item.product_id,
      quantity: qty,
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/annual-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const increment = (product, index) => {
    // alert(index);
    if (product?.quantity == counter[index]) {
      // alert('if')
      // alert(counter[index])
      setCounter({ ...counter, [index]: counter[index] });
      addToReturnCart(product, counter[index]);
    } else {
      // alert('else')
      setCounter({ ...counter, [index]: counter[index] + 1 });
      // alert(counter[index])
      addToReturnCart(product, (counter[index] + 1));
    }
    
    // console.log(counter);
    // console.log('1111111111111111111111111',index);
  };

  const decrement = (product, index) => {
    if (counter[index] === 0) {
      setCounter({ ...counter, [index]: counter[index] });
      addToReturnCart(product, counter[index]);
    } else {
      setCounter({ ...counter, [index]: counter[index] - 1 });
      addToReturnCart(product, (counter[index]-1));
    }
  };

  const updateEditCount = (product, index) => {
    setCounter({ ...counter, [index]: Number(inputValue) });

    addToReturnCart(product, index);
  };

  const returnSpareRequest = async () => {
    var obj = {
      annual_return_items: annualReturnData,
    };

    const mypromise = await new Promise<String>((resolve, response) => {
      apiCAll(obj, "/annual-return", authToken)
        .then((x) => {
          if (x.data.ErrorCode === 103) {
            setResponseMsg(x.data.ErrorMessage +" Request No is " +x.data.Response.request_no)
            setReturnModal(true)
          }
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDataFilter = (e) => {
    if (e) {
      const copyAnnualReturnData = [...annualReturnData];
      const data = copyAnnualReturnData?.filter((val: any, index) =>
        e ? 
        (val?.product_name?.toLowerCase().indexOf(e.toLowerCase()) >= 0 || val?.product_code?.indexOf(e) >= 0)
        : true 
      );
      setAnnualReturnData(data);
    } else {
      handleAnnualReturn();
    }
  };

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="annual-return">
        <div className="flex mx-2">
          <button
            className="self-center"
            onClick={handleBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Ok Spare Request Raise
          </h3>
        </div>

        <div className="bg-white pt-4">
          <div className="w-8/12 mx-auto">
            <label
              htmlFor="#"
              className="mr-3"
            >
              Search Spare name/code:
            </label>
            <input
              type="text"
              className="border w-1/4 rounded px-4 input_style"
              placeholder="Search...."
              onChange={(e) => handleDataFilter(e.target.value)}
            />
            <div className="text-right">
              <Link
                className="text-sm hyperlink underline cursor-pointer"
                to={{
                  pathname: "/home/annual-return-list",
                }}
              >
                Ok Spare Return List
              </Link>
            </div>
          </div>
          <div className="py-10 flex justify-center">
            <table className="shadow-primary bg-white w-8/12">
              {annualReturnData?.length ? (
                <tr>
                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Sr.No.
                    </th>
                  ) : null}

                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Spare Name
                    </th>
                  ) : null}

                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Spare Code
                    </th>
                  ) : null}

                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Annual Quantity
                    </th>
                  ) : null}
                  {annualReturnData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Return Quantity
                    </th>
                  ) : null}
                </tr>
              ) : (
                <span>No Data Found</span>
              )}
              {annualReturnData?.length
                ? annualReturnData.map((item: any, index) => (
                    <tr
                      className="tabe-row"
                      key={index}
                    >
                      {item.product_id ? (
                        <td className="border px-4 py-2 text-sm ">
                          {index + 1}{" "}
                        </td>
                      ) : null}

                      {item.product_name ? (
                        <td className="border px-4 py-2 text-sm">
                          <Link
                            className="hyperlink"
                            to={`/annual-return/${item.product_id}`}
                          >
                            {item.product_name}
                          </Link>
                        </td>
                      ) : null}
                      {item.product_code ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item?.product_code}
                        </td>
                      ) : null}

                      {item.quantity ? (
                        <td className="border px-4 py-2 text-sm ">
                          {item.quantity}{" "}
                        </td>
                      ) : null}
                      {
                        <td className="border-x-1 sm: border text-center  px-2 py-2 text-sm pt-4 w-64">
                          <div className="text-center flex justify-around">
                            <span
                              className="cursor-pointer self-center"
                              onClick={() => decrement(item, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M20 12H4"
                                />
                              </svg>
                            </span>
                            {/* {item.quantity} */}
                            <Popup
                              className="search-popup"
                              trigger={
                                <button className="text-primary  cursor-pointer text-sm">
                                  {counter[index] || 0}
                                </button>
                              }
                              modal
                            >
                              {(close) => (
                                <div className="modal">
                                  <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                    Enter the Quantity here{" "}
                                    <button
                                      className="close cursor-pointer  text-2xl"
                                      onClick={close}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="content mx-2 my-2">
                                    <div className="mb-3">
                                      <input
                                        min="0"
                                        type="number"
                                        className="phone bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                        placeholder="Quantity"
                                        value={inputValue}
                                        onChange={(e) =>
                                          setInputValue(e?.target?.value)
                                        }
                                      />
                                    </div>

                                    <button
                                      className={
                                        " rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full " +
                                        (inputValue > item.quantity
                                          ? "opacity-20"
                                          : null)
                                      }
                                      onClick={() => {
                                        if (inputValue) {
                                          updateEditCount(item, index);
                                        }
                                        close();
                                        setInputValue("");
                                      }}
                                      disabled={inputValue > item.quantity}
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                            <span
                              className="cursor-pointer self-center"
                              onClick={() => increment(item, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 4v16m8-8H4"
                                />
                              </svg>
                            </span>
                          </div>
                        </td>
                      }
                    </tr>
                  ))
                : null}
            </table>
          </div>
          <div className="text-center">
            <button
              onClick={returnSpareRequest}
              className="px-8 text-sm rounded bg-primary text-white font-semibold py-2 my-5 d-flex content-center  rounded input_style"
            >
            Place Ok Spare Return{" "}
            </button>
          </div>
          {returnModal ? (
              <Popup
                  onClose={handleAnnualReturn}
                  open
                  modal
              >
                  {() => (
                      <div className="modal">
                          <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                              Status
                              <button
                                  className="close cursor-pointer  text-2xl"
                                  onClick={() => { setReturnModal(false); handleAnnualReturn() }}
                              >
                                  &times;
                              </button>
                          </div>

                          <div className="content mx-2 my-2 py-10">
                              <p className="text-center">{responseMsg}</p>
                              <div className="button-list flex  justify-around">
                                  <button className="contact-support rounded-full bg-white text-xs h-10 w-1/3 mt-4 font-bold shadow-secondary text-primary">
                                      <Link to="/home/annual-return-list">View Ok Returns</Link>
                                  </button>
                              </div>
                          </div>
                      </div>
                  )}
              </Popup>
          ) : null}
        </div>
      </div>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245, 0.2);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .link_text {
            color: #0211c0;
          }
          .input_style {
            height: 40px;
            line-height: 25px;
            margin-right: 10px;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default AnnualReturn;
