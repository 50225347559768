import { useEffect, useState } from "react";
import { apiCAll } from "../api/api";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import { Loader } from "../shared-components";

type CompletedOrderProps = {
  data: any;
  updatedCartList?: any;
};
const CompletedOrder = ({
  data,
  updatedCartList,
}: CompletedOrderProps): JSX.Element => {
  const [completeData, setCompleteData] = useState(data);
  const [showLoader, setShowLoader] = useState(false);
  const [counter, setCounter] = useState({});
  const [cancelOrderBox, setCancelOrderBox] = useState(false);
  const tableDataHeaderKeys =
    Array.isArray(completeData) && completeData?.length ? completeData[0] : {};

  useEffect(() => {
    let count = {};
    for (var input = 1; input <= completeData?.length; input++) {
      count[input] = 0;
    }

    setCounter(count);
  }, [completeData]);

  const authToken = localStorage.getItem("token") || "";

  const increment = (item, id) => {
    setCounter({ ...counter, [id]: counter[id] + 1 });
    let obj = {
      offer_price: parseFloat(item.offer_price),
      rate: parseFloat(item.rate),
      quantity: item.quantity + counter[id] + 1,
      product_id: item.id,
    };
    apiCAll(obj, "/cart-add", authToken).then((x) => {
      updatedCartList();
    });
  };

  const decrement = (item, id) => {
    let obj = {
      offer_price: parseFloat(item.offer_price),
      rate: parseFloat(item.rate),
      quantity: item.quantity + counter[id] - 1,
      product_id: item.id,
    };
    apiCAll(obj, "/cart-add", authToken).then((x) => {
      updatedCartList();
    });
    if (counter[id] === 0) {
      setCounter({ ...counter, [id]: counter[id] - 1 });
    } else if (counter[id] === -1) {
      setCounter({ ...counter, [id]: counter[id] });
    } else {
      setCounter({ ...counter, [id]: counter[id] - 1 });
    }
  };

  const [textBoxInputValue, setTextBoxInputValue] = useState("");
  const [showCancelSuccessfully, setShowCancelSuccessfully] = useState(false);

  const cancelOrderAPI = (orderID) => {
    let obj = {
      order_id: orderID,
      cancel_reason: textBoxInputValue,
    };
    apiCAll(obj, "/order-cancel", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowCancelSuccessfully(true);
      }
    });
  };

  const handleCancel = () => {
    setCancelOrderBox(true);
    setShowCancelSuccessfully(false);
    setTextBoxInputValue("");
  };

  useEffect(() => {
    apiCAll({}, "/orders/completed", authToken)
      .then((x) => {
        setCompleteData(x?.data?.Response);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCompleteRefresh = () => {
    setShowLoader(true);
    apiCAll({}, "/orders/completed", authToken)
      .then((x) => {
        setShowLoader(false);
        setCompleteData(x?.data?.Response);
        console.log(2222, completeData);
      })
      .catch((err) =>  {setShowLoader(false);
      console.log(err)});
  };

  return (
    <>
     {showLoader ? <Loader /> : null}
      {completeData?.length >= 1 ? (
        <button
          className="mt-4 w-full justify-end  flex  text-sm  hover:text-gray-600"
          onClick={handleCompleteRefresh}
        >
          <img src="/refresh.png" width="20px" className="mr-1" />
          Refresh
        </button>
      ) : null}
      <table className="shadow-primary bg-white mt-4 w-full">
        {completeData?.length >= 1 ? (
          <tr>
            {tableDataHeaderKeys.id ? (
              <th className="border text-left px-4 py-2 text-sm">Sr.No.</th>
            ) : null}

            {tableDataHeaderKeys.orderId ? (
              <th className="border text-left px-4 py-2 text-sm">Order Id</th>
            ) : null}

            {tableDataHeaderKeys.order_date ? (
              <th className="border text-left px-4 py-2 text-sm">Order Date</th>
            ) : null}

            {tableDataHeaderKeys.amount ? (
              <th className="border text-left px-4 py-2 text-sm">Amount(&#8377;)</th>
            ) : null}

            {tableDataHeaderKeys.current_status ? (
              <th className="border text-left px-4 py-2 text-sm">
                Current Status
              </th>
            ) : null}

            {tableDataHeaderKeys.more ? (
              <th className="border text-left px-4 py-2 text-sm">More</th>
            ) : null}
          </tr>
        ) : <tr><th className="border text-left px-4 py-2 text-sm">There is no Completed Order</th></tr>}
        {completeData.map((item: any, index) => (
          <tr className="tabe-row">
            {item.id ? (
              <td className="border px-4 py-2 text-sm ">{index + 1} </td>
            ) : null}

            {item.orderId ? (
              <td className="border px-4 py-2 text-sm">
                #
                <Link className="hyperlink" to={`/order/` + item.orderId}>
                  {item.orderId}
                </Link>
              </td>
            ) : null}
            {item.order_date ? (
              <td className="border px-4 py-2 text-sm">{item.order_date}</td>
            ) : null}

            {item.amount ? (
              <td className="border px-4 py-2 text-sm">{item.amount}</td>
            ) : null}

            {item.current_status ? (
              <td className="border px-4 py-2 text-sm">
                {item.current_status}
              </td>
            ) : null}

            {item.edit ? (
              <td className="border px-4 py-2 text-sm">
                <Popup
                  closeOnDocumentClick={false}
                  onClose={() => setCancelOrderBox(false)}
                  trigger={
                    <button className="cursor-pointer text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="text-primary"
                      >
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path
                          fillRule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal">
                      <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                        Order Id: {item.orderId}
                        <button
                          className="close cursor-pointer  text-2xl"
                          onClick={close}
                        >
                          &times;
                        </button>
                      </div>

                      <div className="content mx-2 my-2">
                        {!cancelOrderBox ? (
                          <>
                            {item.shipped === 0 ? (
                              <button
                                className="cursor-pointer"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                            ) : (
                              <>
                                <p className="cursor-pointer hover:text-blue-200 border-b mb-2 pb-2">
                                  Download Invoice
                                </p>
                                {/* <p className="cursor-pointer hover:text-blue-200">
                                  Send Invoice on whatsapp
                                </p> */}
                              </>
                            )}
                          </>
                        ) : null}

                        {cancelOrderBox ? (
                          <>
                            <div className="mx-2 py-1 font-bold text-base border-black  justify-between items-center">
                              {!showCancelSuccessfully ? (
                                <>
                                  <div className="border-b ">
                                    <p>
                                      Why do you want to cancel the order ?{" "}
                                    </p>
                                    <textarea
                                      name=""
                                      id=""
                                      value={textBoxInputValue}
                                      onChange={(e) =>
                                        setTextBoxInputValue(e?.target?.value)
                                      }
                                      placeholder="Please write the reason"
                                      className="w-full my-3  p-4 bg-white rounded-md  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                    ></textarea>
                                  </div>
                                  <button
                                    disabled={!textBoxInputValue}
                                    onClick={() => cancelOrderAPI(item.orderId)}
                                    className={`${
                                      !textBoxInputValue
                                        ? "disabled:opacity-50"
                                        : ""
                                    } rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full`}
                                  >
                                    CONFIRM
                                  </button>
                                </>
                              ) : (
                                <p>Order has been Cancelled Successfully!</p>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}
                </Popup>
              </td>
            ) : null}
            {item.deliveryDate ? (
              <td className="border px-4 py-2 text-sm">{item.deliveryDate}</td>
            ) : null}
            {item.more ? (
              <td className="border px-4 py-2 text-sm">
                <div className="cursor-pointer">
                  <Popup
                    closeOnDocumentClick={false}
                    onClose={() => setCancelOrderBox(false)}
                    trigger={
                      <button className={`${item.current_status != "Delivered" ? 'opacity-40 ' : ''  }cursor-pointer text-sm`} disabled = {item.current_status != "Delivered"} >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </button>
                    }
                    modal
                  >
                    {(close) => (
                      <div className="modal">
                        <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                          Order Id: {item.orderId}
                          <button
                            className="close cursor-pointer  text-2xl"
                            onClick={close}
                          >
                            &times;
                          </button>
                        </div>
                        <div className="content mx-2 my-2">
                          {item?.current_status === "Delivered" ? (
                            <p className="cursor-pointer  border-b mb-2 pb-2">
                              {" "}
                              <Link
                                to={{
                                  pathname: `/return/${item.orderId}`,
                                  state: {
                                    orderId: `${item.orderId}`,
                                    return: true,
                                  },
                                }}
                              >
                                {" "}
                                Return and Replacement
                              </Link>
                            </p>
                          ) : null}
                          <p className="cursor-pointer  border-b mb-2 pb-2">
                            {" "}
                            <Link
                              to={{
                                pathname: `/review/${item.orderId}`,
                                state: {
                                  orderId: `${item.orderId}`,
                                },
                              }}
                            >
                              {" "}
                              Review and Rating
                            </Link>
                          </p>
                          <p className="cursor-pointer ">
                            {" "}
                            <a href={item.download_invoice} target="_blank">
                              {" "}
                              Download Invoice
                            </a>
                          </p>
                          {/* <p className="cursor-pointer ">
                            {" "}
                            <Link
                              to={{
                                pathname: `/rating/${item.orderId}`,
                                state: {
                                  orderId: `${item.orderId}`,
                                },
                              }}
                            >
                              {" "}
                              Rating
                            </Link>
                          </p> */}
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </td>
            ) : null}
          </tr>
        ))}
      </table>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245 , 0.20);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default CompletedOrder;
