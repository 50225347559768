import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";
import { Loader } from "../shared-components";

const headerTabs = [
  {
    label: "GlenService Product",
    link: "/support/glenService-product",
    subLabel: "Purchased on - GlenService.in",
  },
  {
    label: "Other Prdoucts",
    link: "/support/non-glenService-warranty",
    subLabel: "Purchased on - Other Platforms",
  },
];

const InitateWarranty = (params): JSX.Element => {
  const authToken = localStorage.getItem("token") || "";
  const [orderDetail, setOrderDetail] = useState({});
  const [productQuestion, setProductQuestion] = useState<any[]>([]);
  const [category_check_list, setCategoryCheckList] = useState<string[]>([]);
  const [serviceRequest, setServiceRequest] = useState(true);
  const [serviceRequestNumber, setServiceRequestNumber] = useState(null);
  const [file, setFile] = useState([] as any);
  const [image_1, setImage1] = useState();
  const [image_2, setImage2] = useState();
  const [image_3, setImage3] = useState();
  const [image_4, setImage4] = useState();
  const [image_5, setImage5] = useState();

  const [arr, setArr] = useState([{}]);
  const [counter, setCounter] = useState(1);
  const [orderId, setOrderId] = useState();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setOrderDetail(params?.location?.state?.orderDetails);
    setProductQuestion(params?.location?.state?.productQuestion);
    setOrderId(params?.location?.state?.order_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const decrement = (totalCount) => {
    if (counter <= 1) {
      setCounter(counter);
    } else {
      setCounter(counter - 1);
    }
  };
  const increment = (totalCount) => {
    setCounter(counter + 1);
  };

  const handleChangeInput = (e, question_id, question_type_id,) => {
    if (question_type_id === 2) {
    let newArray = [...category_check_list, e.target.value];
      if (category_check_list.includes(e.target.value)) {
        newArray = newArray.filter((day) => day !== e.target.value);
      }
      setCategoryCheckList(newArray);
    }

  };

  const handleBlur = (e, element) => {
    let obj = {};

    obj = {
      question_id: element?.id,
      question_type_id: element?.question_type_id,
      answer:
        element?.question_type_id === 2
          ? category_check_list
          : e?.target?.value,
    };

    let updatedCarsArray = { ...arr, [e?.target?.name]: obj };

    setArr(updatedCarsArray);
  };


  let newObject = Object.values(arr);
  let finalArr = newObject.filter((value) => Object.keys(value).length !== 0);

  const generateServiceRequest = () => {
  
    setShowLoader(true);

    let orderId = params?.location?.state?.order_id;
    


   


    let obj = {
      product_id: orderDetail["product_id"],
      quantity: counter,
      reason: JSON.stringify(finalArr),
      warranty_type: orderDetail["warranty_type"],
      image1: testImage[0],
      image2: testImage[1],
      image3: testImage[2],
      image4: testImage[3],
      image5: testImage[4],
    };
    let obj1 = {
      product_id: orderDetail["product_id"],
      quantity: counter,
      reason: JSON.stringify(finalArr),
      order_id: orderId,
      warranty_type: orderDetail["warranty_type"],
      image1: testImage[0],
      image2: testImage[1],
      image3: testImage[2],
      image4: testImage[3],
      image5: testImage[4],
      
    };

    var form_data_obj = new FormData();
    let data = orderDetail["warranty_type"] === 3 ? obj : obj1;

    for (var key in data) {
      form_data_obj.append(key, data[key]);
    }

    apiCAll(form_data_obj, "/warranty_request/add", authToken).then((x) => {
      
      if (x?.data?.ErrorCode === 0) {
        setShowLoader(false)
        setServiceRequest(false);
        setServiceRequestNumber(x?.data?.Response?.request_no);
      }
    });
  };
  const [testImage, setTestImage] = useState([]);

  function uploadSingleFile(e) {
    // setFile([...file, URL.createObjectURL(e.target.files[0])]);

    let images = [] as any;
    let images1 = [] as any;

    for (let i = 0; i < e.target.files.length; i++) {
      images1.push(URL.createObjectURL(e.target.files[i]));
      images.push(e.target.files[i]);

      if (i == 0) {
        setImage1(e.target.files[i]);
      }
      if (i == 1) {
        setImage2(e.target.files[i]);
      }
      if (i == 2) {
        setImage3(e.target.files[i]);
      }
      if (i == 3) {
        setImage4(e.target.files[i]);
      }
      if (i == 4) {
        setImage5(e.target.files[i]);
      }
    }

    setFile(file.concat(images1));
    setTestImage(testImage.concat(images));
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }
  const handleClick = () => {
    let element: HTMLElement = document.getElementById("images") as HTMLElement;
    element.click();
  };

  return (
    <>
        {showLoader ? <Loader /> : null}
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="flex mx-2">
          <Link to="/support/non-glenService-warranty" className="self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Warranty and Repair{" "}
          </h3>
        </div>
        <div className="bg-white p-4 px-6">
          <div className="home-tabs mt-4">
            <ul className="flex justify-between shadow-secondary text-center rounded-full ">
              {headerTabs.map((x, index) => (
                <>
                  <Link to={x.link} className="flex-1  ">
                    <li
                      key={index}
                      className={`${
                        params?.location?.state?.active === index
                          ? "bg-primary text-white"
                          : ""
                      } ${
                        params?.location?.state?.active === 0
                          ? " rounded-l-full"
                          : " rounded-r-full"
                      }  sm:p-3 border-color  text-xs sm:text-sm hover: cursor-pointer text-black p-2`}
                    >
                      {x.label}
                      <p className="text-small">({x?.subLabel})</p>
                    </li>
                  </Link>
                </>
              ))}
            </ul>
          </div>
          <div className="content mt-4 text-xs sm:text-sm">
            {serviceRequest ? (
              <>
                <p className="text-xs py-1">
                  Product Name:{" "}
                  <span className="text-gray-500 ml-1">
                    {" "}
                    {orderDetail["name"]}
                  </span>
                </p>
                <p className="text-xs py-1">
                  Brand Name:{" "}
                  <span className="text-gray-500 ml-1">
                    {" "}
                    {orderDetail["brand_name"]}
                  </span>
                </p>
                
                {orderDetail["orderdate"]?"":
                <p className="text-xs py-1">
                  Manufacture Year:{" "}
                  <span className="text-gray-500 ml-1">
                    {" "}
                    {orderDetail["mfg_year"]}                    
                  </span>
                </p>
                }
                <p className="text-xs py-1">
                  Purchased Date:{" "}
                  <span className="text-gray-500 ml-1">
                    {" "}
                    {orderDetail["purchase_date"]??orderDetail["orderdate"]}
                  </span>
                </p>

                <div className="order-amount flex flex-col mt-3">
                  <div className="order-quantity flex justify-between mb-3">
                    <div className="left w-3/4">
                      <div className="order-item flex flex-col">
                        <label>Choose Item Quantity</label>
                      </div>
                    </div>
                    <div className="right w-1/5">
                      <div className="order-item flex justify-around">
                        <span
                          className="cursor-pointer self-center"
                          onClick={() => decrement(counter)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M20 12H4"
                            />
                          </svg>
                        </span>
                        {counter}
                        <span
                          className="cursor-pointer self-center"
                          onClick={() => increment(counter)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="order-quantity flex justify-between">
                    <div className="left w-full">
                      <div className="order-item flex flex-col">
                        {productQuestion.map((element, i) => (
                          <>
                            <label>
                              {i + 1}. {element["question"]}
                            </label>
                            <div className="pl-4  mb-3 flex flex-col sm:flex-row justify-between">
                              {element["question_type_id"] === 1 ? (
                                <>
                                  {element["question_option"].map(
                                    (x, index) => (
                                      <div className=" flex mt-2">
                                        <div className="radio flex items-center">
                                          <input
                                            id={`radioAdmin${i}-${index + 1}`}
                                            name={`radioAdminRule-${i}`}
                                            type="radio"
                                            value={x["options"]}
                                            className="cursor-pointer mr-2"
                                            onChange={(e): void => {
                                              handleChangeInput(
                                                e,
                                                element.id,
                                                element.question_type_id
                                              );
                                              handleBlur(e, element);
                                            }}
                                          />
                                          <label
                                            htmlFor={`radioAdmin${i}-${
                                              index + 1
                                            }`}
                                            className="radio-label cursor-pointer"
                                          >
                                            {" "}
                                            {x["options"]}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              ) : null}
                              {element["question_type_id"] === 2 ? (
                                <>
                                  {element["question_option"].map(
                                    (x, index) => (
                                      <div className=" flex mt-2">
                                        <div className="radio flex items-center">
                                          <input
                                             id={`checkboxAdmin${i}-${index + 1}`}
                                             name={`checkboxAdminRule-${i}`}
                                            type="checkbox"
                                            value={x["options"]}
                                            className="cursor-pointer mr-2 "
                                            onChange={(e): void => {
                                              handleChangeInput(
                                                e,
                                                element.id,
                                                element.question_type_id
                                              );
                                            }}
                                            onBlur={(e): void => {
                                              handleBlur(e, element);
                                            }}
                                          />
                                           <label
                                            htmlFor={`checkboxAdmin${i}-${
                                              index + 1
                                            }`}
                                            className="checkbox-label cursor-pointer"
                                          >
                                            {" "}
                                            {x["options"]}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              ) : null}
                              {element["question_type_id"] === 3 ? (
                                <>
                                  <textarea
                                    className="my-3 w-full  p-4 bg-white rounded-sm  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                    name={`textAreaAdminRule-${i}`}
                                    id={`textAreaAdmin${i}-${i + 1}`}
                                    onKeyUp={(e): void => {
                                      handleChangeInput(
                                        e,
                                        element.id,
                                        element.question_type_id
                                      );
                                      handleBlur(e, element);
                                    }}
                                   
                                  ></textarea>
                                </>
                              ) : null}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className="form-group mt-5">
                      <p className="text-left text-md font-bold mb-2">
                        Upload upto 5 images{" "}
                      </p>
                      <div className="flex flex-wrap">
                        <input
                          type="file"
                          multiple
                          id="images"
                          className="form-control hidden"
                          onChange={uploadSingleFile}
                        />
                        <span
                          className="w-28 h-28 sm:w-40 sm:h-40 border-4 mr-2 border-pink-600 flex items-center justify-center cursor-pointer"
                          onClick={handleClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-10 w-10"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 4v16m8-8H4"
                            />
                          </svg>
                        </span>

                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <div
                                key={item}
                                className="relative mr-2 w-28 h-28 sm:w-40 sm:h-40  mb-3"
                              >
                                <img
                                  src={item}
                                  alt=""
                                  className="w-28 h-28 sm:w-40 sm:h-40 border-4 border-pink-600"
                                />
                                <button
                                  type="button"
                                  onClick={() => deleteFile(index)}
                                  className="transform rotate-45 absolute -top-2 -right-1 sm:right bg-pink-200 rounded-full p-2"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M12 4v16m8-8H4"
                                    />
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={upload}
              >
                Upload
              </button> */}
                  </form>
                </div>

                <footer className="text-center mt-20">
                  <button
                    className={` ${
                      true ? "disabled:opacity-50" : ""
                    } w-3/6 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                    disabled={finalArr.length != productQuestion.length || file.length === 0}
                    onClick={generateServiceRequest}
                  >
                    CONFIRM
                  </button>
                  <p className="footer opacity-75 mt-5 text-center">
                  Copyright© 2022 <b>GlenService</b>. Designed By{" "}
                              <b><a href="https://techstreet.in">TechStreet.in</a></b>
                  </p>
                </footer>
              </>
            ) : (
              <>
                <div>
                  <p>Your Service request has been generated</p>
                  <p>Service request Number : {serviceRequestNumber}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InitateWarranty;
