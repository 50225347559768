import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom"
import { apiCAll } from "../api/api";

const RequestProduct = (): JSX.Element => {
    const authToken = localStorage.getItem("token") || "";
    const [successMessage, setSuccessMessage] = useState(false);
    const [detail, setDetail] = React.useState({
        product_name: '',
        brand_name: '',
        quantity: '',


    });
    const handleChange = (e) => {
        setDetail({ ...detail, [e?.target?.name]: e?.target?.value });
    }

    const handleSubmit = () => {




        let obj = {
            "product_name": detail.product_name,

            "brand_name": detail.brand_name,

            quantity: detail.quantity
        }

        apiCAll(obj, "/customer-request-product", authToken).then((x) => {
            if (x?.data?.ErrorCode === 0) {
                setSuccessMessage(true)


            }
        });

    }
    return (
        <>

            <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
                <div className="flex mx-2">
                    <Link to="/search" className="self-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                        </svg>
                    </Link>
                    <h3 className="text-2xl font-bold text-center py-4 flex-1 ">
                        Request product
                    </h3>
                </div>
         
            <div className=" mt-5">
                <div className="bg-white p-4 px-6">
                    {!successMessage ?
                        <div className="form">
                            <div className="order-id flex flex-col">
                                <label>Product Name*</label>
                                <input
                                    placeholder="Enter your product name"
                                    value={detail.product_name}
                                    onChange={handleChange}
                                    name="product_name"
                                    className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                />
                            </div>
                            <div className="order-id flex flex-col">
                                <label>Brand Name*</label>
                                <input
                                    placeholder="Enter your brand name"
                                    value={detail.brand_name}
                                    onChange={handleChange}
                                    name="brand_name"

                                    className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                />
                            </div>
                            <div className="order-id flex flex-col">
                                <label>Quantity*</label>
                                <input
                                    placeholder="Enter your quantity"
                                    name="quantity"
                                    value={detail.quantity}
                                    onChange={handleChange}
                                    className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                                />
                            </div>


                            <button
                                disabled={Object.values(detail).includes('')}
                                className={` ${true ? "disabled:opacity-50" : ""
                                    } w-full my-5 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                                onClick={handleSubmit}
                            >Submit</button>
                        </div> :
                        <>
                            <div className="text-center">
                                <img
                                    src={window.location.origin + "/green-tick.svg"}
                                    alt="Loader"
                                    className=" w-12 m-auto mb-3"
                                />
                                <h2 className="text-2xl mb-10 font-bold">Request Confirmation</h2>
                                <h2 className="text-xl mb-2 font-bold">Thank you for your request.</h2>
                                <p className="mb-2">We have received your request and will contact you very soon</p>
                            </div>
                        </>}
                </div>

            </div>
        </div>
        </>
    )
}

export default RequestProduct;