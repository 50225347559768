import { apiCAll } from "../api/api";

const ReturnReplacement = ():JSX.Element => {
    const authToken = localStorage.getItem("token") || "";
    apiCAll({return_type:1}, "/return-replacement-request-list", authToken).then((x) => {
        console.log(x?.data)
    })
 return(
     <>

     </>
 )
}

export default ReturnReplacement;