import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import { apiCAll } from "../api/api";
import { Loader } from "../shared-components";

const selectOption = [
  {
    name: "Initiate Return",
    value: "Initiate Return",
    id: 1,
  },
  {
    name: "Initiate Replacement",
    value: "Initiate Replacement",
    id: 2,
  },
];
const ReturnRefund = (params): JSX.Element => {
  let history = useHistory();
  const authToken = localStorage.getItem("token") || "";
  const [orderDetail, setOrderDetail] = useState({});
  const [counter, setCounter] = useState(0);
  const [reasonToReturn, setReasonToReturn] = useState<any[]>([]);
  const [productQuestion, setProductQuestion] = useState<any[]>([]);
  const [confirmRequestModal, setConfirmRequestModal] = useState(false);
  const [confirmRequestDetails, setConfirmRequestDetails] = useState({});
  const [returnType, setReturnType] = useState(null);
  const [returnReason, setReturnReason] = useState(null);
  const [srNumber, setSRNumber] = useState(null);
  const fileObj = [] as any;
  const fileArray = [] as any;
  const [category_check_list, setCategoryCheckList] = useState<any[]>([]);
  // const [checkboxObj, setCheckBoxObj] = useState({});
  const [arr, setArr] = useState([{}]);

  const orderIdDetail = () => {
    let obj = {
      order_id: params?.location?.state?.orderId,
      item_id: params?.location?.state?.itemId,
    };

    apiCAll(obj, "/order-item-detail", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setOrderDetail(x?.data?.Response);
      }
    });
  };

  const selectReason = () => {
    apiCAll({}, "/get-request-question", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setReasonToReturn(x?.data?.Response);
      }
    });
    let obj = {
      item_id: parseInt(params?.location?.state?.itemId),
      type: 1,
    };
    apiCAll(obj, "/get-product-question", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setProductQuestion(x?.data?.Response);
      }
    });
  };
  useEffect(() => {
    orderIdDetail();
    selectReason();
  }, []);

  const decrement = (totalCount) => {
    if (counter + totalCount <= 1) {
      setCounter(counter);
    } else {
      setCounter(counter - 1);
    }
  };

  const increment = (totalCount) => {
    if (counter + totalCount >= totalCount) {
      setCounter(counter);
    } else {
      setCounter(counter + 1);
    }
  };

  const handleChange = (e) => {
    setReturnType(e);
  };

  const handleReasontoReturn = (e, index, totalElement, element) => {
    setReturnReason(e?.target?.value);

    let obj = {};
    obj["id"] = element["id"];
    obj["type"] = element["type"];
    obj["question_option"] = e?.target?.value;
  };

  const [cancelOrderBox, setCancelOrderBox] = useState(false);

  const [file, setFile] = useState([] as any);
  const [image_1, setImage1] = useState();
  const [image_2, setImage2] = useState();
  const [image_3, setImage3] = useState();
  const [image_4, setImage4] = useState();
  const [image_5, setImage5] = useState();

  function uploadSingleFile(e) {
    // setFile([...file, URL.createObjectURL(e.target.files[0])]);

    let images = [] as any;
    let images1 = [] as any;

    for (let i = 0; i < e.target.files.length; i++) {
      images1.push(URL.createObjectURL(e.target.files[i]));
      images.push(e.target.files[i]);
      if (i == 0) {
        setImage1(e.target.files[i]);
      }
      if (i == 1) {
        setImage2(e.target.files[i]);
      }
      if (i == 2) {
        setImage3(e.target.files[i]);
      }
      if (i == 3) {
        setImage4(e.target.files[i]);
      }
      if (i == 4) {
        setImage5(e.target.files[i]);
      }
    }

    setFile(file.concat(images1));
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  const handleClick = () => {
    let element: HTMLElement = document.getElementById("images") as HTMLElement;
    element.click();
  };

  const handleInputChange = (e, element, index) => {
    if (element?.question_type_id === 2) {
      // setCategoryCheckList([...category_check_list, e.target.value]);
      // setCheckBoxObj({...checkboxObj, ...{[index]: !checkboxObj[index]}})
      // category_check_list.filter(x=>return x.)

      let newArray = [...category_check_list, e.target.value];
      if (category_check_list.includes(e.target.value)) {
        newArray = newArray.filter((day) => day !== e.target.value);
      }
      setCategoryCheckList(newArray);
    }
  };
  const handleBlur = (e, element) => {
    let obj = {};

    obj = {
      question_id: element?.id,
      question_type_id: element?.question_type_id,
      answer:
        element?.question_type_id === 2
          ? category_check_list
          : e?.target?.value,
    };

    let updatedCarsArray = { ...arr, [e?.target?.name]: obj };

    setArr(updatedCarsArray);
  };

  let newObject = Object.values(arr);
  let finalArr = newObject.filter((value) => Object.keys(value).length !== 0);
  const [showLoader, setShowLoader] = useState(false);

  const handleReturnRequest = () => {
    setShowLoader(true);
    console.log(category_check_list);
    // console.log(checkboxObj)
    let obj = {
      order_id: parseInt(orderDetail["order_id"]),
      quantity: counter + parseInt(orderDetail["quantity"]),
      return_type: returnType,
      reason: returnReason,
      item_id: parseInt(params?.location?.state?.itemId),
      image1: image_1,
      image2: image_2,
      image3: image_3,
      image4: image_4,
      image5: image_5,
      question_response: JSON.stringify(finalArr),
    };
    console.log(category_check_list);
    var form_data = new FormData();

    for (var key in obj) {
      form_data.append(key, obj[key]);
    }

    apiCAll(form_data, "/return", authToken).then((x) => {
      if (x?.data?.Response !== "") {
        setShowLoader(false)
        setConfirmRequestModal(true);

        setConfirmRequestDetails(x?.data);
        setSRNumber(x?.data?.Response?.request_no);
      }
    });
  };

  return (
    <>
          {showLoader ? <Loader /> : null}

      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="flex mx-2">
          <Link
            to={{
              pathname: `/return/${params?.location?.state?.orderId}`,
              state: {
                orderId: `${params?.location?.state?.orderId}`,
                return: true,
              },
            }}
            className="self-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Return and Refund
          </h3>
        </div>
        <div className="wrapper bg-white p-4 px-6">
          <p className="text-xs py-1">
            Product Name:{" "}
            <span className="text-gray-500 ml-1">
              {" "}
              {orderDetail["product_name"]}
            </span>
          </p>
          <p className="text-xs py-1">
            Order Id:{" "}
            <span className="text-gray-500 ml-1">
              {" "}
              {orderDetail["order_number"]}
            </span>
          </p>
          <p className="text-xs py-1">
            Order Date:{" "}
            <span className="text-gray-500 ml-1">
              {" "}
              {orderDetail["order_date"]}
            </span>
          </p>
          <p className="text-xs py-1">
            Order Amount:{" "}
            <span className="text-gray-500 ml-1"> {orderDetail["total"]}</span>
          </p>
          <p className="text-xs py-1">
            Quantity:{" "}
            <span className="text-gray-500 ml-1">
              {" "}
              {orderDetail["quantity"]}
            </span>
          </p>

          <div className="order-amount flex flex-col text-xs sm:text-sm">
            <h3 className="my-2">
              <b>Please Select the Quantity to place Return.</b>
            </h3>
            <div className="order-quantity flex justify-between mb-3">
              <div className="left w-3/4">
                <div className="order-item flex flex-col">
                  <label>Choose Item Quantity</label>
                </div>
              </div>
              <div className="right w-1/5">
                <div className="order-item flex justify-around">
                  <span
                    className="cursor-pointer self-center"
                    onClick={() => decrement(parseInt(orderDetail["quantity"]))}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M20 12H4"
                      />
                    </svg>
                  </span>
                  {counter + parseInt(orderDetail["quantity"])}
                  <span
                    className="cursor-pointer self-center"
                    onClick={() => {
                      increment(parseInt(orderDetail["quantity"]));
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="order-quantity flex justify-between">
              <div className="left w-3/4">
                <div className="order-item flex flex-col">
                  {reasonToReturn.map((element, index) => (
                    <>
                      <label>{element["question"]}</label>
                      <div className="px-4 shadow-primary rounded-full my-3">
                        <select
                          className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                          onChange={(e): void => {
                            handleReasontoReturn(
                              e,
                              index + 1,
                              reasonToReturn.length,
                              element
                            );
                          }}
                        >
                          <option value="select">Select</option>
                          {element["question_option"].map((x) => (
                            <>
                              <option value={x["question"]}>
                                {x["question"]}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="order-quantity flex justify-between">
              <div className="left w-full">
                <div className="order-item flex flex-col">
                  {productQuestion.map((element, index) => (
                    <>
                      <label>{element["question"]}</label>
                      <div className="pl-4  my-3 flex justify-between flex-col md:flex-row">
                        {element["question_type_id"] === 1 ? (
                          <>
                            {element["question_option"].map((x, i) => (
                              <div className=" flex mt-2">
                                <div className="radio flex items-center">
                                  <input
                                    id={`radio${i}-${index + 1}`}
                                    name={`radio${index}`}
                                    type="radio"
                                    value={x["options"]}
                                    className="cursor-pointer mr-2"
                                    onChange={(e): void => {
                                      handleInputChange(e, element, index);
                                      handleBlur(e, element);
                                    }}
                                  />
                                  <label
                                    htmlFor={`radio${i}-${index + 1}`}
                                    className="radio-label cursor-pointer"
                                  >
                                    {" "}
                                    {x["options"]}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                        {element["question_type_id"] === 2 ? (
                          <>
                            {element["question_option"].map((x, index) => (
                              <div className="w-full flex  mt-2">
                                <div className="checkbox flex items-center">
                                  <input
                                    id={`checkbox-${index + 1}`}
                                    name="checkbox"
                                    type="checkbox"
                                    value={x["options"]}
                                    // checked = {checkboxObj[index] || false}
                                    className="cursor-pointer mr-2"
                                    onChange={(e): void => {
                                      handleInputChange(e, element, index);
                                    }}
                                    onBlur={(e): void => {
                                      handleBlur(e, element);
                                    }}
                                  />
                                  <label
                                    htmlFor={`checbox-${index + 1}`}
                                    className="checkbox-label cursor-pointer"
                                  >
                                    {" "}
                                    {x["options"]}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                        {element["question_type_id"] === 3 ? (
                          <>
                            <textarea
                              onChange={(e): void => {
                                handleInputChange(e, element, index);
                              }}
                              onClick={(e): void => {
                                handleBlur(e, element);
                              }}
                              name="textbox"
                              className="my-3 w-full  p-4 bg-white rounded-sm  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            ></textarea>
                          </>
                        ) : null}
                        {element["question_type_id"] === 4 ? (
                          <select
                            className="my-3 w-full bg-white  placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            onChange={(e): void => {
                              handleReasontoReturn(
                                e,
                                index + 1,
                                productQuestion.length,
                                element
                              );
                            }}
                          >
                            <option value="select">Select</option>
                            {element["question_option"].map((x) => (
                              <>
                                <option value={x["options"]}>
                                  {x["options"]}
                                </option>
                              </>
                            ))}
                          </select>
                        ) : null}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="return-replacement mt-6 text-center text-xs sm:text-sm">

          {/* <div className=" flex">
            <input 
              type="checkbox"
              className="cursor-pointer mr-2"
              checked 
              onClick={()=>{handleChange(1)}} 
              disabled
            />
            <label
              htmlFor={`radioReturn-${1}`}
              className="radio-label cursor-pointer"
            >
              Initiate Return
            </label>
          </div> */}

            {/* <h5>Select an Action</h5>
            <div className="choose-option flex justify-around mt-3">
              {selectOption.map((item, index) => (
                <>
                  <div className="w-1/2">
                    <div className="radio flex align-center items-center">
                      <input
                        id={`radioReturn-${index + 1}`}
                        name="radio"
                        type="radio"
                        onChange={() => handleChange(item.id)}
                        value={item.value}
                        className="cursor-pointer mr-2"
                      />
                      <label
                        htmlFor={`radioReturn-${index + 1}`}
                        className="radio-label cursor-pointer"
                      >
                        {" "}
                        {item.name}
                      </label>
                    </div>
                  </div>
                </>
              ))}
            </div> */}
            <form>
              <div className="form-group mt-5">
                <p className="text-left text-md font-bold mb-2">
                  Upload upto 5 images{" "}
                </p>
                <div className="flex flex-wrap">
                  <input
                    type="file"
                    multiple
                    id="images"
                    className="form-control hidden"
                    onChange={uploadSingleFile}
                  />
                  <span
                    className="w-28 h-28 sm:w-40 sm:h-40 border-4 mr-2 border-pink-600 flex items-center justify-center cursor-pointer"
                    onClick={handleClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </span>

                  {file.length > 0 &&
                    file.map((item, index) => {
                      return (
                        <div
                          key={item}
                          className="relative mr-2 w-28 h-28 sm:w-40 sm:h-40  mb-3"
                        >
                          <img
                            src={item}
                            alt=""
                            className="w-28 h-28 sm:w-40 sm:h-40 border-4 border-pink-600"
                          />
                          <button
                            type="button"
                            onClick={() => deleteFile(index)}
                            className="transform rotate-45 absolute -top-2 -right-1 sm:right bg-pink-200 rounded-full p-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4v16m8-8H4"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={upload}
              >
                Upload
              </button> */}
            </form>
          </div>

          <footer className="text-center mt-20">
            <button
              className={` ${
                !returnType || !returnReason || file.length === 0
                  ? "disabled:opacity-50"
                  : ""
              } w-3/6 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
              disabled={file.length === 0}
              onClick={handleReturnRequest}
            >
              CONFIRM
            </button>
            <p className="footer opacity-75 mt-5 text-center">
              Copyright© 2022 <b>GlenService</b>. Designed By{" "}
              <b>
                <a href="https://techstreet.in">TechStreet.in</a>
              </b>
            </p>
          </footer>
        </div>
      </div>

      {confirmRequestModal ? (
        <Popup
          closeOnDocumentClick={false}
          onClose={() => setCancelOrderBox(false)}
          open
          modal
        >
          {(close) => (
            <div className="modal">
              <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                Confirmation Status
              </div>
              <div className="content mx-2 my-2">
                <p className="text-center">
                  {" "}
                  Your {confirmRequestDetails["ErrorMessage"]}
                </p>
                <p className="text-center">Request SR Number: {srNumber}</p>
                <div className="button-list flex  justify-around">
                  <Link
                    to={{
                      pathname: `/return/${params?.location?.state?.orderId}`,
                      state: {
                        orderId: `${params?.location?.state?.orderId}`,
                        return: true,
                      },
                    }}
                    className="contact-support rounded-full bg-white text-xs py-3 text-center mt-4 w-1/3 font-bold shadow-secondary text-primary"
                  >
                    Back
                  </Link>
                  <button className="contact-support rounded-full bg-white text-xs h-10 w-1/3 mt-4 font-bold shadow-secondary text-primary">
                    <Link to="/home">Continue Shopping</Link>
                  </button>
                </div>
              </div>
            </div>
          )}
        </Popup>
      ) : null}
    </>
  );
};

export default ReturnRefund;
