import { Footer } from "../shared-components"

const ShipppingPolicy = ():JSX.Element => {
    return(
        <>
        <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <p><strong>Shipping Policy</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>What are the shipping charges?</strong></p>
<p>No Delivery charges are applicable on your order,minimum order value will be 500 for the 2nd order of an individual customer</p>
<p><strong>What is the estimated delivery time?</strong></p>
<p>Delivery is done within 3 hours on the same day for orders that are done before 3 pm.</p>
<p>Orders after 3 pm are delivered the next day by 11 am.</p>
<p><strong>How will the delivery be done?</strong></p>
<p>We process all deliveries through our own fleet of riders. In some cases, where your pin code is a little outside our primary delivery zone, we use the support of our delivery partners from Dunzo, Borzo, Porter etc.</p>
<p><strong>How are items packaged?</strong></p>
<p>All items are in their natural packaging. Items are sent to you in an organic, recycled paper bag that is able to withstand both, the temperature as well as the weight of the products.</p>

        <Footer />
        </div>
        </>
    )
}
export default ShipppingPolicy;