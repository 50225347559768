import { profile } from "console";
import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { apiCAll } from "../api/api";
import SideBar from "./SideBar";


type HeaderProps = {
  profileDetails?: {};
  addressListData?: [];
};
const selectOption = [
  {
    name: "Home",
    value: "Home",
    id: "Home",
  },
  {
    name: "Office",
    value: "Office",
    id: "Office",
  },
  {
    name: "Others",
    value: "Others",
    id: "Others",
  },
];
const Header = ({
  profileDetails,
  addressListData,
}: HeaderProps): JSX.Element => {
  const authToken = localStorage.getItem("token") || "";
  const [sideBar, setSideBar] = useState(false);
  const [addressbar, setAddressBar] = useState(false);
  const [addressList, setAddressList] = useState(addressListData);
  const [currentAddress, setCurrentAddress] = useState("");
  const [address, setAddress] = useState({
    address: "",
    landmark: "",
    pincode: "",
  });

  const openSideBar = () => {
    setSideBar(true);
  };

  const setSideBarCallBack = useCallback((show) => {
    setSideBar(show);
  }, []);
  const openAddressBar = () => {
    setAddressBar(!addressbar);
  };

  useEffect(() => {
    apiCAll({}, "/address/list", authToken).then((address) => {
      if (address?.data?.ErrorCode === 0) {
        setAddressList(address?.data?.Response);
        // eslint-disable-next-line array-callback-return
        const defaultAddress = address?.data?.Response?.find((x) => {
          if (x.is_default === 1) {
            return x;
          }
        });

        setCurrentAddress(defaultAddress);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAddress = (address) => {
    setCurrentAddress(address);
    setAddressBar(false);
    apiCAll({ address_id: address.id }, "/address/default", authToken).then(
      (x) => {
        if (x?.data?.ErrorCode === 0) {
          window.location.reload();
        }
      }
    );
  
  };

  const removeAddress = (id) => {
    apiCAll({ address_id: id }, "/address/delete", authToken).then((X) => {
      if (X?.data?.ErrorCode === 0) {
        alert("Address has been Removed Successfully");
        window.location.reload();
      }
    });
  };

  const handleChange = (e) => {
    setAddress({ ...address, [e?.target?.name]: e?.target?.value });
   
  };

  const submitAddressDetails = () => {
    let obj = {
      name: profileDetails?.["name"],
      mobile_number: localStorage.getItem("phone"),
      address: address["address"],
      landmark: address["landmark"],
      pincode: address["pincode"],
      address_type: returnType,
    };
    apiCAll(obj, "/address/add", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        alert("New Address has been updated Successfully!");
        window.location.reload();
      }
    });
  };
  const [returnType, setReturnType] = useState(null);

  const handleAddressEditCount = (e) => {
    setReturnType(e);
  };
  return (
    <>
      <header className="flex ">
        <div className="flex flex-1 flex-col">
          <div className="address flex flex-1 text-primary  items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            </svg>
            <span onClick={openAddressBar} className="cursor-pointer text-xs">
              {currentAddress?.["address"]}
            </span>
          </div>
          {/* {currentAddress["is_default"] == 0 ? <p className="text-red-600 text-xs ml-5">We  are not serviceble at this pincode</p> : null} */}
        </div>

        <div className="menu text-primary cursor-pointer" onClick={openSideBar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </div>

        {sideBar ? (
          <SideBar
            profileDetails={profileDetails}
            className="60%"
            sideBar={setSideBarCallBack}
          />
        ) : null}
      </header>

      {addressbar ? (
        <>
          <div
            className="overlay z-10 absolute bg-black w-full h-full top-0 left-0 opacity-60"
            onClick={() => setAddressBar(false)}
          ></div>
          <div
            className={`${
              addressbar ? "showAddressBar" : "hideAddressBar"
            } z-50 fixed bottom-0 bg-pink-100 left-0 right-0 px-5 py-2  rounded-t-2xl`}
          >
            <h3 className="font-bold  pt-2 pb-3">
              Saved Addresses{" "}
              <span className="text-sm float-right text-primary underline cursor-pointer">
                <Popup
                  closeOnDocumentClick={false}
                  trigger={
                    <button className="text-primary underline cursor-pointer text-sm">
                      Add Address
                    </button>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal">
                      <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                        Add your new address here{" "}
                        <button
                          className="close cursor-pointer  text-2xl"
                          onClick={close}
                        >
                          &times;
                        </button>
                      </div>
                      <div className="content mx-2 my-2">
                        <div className="mb-3">
                          <div className="order-id flex flex-col">
                            <label>Address*</label>
                            <input
                              // minLength={3}
                              value={address["address"]}
                              onChange={handleChange}
                              name="address"
                              placeholder="Enter your address here"
                              className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                            {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                          </div>
                          <div className="order-id flex flex-col">
                            <label>Landmark*</label>
                            <input
                              // minLength={3}
                              value={address["landmark"]}
                              onChange={handleChange}
                              name="landmark"
                              placeholder="Enter your landmark here"
                              className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                            {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                          </div>
                          <div className="order-id flex flex-col">
                            <label>Pincode*</label>
                            <input
                              // minLength={3}
                              value={address["pincode"]}
                              onChange={handleChange}
                              name="pincode"
                              placeholder="Enter your pincode here"
                              className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                            />
                            {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                            <div className="flex flex-col">
                              <div className="return-replacement mt-6 text-center">
                                <h5>Choose your address type</h5>
                                <div className="choose-option flex justify-around mt-3">
                                  {selectOption.map((item, index) => (
                                    <>
                                      <div className="w-1/2">
                                        <div className="radio">
                                          <input
                                            id={`radioReturn-${index + 1}`}
                                            name="addressType"
                                            type="radio"
                                            onChange={() =>
                                              handleAddressEditCount(item.id)
                                            }
                                            // checked={item.id === "Home"}
                                            value={item.value}
                                            className="cursor-pointer"
                                          />
                                          <label
                                            htmlFor={`radioReturn-${index + 1}`}
                                            className="radio-label cursor-pointer"
                                          >
                                            {" "}
                                            {item.name}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <footer className="text-center mt-10">
                            <button
                              className="w-3/6 rounded-full bg-primary text-md h-12 text-white cursor-pointer"
                              //   disabled={!returnType || !returnReason}
                              onClick={submitAddressDetails}
                            >
                              Add Address
                            </button>
                            <p className="footer opacity-75 mt-5 text-center">
                              Copyright© 2022 <b>GlenService</b>. Designed By{" "}
                              <b><a href="https://techstreet.in">TechStreet.in</a></b>
                            </p>
                          </footer>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              </span>
            </h3>
            {addressList &&
              addressList.map((address) => (
                <div className="flex border-t border-opacity-10 border-black justify-between py-2.5 items-center">
                  <div className="flex items-center">
                    <div className="opacity-50 mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={() => changeAddress(address)}
                      className="cursor-pointer"
                    >
                      <p className="text-sm">{address["address_type"]}</p>
                      <p className="text-xs opacity-50">{address["address"]}</p>
                      {address["is_servicable"] === 0 ? (
                        <p className="text-red-600 text-xs">
                          We are not serviceble at this pincode
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="opacity-50 cursor-pointer">
                    <Popup
                      trigger={
                        <div className="menu-item">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                            />
                          </svg>{" "}
                        </div>
                      }
                      position="left top"
                      on="click"
                      closeOnDocumentClick
                      mouseLeaveDelay={300}
                      mouseEnterDelay={0}
                      contentStyle={{ padding: "0px", border: "none" }}
                      arrow={false}
                    >
                      <div className="px-2">
                        <div className="menu-item border-b py-1 pt-2 cursor-pointer hover:opacity-50">
                          {" "}
                          <Link
                            to={{
                              pathname: "/edit-address",
                              state: {
                                id: address["id"],
                              },
                            }}
                          >
                            Edit
                          </Link>
                        </div>
                        <div
                          className="menu-item py-1 pb-2 cursor-pointer  hover:opacity-50"
                          onClick={() => removeAddress(address["id"])}
                        >
                          Remove
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : null}

      <style jsx>
        {`
          body {
            // overflow: hidden;
          }
          .menu {
            transform: scaleX(-1);
          }

          .showAddressBar {
            animation: all ease 0.3s;
            will-change: transform;
          }
          .hideAddressBar {
            top: 100%;
            height: 0;
          }

          @keyframes bottomToTop {
            0% {
              height: 0%;
            }
            100% {
              height: 50%;
            }
          }
        `}
      </style>
    </>
  );
};

export default Header;
