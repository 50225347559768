import React, { useState, useReducer, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";
import { Loader } from "../shared-components";

const authToken = localStorage.getItem("token") || "";

const initialState = {};

function reducer(state, action) {
  switch (action.type) {
    case "SET":
      return {
        ...state,
        [action.name]: { ...(state[action.name] || null), value: action.value },
      };
    case "SET_ERROR":
      return {
        ...state,
        [action.name]: {
          ...(state[action.name] || null),
          errorMessage: action.errorMessage,
          isValid: action.isValid,
        },
      };

    default:
      return state;
  }
}

const Registration = (): JSX.Element => {
  const [addressToggle, setAddressToggle] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [latitude, setLatitude] = useState(Number);
  const [longitude, setLongitude] = useState(Number);
  const registrationData = [
    {
      label: "Doctor Name*",
      name: "doctor_name",
      validation: [
        {
          ruleType: "regex",
          message: "",
          payload: /^[a-zA-Z ]+$/,
        },
        {
          ruleType: "minLength",
          message: "Minimum 3 characters",
          payload: 3,
        },
      ],
      required: true,
      isValid: true,
      errorMessage: "",
    },
    {
      label: "Store Name",
      name: "clinic_name",
      validation: [
        {
          ruleType: "minLength",
          message: "Minimum 3 character allowed",
          payload: 3,
        },
      ],
      isValid: false,
      required: false,
      errorMessage: "",
    },
    {
      label: "GSTIN",
      name: "gstin",
      validation: [
        {
          ruleType: "minLength",
          message: "GSTIN should be characters",
          payload: 15,
        },
      ],
      isValid: false,
      required: false,
      errorMessage: "",
      maxLength: 15,
    },
    {
      label: "Email Id*",
      name: "email",
      validation: [
        {
          ruleType: "minLength",
          message: "Email Id is required",
          payload: 1,
        },
      ],
      isValid: true,
      required: true,
      errorMessage: "",
    },
    {
      label: "Delivery Address*",
      name: "address",
      validation: [
        {
          ruleType: "minLength",
          message: "Delivery Address is required",
          payload: 1,
        },
      ],
      isValid: true,
      required: true,
      errorMessage: "",
    },
    {
      label: "Landmark*",
      name: "landmark",
      validation: [
        {
          ruleType: "minLength",
          message: "Landmark is required",
          payload: 1,
        },
      ],
      isValid: true,
      required: true,
      errorMessage: "",
    },
    {
      label: "Pincode*",
      name: "pincode",
      validation: [
        {
          ruleType: "minLength",
          message: "6 digits are required",
          payload: 6,
        },
      ],
      isValid: true,
      required: true,
      errorMessage: "",
      maxLength: 6,
    },
    {
      label: "Latitude",
      name: "latitude",
      value: "133",
      isValid: false,
      required: false,
      errorMessage: "",
      maxLength: 6,
      validation: [
        {
          ruleType: "minLength",
          message: "2 digits are required",
          payload: 2,
        },
      ],
    },
    {
      label: "Longitude",
      name: "longitude",
      validation: [
        {
          ruleType: "minLength",
          message: "2 digits are required",
          payload: 2,
        },
      ],
      isValid: false,
      required: false,
      errorMessage: "",
      maxLength: 6,
    },
    {
      label: "Alternate Delivery Address",
      name: "alternate_address",
      validation: [
        {
          ruleType: "minLength",
          message: "Alternate Delivery Address is required",
          payload: 1,
        },
      ],
      isValid: true,
      required: false,
      errorMessage: "",
    },
    {
      label: "Landmark*",
      name: "alternate_landmark",
      validation: [
        {
          ruleType: "minLength",
          message: "Landmark is required",
          payload: 1,
        },
      ],
      isValid: true,
      required: false,
      errorMessage: "",
    },
    {
      label: "Pincode",
      name: "alternate_pincode",
      validation: [
        {
          ruleType: "minLength",
          message: "6 digits are required",
          payload: 6,
        },
      ],
      isValid: true,
      errorMessage: "",
      required: false,
      maxLength: 6,
    },
    {
      label: "Latitude",
      name: "alternate-latitude",

      isValid: true,
      required: false,
      errorMessage: "",
      maxLength: 6,
    },
    {
      label: "Longitude",
      name: "alternate-longitude",

      isValid: true,
      required: false,
      errorMessage: "",
      maxLength: 6,
    },
  ];

  const handleChange = (e) => {
    dispatch({
      type: "SET",
      value: e.target.value,
      name: e.target.name,
    });
  };

  const blurHandler = (e, data) => {
    checkValidation(data, e);
  };

  const checkValidation = (data, event?) => {
    const validations = data?.validation;
    const value = state[data.name]?.value || "";
    let isValid = true;

    for (let i = 0; i < validations?.length; i++) {
      switch (validations[i]?.ruleType) {
        case "regex":
          if (!validations[i].payload.test(value + event?.key)) {
            isValid = false;
            event?.preventDefault();
            if (event) {
              handleChange(event);
            }
          }
          break;
        case "minLength":
          if (value?.length < validations[i].payload) {
            isValid = false;
          }
          break;
        default:
          break;
      }

      if (!isValid) {
        dispatch({
          type: "SET_ERROR",
          name: data.name,
          errorMessage: validations[i].message,
          isValid: isValid,
        });
        break;
      } else {
        dispatch({
          type: "SET_ERROR",
          name: data.name,
          errorMessage: "",
          isValid: isValid,
        });
      }
    }
    return isValid;
  };
  let history = useHistory();

  const renderForm = (data) => {
    const arr: any = [];
    const isEven = data.length % 2 === 0;
    const length = isEven ? data.length : data.length - 1;

    for (let i = 0; i < length; i += 2) {
      arr.push(
        <div
          key={data[i]?.name}
          className="flex row text-left  justify-between mb-2"
        >
          <div className="w-44">
            <label className="text-sm">{data[i].label}</label>
            <input
              autoComplete="off"
              placeholder={data[i].label}
              className="mt-1 mb-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
              value={state[data[i].name]?.value || ""}
              name={data[i].name}
              onChange={handleChange}
              onKeyUp={(event) => blurHandler(event, data[i])}
              maxLength={data[i]?.maxLength}
            />
            {state[data[i].name]?.errorMessage ? (
              <p className="text-xs mb-2 text-red-600">
                {state[data[i].name]?.errorMessage}
              </p>
            ) : null}
          </div>
          <div className="w-44">
            <label className="text-sm">{data[i + 1].label}</label>
            <input
              autoComplete="off"
              placeholder={data[i + 1].label}
              className="mt-1 mb-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
              value={state[data[i + 1]?.name]?.value || ""}
              name={data[i + 1]?.name}
              maxLength={data[i + 1]?.maxLength}
              onChange={handleChange}
              onKeyUp={(event) => blurHandler(event, data[i + 1])}
            />
            {state[data[i + 1].name]?.errorMessage ? (
              <p className="text-xs mb-2 text-red-600">
                {state[data[i + 1].name]?.errorMessage}
              </p>
            ) : null}
          </div>
        </div>
      );
    }
    if (!isEven) {
      const lastIndex = data.length - 1;
      arr.push(
        <div
          key={data[lastIndex]?.name}
          className="flex row text-left  justify-between mb-2"
        >
          <div className="w-44">
            <label className="text-sm">{data[lastIndex]?.label}</label>
            <input
              autoComplete="off"
              placeholder={data[lastIndex]?.label}
              className="mt-1 mb-2 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
              value={state[data[lastIndex]?.name]?.value || ""}
              name={data[lastIndex]?.name}
              maxLength={data[lastIndex]?.maxLength}
              onChange={handleChange}
              onKeyUp={(event) => blurHandler(event, data[lastIndex])}
            />
            {state[data[lastIndex]?.name]?.errorMessage ? (
              <p className="text-xs mb-2 text-red-600">
                {state[data[lastIndex]?.name]?.errorMessage}
              </p>
            ) : null}
          </div>
        </div>
      );
    }
    return arr;
  };
  const renderRegistrationFormElement = renderForm(
    registrationData.slice(0, 9)
  );
  const renderAlternateFormElement = renderForm(
    registrationData.slice(9, registrationData.length)
  );
  const [showLoader, setShowLoader] = useState(false);

  const registrationForm = () => {
    let isFormValid = true;
    for (let i = 0; i < registrationData.length; i++) {
      if (registrationData[i].required) {
        const elementValid = checkValidation(registrationData[i]);
        isFormValid = isFormValid && elementValid;
      }
    }
    if (isFormValid) {
      let obj = {};

      const values = Object.keys(state).map((k) => state[k].value);
      const key = Object.keys(state);
      values.forEach((element, index) => {
        obj[key[index]] = element;
      });

      const phone = localStorage.getItem("phone") || "";

      let obj1 = {
        phone: phone,
        latitude: 35.32,
        longitude: 40.0121,
        is_alternate_address: addressToggle,
        alternate_latitude: 40.2,
        alternate_longitude: 65.21,
      };

      setShowLoader(true);

      let merged = { ...obj, ...obj1 };

      apiCAll(merged, "/register", authToken).then((x) => {
        if (x.data.ErrorCode === 0) {
          setShowLoader(false)
          localStorage.setItem("token", x.data?.token);
          history.push("/home");
        } else {
          console.log("Error:", x.data.ErrorMessage);
          setShowLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    // getMyLocation();
    // (
    //   document.getElementsByName("latitude") as unknown as HTMLTextAreaElement
    // )[0].value = latitude;
    // (
    //   document.getElementsByName("longitude") as unknown as HTMLTextAreaElement
    // )[0].value = longitude;
  });

  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error);

          // setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        }
      );
    }
  };

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="mt-10">
        <header>
          <h3 className="text-2xl text-center text-primary font-bold">
            GlenService
          </h3>
        </header>
        <div className="insta-dental-form mt-5 mx-5 text-center lg:w-1/2 my-0 md:mx-auto">
          {renderRegistrationFormElement}
          <div className="alternateAddress flex justify-between mt-4">
            <h4 className="font-bold text-xl w-3/4 text-left">
              Add Another Delivery Address:
            </h4>
            <div className="flex items-center justify-end w-1/5 mb-6">
              <label
                htmlFor="toggleB"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    type="checkbox"
                    id="toggleB"
                    className="sr-only"
                    onClick={() => setAddressToggle(!addressToggle)}
                  />

                  <div className="block bg-secondary border border-black border-opacity-25 w-14 h-8 rounded-full"></div>

                  <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                </div>
              </label>
            </div>
          </div>
          {addressToggle ? renderAlternateFormElement : null}
          <div className="button-list mt-4 flex flex-col">
            <button
              className="login rounded-full bg-primary text-md h-12 text-white"
              onClick={() => registrationForm()}
            >
              SUBMIT
            </button>
            <Link
              to="/contactus"
              className="contact-support rounded-full bg-white text-md py-3 text-center mt-16 font-bold shadow-secondary text-primary"
            >
              CONTACT SUPPORT
            </Link>
            <p className="footer opacity-75 mt-12 text-center">
              Copyright© 2022 <b>GlenService</b>. Designed By{" "}
              <b>
                <a href="https://techstreet.in">TechStreet.in</a>
              </b>
            </p>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          input:checked ~ .dot {
            transform: translateX(100%);
            background-color: #9c1e60;
          }
        `}
      </style>
    </>
  );
};

export default Registration;
