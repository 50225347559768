import { Link } from "react-router-dom";

const Footer = (): JSX.Element => {
  return (
    <>
      <p className="footer  mt-12 text-center">
        <p className="text-xxs flex justify-around sm:justify-between text-primary font-bold flex-wrap">
          {/* <span className="underline">
            <Link to="/about-us" target="_blank">
              About Us
            </Link>
          </span> */}
          <span className="underline">
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
          </span>
          {/* <span className="underline">
            <Link to="/shipping-policy" target="_blank">
              Shipping Policy
            </Link>
          </span> */}
          <span className="underline">
            <Link to="#" target="_blank">
              Refund & Replacement
            </Link>
            {/* <Link to="/return-refund-policy" target="_blank">
              Refund & Replacement
            </Link> */}

          </span>
          <span className="underline">
            <Link to="#" target="_blank">
              Terms & Condition
            </Link>
            {/* <Link to="/terms-and-condition" target="_blank">
              Terms & Condition
            </Link> */}
          </span>
        </p>
        <p className="opacity-75 text-xs my-2">
        Copyright© 2022 <b>Glen Appliances Pvt. Ltd.</b> Designed By{" "}
                              <b><a href="https://techstreet.in">TechStreet.in</a></b>
        </p>
      </p>
    </>
  );
};
export default Footer;
