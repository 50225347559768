import { useEffect, useState } from "react";
import { apiCAll } from "../api/api";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";

type NewOrderProps = {
  data: any;
  updatedCartList?: any;
};

const NewOrder = ({ data, updatedCartList }: NewOrderProps): JSX.Element => {
  const tableDataHeaderKeys = Array.isArray(data) && data.length ? data[0] : {};

  const [counter, setCounter] = useState({});
  const [cancelOrderBox, setCancelOrderBox] = useState(false);
  const [pendingItemId, setPendingItemId] = useState([])

  useEffect(() => {
    const tableDataHeaderKeys = Array.isArray(data) && data.length ? data[0] : {};

    let count = {};
    for (var input = 1; input <= data?.length; input++) {
      count[input] = 0;
    }

    setCounter(count);
  }, [data]);

  const authToken = localStorage.getItem("token") || "";

  const increment = (item, id) => {
    // setCounter({ ...counter, [id]: counter[id] + 1 });
    let obj = {
      offer_price: parseFloat(item.offer_price),
      rate: parseFloat(item.rate),
      quantity: item.quantity + counter[id] + 1,
      product_id: item.id,
    };
    apiCAll(obj, "/cart-add", authToken).then((x) => {
      updatedCartList();
    });
  };

  const decrement = (item, id) => {
    let obj = {
      offer_price: parseFloat(item.offer_price),
      rate: parseFloat(item.rate),
      quantity: item.quantity + counter[id] - 1,
      product_id: item.id,
    };
    apiCAll(obj, "/cart-add", authToken).then((x) => {
      updatedCartList();
    });
    if (counter[id] === 0) {
      setCounter({ ...counter, [id]: counter[id] - 1 });
    } else if (counter[id] === -1) {
      setCounter({ ...counter, [id]: counter[id] });
    } else {
      setCounter({ ...counter, [id]: counter[id] - 1 });
    }
  };

  const [textBoxInputValue, setTextBoxInputValue] = useState("");
  const [showCancelSuccessfully, setShowCancelSuccessfully] = useState(false);

  const cancelOrderAPI = (orderID) => {
    let obj = {
      order_id: orderID,
      cancel_reason: textBoxInputValue,
    };
    apiCAll(obj, "/order-cancel", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowCancelSuccessfully(true);
      }
    });
  };

  const handleCancel = () => {
    setCancelOrderBox(true);
    setShowCancelSuccessfully(false);
    setTextBoxInputValue("");
  };

  // console.log(data)

  const findOrderNumbers = (data) => {
    let orderNumberArr = data.map(v => v.order_number);
    return orderNumberArr.every(checkNullValue);
  }

  const checkNullValue = (value) => {
    return value === null
  }

  return (
    <>
      <table className="shadow-primary bg-white mt-4 w-full">
        {data.length >= 1 ? (
          <tr className="flex">
            {tableDataHeaderKeys.id ? (
              <th className="border text-center px-1 py-2 text-sm w-1/12">S.No.</th>
            ) : null}

            {!findOrderNumbers(data)  ? (
              <th className="border text-left px-2 py-2 text-sm w-64">
                Pending Order
              </th>
             ) : null}

            {tableDataHeaderKeys.product_name ? (
              <th className="border text-left px-2 py-2 text-sm w-64">
                Product Name
              </th>
            ) : null}

            {tableDataHeaderKeys.quantity ? (
              <th className="border text-center px-2 py-2 text-sm w-64">Quantity</th>
            ) : null}

            {tableDataHeaderKeys.offer_price ? (
              <th className="border text-center px-2 py-2 text-sm w-1/6">Price(&#8377;)</th>
            ) : null}

            {tableDataHeaderKeys.amount!=null ? (
              <th className="border text-center px-2 py-2 text-sm w-1/6">Amount(&#8377;)</th>
            ) : null}
          </tr>
        ) : null}

      
       
        {data.map((item: any, index) => (
          <tr className="tabe-row flex">
            {item.id ? (
              <td className="border text-center px-1 py-2 text-sm w-1/12">{index + 1} </td>
            ) : null}

            {/* {item.order_number ? (
              <td className="border px-2 py-2 text-sm w-64">
                #
                <Link className="hyperlink" to={`/order/` + item.order_number}>
                  {item.order_number}
                </Link>
              </td>
            ) : <td className="border px-2 py-2 text-sm w-64">
              Unavailable
            </td>} */}
           
             {findOrderNumbers(data) ? null :
            <td className="border px-2 py-2 text-sm w-64">
              {item.order_number ?<>
              #
              <Link className="hyperlink" to={`/order/` + item.order_number}>
                {item.order_number}
              </Link>
              </> : '---'}
            </td> }
          

            {item.product_name ? (
              <td className="border px-2 py-2 text-sm w-64">
                {item.product_name}
                {item.short_description ? (
                  <p className="text-xs">{item.short_description}</p>
                ) : null}
              </td>
            ) : null}

            {item.quantity ? (
              <td className="border-x-1 sm: border text-center  px-2 py-2 text-sm pt-4 w-64">

                {item?.["avaliable_stock"] < item?.quantity ?

                  <div className="counter md:self-center font-bold text-sm md:text-md  text-center text-red-600">
                    Out of Stock
                  </div> : <div className="text-center flex justify-around">
                    <span
                      className="cursor-pointer self-center"
                      onClick={() => decrement(item, index + 1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M20 12H4"
                        />
                      </svg>
                    </span>
                    {item.quantity + counter[index + 1]}
                    <span
                      className="cursor-pointer self-center"
                      onClick={() => {
                        increment(item, index + 1);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                    </span>
                  </div>}
                {item?.["avaliable_stock"] <= 1 && item?.["avaliable_stock"] >= 1 && item?.["quantity"] <= item?.["avaliable_stock"] ? (<p className="text-red-500 text-center text-xxs  ">
                  Only  {item?.["avaliable_stock"]} left!
                </p>) : null}
                <div dangerouslySetInnerHTML={{ __html: item?.["group_price"] }} className="text-xxs text-green-700 text-center mt-3" />
              </td>
            ) : null}
            {item.offer_price ? (
              <td className="border text-center px-2 py-2 text-sm w-1/6">{item.offer_price}</td>
            ) : null}
            {item.amount!=null ? (
              <td className="border text-center px-2 py-2 text-sm w-1/6">{item.amount}</td>
            ) : null}
          </tr>
        ))}
      </table>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245 , 0.20);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default NewOrder;
