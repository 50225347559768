import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";
import { Footer, Loader } from "../shared-components";

const ContactUs = (props): JSX.Element => {
  const authToken = localStorage.getItem("token") || "";
  const [showLoader, setShowLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [detail, setDetail] = React.useState({
    name: props?.location?.state?.profileDetails?.name || "",
    email: props?.location?.state?.profileDetails?.email || "",
    phone: localStorage.getItem("phone") || "",
    query: props?.location?.state?.profileDetails?.query || "",
  });

  const handleChange = (e) => {
    setDetail({ ...detail, [e?.target?.name]: e?.target?.value });
  };

  const handleSubmit = () => {
    setShowLoader(true);
    let requestForOpenContactUs = {
      name: detail.name,
      mobile_no: detail.phone,
      email: detail.email,
      subject: detail.query,
      query: detail.query,
    };
    let requestForContactUs = {
      name: detail.name,
      mobile_no: localStorage.getItem("phone") || detail.phone,
      email: detail.email,
      subject: detail.query,
      query: detail.query,
    };
    let url = localStorage.getItem("token") ? "/contact-us" : "/contact-us-all";
    let contactusRequest = localStorage.getItem("token")
      ? requestForContactUs
      : requestForOpenContactUs;
    apiCAll(contactusRequest, url, authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowLoader(false)
        setSuccessMessage(true);
      }
    });
  };
  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="flex mx-2">
          <Link
            to={{
              pathname: `/home`,
            }}
            className="self-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Contact Us
          </h3>
        </div>
        <div className="bg-white p-4 px-6">
        <h3 className="text-xl font-bold py-4 flex-1">
            Contact Details:
        </h3>
        <p className="flex"><img  className="mr-2"   src={window.location.origin + "/email.png"} width="20px"/> 
         <a
            href="email:Contact@glenindia.com"
            className="text-sm  font-bold text-primary"
          >
            cr@glenindia.com
          </a></p>
          <p className="text-sm font-bold flex items-start mt-2 text-primary">
          <img className="mr-1" width="25px" height="25px" src={window.location.origin + "/address.png"}/> 
            Address- 34, Phase, I, 1, DLF Industrial Area,<br/>Faridabad, Haryana 121003
          </p>

          <h3 className="text-xl font-bold py-4 flex-1">
            For any query please fill the below form.
          </h3>

          {!successMessage ? (
            <div className="form">
              <div className="order-id flex flex-col">
                <label>Name*</label>
                <input
                  placeholder="Enter your name"
                  value={detail.name}
                  onChange={handleChange}
                  name="name"
                  className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                />
              </div>
              <div className="order-id flex flex-col">
                <label>Email*</label>
                <input
                  placeholder="Enter your email"
                  value={detail.email}
                  onChange={handleChange}
                  name="email"
                  className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                />
              </div>
              <div className="order-id flex flex-col">
                <label>Phone*</label>
                <input
                  placeholder="Enter your phone"
                  name="phone"
                  value={localStorage.getItem("phone") || ""}
                  onChange={handleChange}
                  className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                />
              </div>

              <div className="order-id flex flex-col">
                <label>Write your Query*</label>
                <textarea
                  name="query"
                  onChange={handleChange}
                  className="my-3 w-full  p-4 bg-white rounded-md  shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                ></textarea>
              </div>
              <button
                disabled={Object.values(detail).includes("")}
                className={` ${
                  true ? "disabled:opacity-50" : ""
                } w-full my-5 rounded-full bg-primary text-md h-12 text-white cursor-pointer`}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          ) : (
            <>
              <div className="text-center">
                <p className="text-primary">Thanks For Contacting us</p>
                <p>Someone Assist you shortly</p>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
