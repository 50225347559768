import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";

interface OrderPageProps {
  data: [];
}

const Order = (props, { data }: OrderPageProps): JSX.Element => {
  let history = useHistory();

  const authToken = localStorage.getItem("token") || "";
  const [orderDetail, setOrderDetail] = useState({});
  const [orderItems, setorderItems] = useState(data);
  const [returnItems, setReturnItems] = useState([]);
  const [processedItems, setProcessedItems] = useState([])
  const [pendingItems, setPendingItems] = useState([])
  const [shippingDetails, setShippingDetails] = useState([])

  const orderId = () => {
    let obj = {
      order_id: props?.match?.params?.id || props?.location?.state?.orderId,
    };

    apiCAll(obj, "/order-detail", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setReturnItems(x?.data?.Response?.returnItem);
        setOrderDetail(x?.data?.Response);
        setorderItems(x?.data?.Response?.items?.filter((x) => x.quantity != 0));
        setProcessedItems(x?.data?.Response?.processed_items?.filter((x) => x.quantity != 0));
        setPendingItems(x?.data?.Response?.pending_items?.filter((x) => x.quantity != 0));
        setShippingDetails(x?.data?.Response?.shipping_details)
      }
    });
  };

  useEffect(() => {
    orderId();
  }, []);

  const handleBack = () => {
    history.push("/home/complete-order");
  };

  return (
    <>
      <div className="return-refund">
        <div className="flex mx-2">
          <button className="self-center" onClick={handleBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Order Details
          </h3>
        </div>

        <div className="wrapper bg-white p-4 px-6 text-sm md:text-md">
          <p>
            <span>Order Id: {orderDetail["order_number"]}</span>{" "}
            <span className="float-right text-blue-600 cursor-pointer">
              <a href={orderDetail["download_invoice"]} target="_blank">
                Download Invoice
              </a>
            </span>
          </p>
          <p>
            <span>Order Date: {orderDetail["order_date"]}</span>
          </p>
          <p>
            <span>Order Amount: &#8377; {orderDetail["total"]}</span>
          </p>
          <p>
            <span>Order Status: {orderDetail["order_status"]}</span>
          </p>
          {orderDetail["cancel_reason"] ? (
          <p>
            <span>Cancellation Remark: {orderDetail["cancel_reason"]}</span>
          </p>
          ) : null}
          {orderDetail["cancel_reason"]!=orderDetail["rejected_remark"] ? (
          <p>
            <span>Rejection Remark: {orderDetail["rejected_remark"]}</span>
          </p>
          ) : null}

          {orderDetail["order_status"] === "Shipped" ? <>
            <p>
              <span>Docket No: {shippingDetails["docket_no"]}</span>
            </p>
            <p>
              <span>Delivery By: {shippingDetails["delivery_by"]}</span>
            </p>
            <p>
              <span>Packing Box Qty.: {shippingDetails["packing_box_count"]}</span>
            </p>
            <p>
              <span>Weight: {shippingDetails["weight"]}</span>
            </p>
          </> : null}

          {orderItems &&
            orderItems.map((item: any, index) => (
              <div className="flex justify-between border my-2 p-2">
                <div className="w-2/5 self-center">
                  <p>
                    Name: <b>{item?.product_name}</b>
                  </p>
                  <p>
                    Quantity: <b>{item?.quantity}</b>
                  </p>
                  <p>
                    Price: <b>{item?.offer_price}</b>
                  </p>
                </div>
                <div className="flex justify-end">
                  <img
                    src={item?.product_image}
                    alt={item?.product_name}
                    className="w-20 md:w-36"
                  />

                  {/* {props?.location?.state?.return === true ? (
                    <button>
                      <Link
                        to={{
                          pathname: `/return/${orderDetail["order_number"]}/${item.id}`,
                          state: {
                            itemId: `${item.id}`,
                            orderId: `${orderDetail["order_number"]}`,
                          },
                        }}
                        className="w-20 md:w-36 ml-6 flex justify-center items-center rounded-full bg-primary text-sm md:text-md h-10 md:h-12 text-white"
                      >
                        Return
                      </Link>
                    </button>
                  ) : null} */}
                </div>
              </div>
            ))}
          {returnItems.length >= 1 ? <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Return Placed
          </h3> : null}
          {returnItems &&
            returnItems.map((item: any, index) => (
              <div className="flex justify-between border my-2 p-2">
                <div className="w-2/5 self-center">
                  <p>
                    Product Name: <b>{item?.product_name}</b>
                  </p>
                  <p>
                    Return Quantity: <b>{item?.return_qty}</b>
                  </p>
                  <p>
                    Price: <b>{item?.discount_price}</b>
                  </p>
                </div>
                <div className="flex justify-end">
                  <img
                    src={item?.product_image}
                    alt={item?.product_name}
                    className="w-20 md:w-36"
                  />
                </div>
              </div>
            ))}

            <div className="processed_items_list">
            {processedItems?
            <h3 className="text-2xl font-bold py-4 flex-1">
              Processed Items
            </h3>
            :null}
            
            {processedItems &&
              processedItems.map((item: any, index) => (
                <div className="flex justify-between border my-2 p-2">
                  <div className="w-2/5 self-center">
                    <p>
                      Name: <b>{item?.product_name}</b>
                    </p>
                    <p>
                      Quantity: <b>{item?.quantity}</b>
                    </p>
                    <p>
                      Price: <b>{item?.offer_price}</b>
                    </p>
                    {item?.remark?
                      <p>
                        Remark: <b>{item?.remark}</b>
                      </p>
                    :""
                    }
                    
                  </div>
                  <div className="flex justify-end">
                    <img
                      src={item?.product_image}
                      alt={item?.product_name}
                      className="w-20 md:w-36"
                    />

                    {props?.location?.state?.return === true ? (
                      <button>
                        <Link
                          to={{
                            pathname: `/return/${orderDetail["order_number"]}/${item.id}`,
                            state: {
                              itemId: `${item.id}`,
                              orderId: `${orderDetail["order_number"]}`,
                            },
                          }}
                          className="cursor-pointer w-20 md:w-36 ml-6 flex justify-center items-center rounded-full bg-primary text-sm md:text-md h-10 md:h-12 text-white"
                        >
                          Return
                        </Link>
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
            {pendingItems?
            <h3 className="text-2xl font-bold py-4 flex-1">
              Pending Items
            </h3>
            :null}
            {pendingItems &&
              pendingItems.map((item: any, index) => (
                <div className="flex justify-between border my-2 p-2">
                  <div className="w-2/5 self-center">
                    <p>
                      Name: <b>{item?.product_name}</b>
                    </p>
                    <p>
                      Quantity: <b>{item?.quantity}</b>
                    </p>
                    <p>
                      Price: <b>{item?.offer_price}</b>
                    </p>
                    {item?.remark?
                      <p>
                        Remark: <b>{item?.remark}</b>
                      </p>
                    :""
                    }
                    
                  </div>
                  <div className="flex justify-end">
                    <img
                      src={item?.product_image}
                      alt={item?.product_name}
                      className="w-20 md:w-36"
                    />

                    {props?.location?.state?.return === true ? (
                      <button>
                        <Link
                          to={{
                            pathname: `/return/${orderDetail["order_number"]}/${item.id}`,
                            state: {
                              itemId: `${item.id}`,
                              orderId: `${orderDetail["order_number"]}`,
                            },
                          }}
                          className="cursor-pointer w-20 md:w-36 ml-6 flex justify-center items-center rounded-full bg-primary text-sm md:text-md h-10 md:h-12 text-white"
                        >
                          Return
                        </Link>
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}




          </div>


          <footer className="text-center flex justify-around mt-20">
            <button
              onClick={handleBack}
              className="w-1/4 flex justify-center items-center rounded-full bg-primary text-sm md:text-md h-10 md:h-12 text-white"
            >
              BACK
            </button>

            {/* <button className="w-1/4 rounded-full bg-primary text-sm md:text-md h-10 md:h-12 text-white">CANCEL</button> */}
          </footer>
          <p className="footer opacity-75 mt-5 text-center">
            Copyright© 2022 <b>GlenService</b>. Designed By{" "}
            <b><a href="https://techstreet.in">TechStreet.in</a></b>
          </p>
        </div>
      </div>
    </>
  );
};

export default Order;
