import { useHistory } from "react-router";

const Search = () => {
    const history = useHistory();
    const openSearchPage = () => {
        history.push("/search");
    }
    return (
        <>
            <div className="search flex mt-5">
                <input className="bg-white w-full rounded-l-full h-10 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4" placeholder="Start Typing the Spares you need...
" onClick={openSearchPage} />
                <span className="bg-primary text-white rounded-r-full w-20 shadow-primary cursor-pointer h-10 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-10" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </span>
            </div>
        </>
    )
}
export default Search;