import { Loader } from "../shared-components";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { apiCAll } from "../api/api";

const GsmaReturnItem = (): JSX.Element => {
  const location = useLocation();
  const item: any = location.state;
  const authToken = localStorage.getItem("token") || "";
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [GSMAReturnQty, setGSMAReturnQty] = useState(0);
  const [cartUpdated, setCartUpdated] = useState(false);

  const [tempData, setTempData] = useState([]) as any;

  const handleBack = () => {
    history.push("/home/GSMA-return");
  };

  const createRetunData = () => {
    closeModal();
    setTempData([]);
    let newData: any = [];

    for (let i = 0; i < GSMAReturnQty; i++) {
      newData.push({
        id: Math.round(Math.random() * 100),
        partName: item.product_name,
        partCode: "CP001",
        quantity: 1,
        GSMAcode: "",
      });
    }

    setTempData(newData);
  };

  const handleGSMAcode = (val: any, index) => {
    const updatedTempData = [...tempData];
    const selectedObj = updatedTempData[index];
    selectedObj.GSMAcode = val;
    setTempData(updatedTempData);
  };

  const handlePopup = () => {
    if (!item) {
      setShowPopup(true);
    } else {
      const GSMAcodeIsAvailable = tempData.some((val) => {
        return val.GSMAcode !== "";
      });
      if (tempData.length === 0) {
        createRetunData();
      } else {
        if (GSMAcodeIsAvailable === true) {
          setShowPopup(true);
        } else if (GSMAcodeIsAvailable === false && tempData.length > 0) {
          createRetunData();
        } else {
          setShowPopup(false);
        }
      }
    }
  };

  const handleGsmaReturn = async () => {
    const data: any = [];

    for (let i = 0; i < GSMAReturnQty; i++) {
      data.push({
        product_id: item.product_id,
        gsma_number: tempData[i].GSMAcode,
      });
    }

    const obj = {
      gsma_product: [...data],
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/gsma-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(true);
        });
    });

    if (myPromise === "success") {
      setTimeout(() => {
        setCartUpdated(true);
      }, 1200);
    }
  };

  const closeModal = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="annual-return">
        <div className="flex mx-2">
          <button
            className="self-center"
            onClick={handleBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Warranty Defective Retrun Items
          </h3>
        </div>

        <div className="bg-white h-screen">
          <div className="pt-10 flex justify-center">
            <p className="m-0 mt-2">
              How many quantity would you like to return ? :{" "}
            </p>
            <div className="input_field border h-8 rounded ml-3 flex justify-around mt-1">
              <span
                className="cursor-pointer self-center ml-2"
                onClick={() => {
                  setGSMAReturnQty(
                    GSMAReturnQty === 0 ? GSMAReturnQty : GSMAReturnQty - 1
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20 12H4"
                  />
                </svg>
              </span>
              <input
                type="number"
                className="px-1 mt-2 ml-5 w-11 mb-1 text-md Gsma_User_input"
                value={GSMAReturnQty}
                onChange={(e) => {
                  setGSMAReturnQty(Number(e.target.value));
                }}
              />
              <span
                className="cursor-pointer self-center mr-2"
                onClick={() => {
                  setGSMAReturnQty(GSMAReturnQty + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </span>
            </div>
            <div className="">
              <button
                type="button"
                className="rounded-full bg-primary text-md h-11 text-white px-8 cursor-pointer flex items-center ml-20"
                onClick={() => {
                  handlePopup();
                }}
              >
                Submit
              </button>

              {showPopup === true ? (
                <div className="search-popup">
                  <div className="modal border rounded">
                    <div className="border-b px-3 py-1 font-bold text-base border-black flex justify-between items-center">
                      Warning{" "}
                      <button
                        className="close cursor-pointer flex justify-end text-3xl"
                        onClick={closeModal}
                      >
                        &times;
                      </button>
                    </div>
                    <div className="content mx-2 my-2">
                      <p className="m-0 py-10 text-center">
                        {!item
                          ? "Please first select a part from Warranty defective return."
                          : "If you click on Agree your previous data will be deleted."}
                      </p>
                    </div>
                    <div className="flex justify-end pb-3">
                      {item ? (
                        <button
                          type="button"
                          onClick={() => {
                            createRetunData();
                          }}
                          className="rounded-full bg-primary text-md h-8 text-white px-4 cursor-pointer flex items-center mr-2"
                        >
                          Agree
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={
                          !item
                            ? () => {
                                history.push("/home/Gsma-return");
                                closeModal();
                              }
                            : closeModal
                        }
                        className="rounded-full bg-primary text-md h-8 text-white px-4 cursor-pointer flex items-center mr-3"
                      >
                        {!item ? "Ok" : "Cancel"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="py-10 flex justify-center">
            <table className="shadow-primary bg-white w-8/12">
              <tr>
                <th className="border text-left px-4 py-2 text-sm">Sr.No.</th>

                <th className="border text-left px-4 py-2 text-sm">
                  Part Name
                </th>

                <th className="border text-left px-4 py-2 text-sm">
                  Part Code
                </th>

                <th className="border text-left px-4 py-2 text-sm">Quantity</th>

                <th className="border text-left px-4 py-2 text-sm">
                  {" "}
                  Enter GSMA Service Booking No.
                </th>
              </tr>
              {item ? (
                tempData.map((val, index) => {
                  return (
                    <tr
                      className="tabe-row"
                      key={index}
                    >
                      <td className="border px-4 py-2 text-sm ">
                        {index + 1}{" "}
                      </td>

                      <td className="border px-4 py-2 text-sm hyperlink cursor-pointer">
                        {val.partName}
                      </td>

                      <td className="border px-4 py-2 text-sm ">
                        {val.partCode}
                      </td>

                      <td className="border px-4 py-2 text-sm ">
                        {val.quantity}
                      </td>

                      <td className="border px-4 py-2 text-sm ">
                        <input
                          type="text"
                          className="Gsma_input bg-transparent w-full border-bottom"
                          value={val.GSMAcode}
                          onChange={(e) => {
                            handleGSMAcode(e.target.value, index);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="my-4 bg-white text-center">
                  No Data Available
                </div>
              )}
            </table>
          </div>
          <div className="mx-auto pb-10 w-8/12">
            {tempData.length ? (
              <button
                type="button"
                className="rounded-full bg-primary text-md h-11 text-white px-8 cursor-pointer flex items-center ml-auto mr-5"
                onClick={handleGsmaReturn}
              >
                Add to return
              </button>
            ) : null}
            <button
              type="button"
              className="rounded-full bg-primary text-md h-11 text-white px-8 mt-3 cursor-pointer flex items-center ml-auto mr-1"
              onClick={() => {
                history.push(`/GsmaViewReturnCart`);
                // /home/Gsma-return/GsmaReturnItem/GsmaViewReturnCart
              }}
            >
              View return cart
            </button>
          </div>
          {cartUpdated ? (
            <div
              className="green-alert text-center w-full bottom-16 fixed px-4 py-3 rounded  animate__animated animate__fadeOut animate__delay-1s"
              role="alert"
            >
              <strong>Cart updated successfully!</strong>
            </div>
          ) : null}
        </div>
      </div>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245, 0.2);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .Gsma_input:focus {
            outline: none !important;
          }

          .Gsma_User_input {
            outline: none !important;
          }

          .Gsma_input {
            border-bottom: 1px solid grey;
          }

          .search-popup {
            background-color: rgba(0, 0, 0, 0.2);
            width: 98.9vw;
            height: 120%;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }

          .modal {
            width: 500px;
            height: 210px;
            background: white;
            z-index: 10;
            margin-top: 15%;
            margin-left: 33%;
            border-radius: 10px;
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }

          .green-alert {
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
          }

          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default GsmaReturnItem;
