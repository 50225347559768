/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { useHistory } from "react-router";
import { apiCAll } from "../api/api";
import { Footer, Header, Loader } from "../shared-components";
import Search from "./Search";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Table from "./Table";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { Link } from "react-router-dom";
import { CompletedOrder, NewOrder, TrackOrder } from ".";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
const selectOption = [
  {
    name: "Please Confirm",
    value: "Cash on Delivery",
    img:"/cod.jpeg",
    id: "1",
  },
  // {
  //   name: "Pay Online",
  //   value: "Pay Online",
  //   img:"/pre-paid.jpeg",
  //   id: "2",
  // },
];

const tabsData = [
  {
    name: "New Order",
    id: "NewOrder",
    link: "/home/new-order",
  },
  {
    name: "Track Order",
    id: "TrackOrder",
    link: "/home/track-order",
  },
  {
    name: "Completed Order",
    id: "CompleteOrder",
    link: "/home/complete-order",
  },
];

const completeOrderData = [
  {
    id: 1,
    orderId: "#456770",
    orderDate: "15/06/2021",
    amount: "3456.00",
    currentStatus: "On the way",
    deliveryDate: "20/6/2021",
    more: true,
  },
  {
    id: 1,
    orderId: "#456770",
    orderDate: "15/06/2021",
    amount: "3456.00",
    currentStatus: "On the way",
    deliveryDate: "20/6/2021",
    more: true,
  },
];

const Home = (props): JSX.Element => {
  let history = useHistory();
  const [active, setActive] = useState(props?.location?.state?.active);
  const authToken = localStorage.getItem("token") || "";
  const [cartList, setCartList] = useState([]);
  const [trackOrderList, setTrackOrderList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [profileDetails, setProfileDetails] = useState({});
  const [completeData, setCompleteData] = useState([]);
  const [storeInstrucation, setStoreInstrucation] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [bannerImages, setBannerImages] = useState<any[]>([
    { url: "/solid-bg.jpeg" },
  ]);
  const Razorpay = useRazorpay();

  const CartList = () => {
    apiCAll({}, "/store-setting", authToken)
      .then((x) => {
        setStoreInstrucation(x?.data?.Response?.delivery_instruction);
        let imgs = [] as any;
        {
          x?.data?.Response?.store_banner.map((item: any, index) => {
            window.navigator.userAgent.toLowerCase().includes("mobi")
              ? imgs.push({ url: item["mobile_banner"] })
              : imgs.push({ url: item["desktop_banner"] });
          });
        }
        setBannerImages(imgs);
      })
      .catch((err) => console.log(err));

    apiCAll({}, "/cart", authToken)
      .then((x) => {
        if (x?.data?.ErrorCode === 0) {
          if (x?.data?.Response?.items) {
            setCartList(x?.data?.Response?.items);
            setTotalPrice(x?.data?.Response?.total);
            setShippingPrice(x?.data?.Response?.delivery_fee);
            setSubTotal(x?.data?.Response?.total_price);
          }
        } else {
          setCartList([]);
          setTotalPrice(null);
          setShippingPrice(null);
          setSubTotal(null);
        }
      })
      .catch((err) => console.log(err));
    apiCAll({}, "/profile", authToken)
      .then((x) => {
        localStorage.setItem("user_id", x?.data?.Response?.id);
        setProfileDetails({
          name: x?.data?.Response?.username,
          email: x?.data?.Response?.email,
          profile_image: x?.data?.Response?.profile_image,
        });
      })
      .catch((err) => console.log(err));
    apiCAll({}, "/orders/open", authToken)
      .then((x) => {
        setTrackOrderList(x?.data?.Response);
      })
      .catch((err) => console.log(err));
    apiCAll({}, "/orders/completed", authToken)
      .then((x) => {
        setCompleteData(x?.data?.Response);
      })
      .catch((err) => console.log(err));
  };

  const checkPreviousFeedbackData = () => {
    apiCAll({}, "/enforce-feedback-list", authToken)
      .then((x) => {
        if (x?.data?.Response?.length >= 1) {
          history.push("/enforce-feedback");
        }
      })
      .catch((err) => console.log(err));
  };
 
 useEffect(() => {
   CartList();
    if (!localStorage.getItem("enableSkipForNow")) {
      checkPreviousFeedbackData();
    }
  }, []);

  const updatedCartListCallback = () => {
    CartList();
  };

  const handlePayment = async (close) => {
    let order = await apiCAll({}, "/placeorder", authToken).then((x) => {
      console.log(111111, x)
      return x?.data?.Response?.razorpay_order;
    });

  
    close();

    let options = {
      // key: "rzp_test_MhKrOdDQM8C8PL", // Enter the Key ID generated from the Dashboard
      key: "rzp_live_ZNr6pHIuzNa8mS", // Enter the Key ID generated from the Dashboard
      amount: order?.["amount"],
      currency: order?.["currency"],
      name: order?.["name"],
      description: order["description"],
      image: order["image"],
      order_id: order["id"],
      handler: function (response) {
        apiCAll(response, "/transaction-update", authToken).then((x) => {
          history.push({
            pathname: "/success",

            state: {
              orderid: x?.data?.Response?.glenService_order_id,
              transactionId: x?.data?.Response?.transaction_id,
            },
          });
        });
      },
      prefill: {
        name: profileDetails["name"],
        email: profileDetails["email"],
        contact: localStorage.getItem("phone"),
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          window.location.reload();
        },
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      apiCAll(response, "/transaction-update", authToken);
    });

    rzp1.open();
  };

  useEffect(() => {
    handleTabs();
  });

  const handleTabs = () => {
    if (history.location) {
      if (history.location.pathname.includes("new")) {
        setActive(1);
      } else if (history.location.pathname.includes("track")) {
        setActive(2);
      } else if (history.location.pathname.includes("complete")) {
        setActive(3);
      } else {
        setActive(1);
      }
    }
  };
  const [paymentMode, setPaymentMode] = useState(null);

  const handlePaymentMode = (e) => {
    setPaymentMode(e);
  };

  const updatePaymentMode = (close) => {
    setShowLoader(true)
    if (paymentMode == 1) {
           apiCAll({}, "/placeorder-cod", authToken)
        .then((x) => {
          if (x?.data?.ErrorCode === 0) {
             history.push({
              pathname: "/success",

              state: {
                orderid: x?.data?.Response?.order_id,
                // transactionId: x?.data?.Response?.transaction_id,
              },
            });
          }
        })
        .catch((err) => console.log(err));
    } else if (paymentMode == 2) {
      handlePayment(close);
       } else {
    
      history.push({
        pathname: "/success"
      });
    }
    
  };

  const handleEmptyCart = () => {
    setShowLoader(true);
    apiCAll({}, "/cart-empty", authToken).then((x) => {
      if (x?.data?.ErrorCode == 0) {
       
        window.location.reload();
        setShowLoader(false);
      }
    });
  };

  const handleTrackRefresh =() => {
    apiCAll({}, "/orders/open", authToken)
      .then((x) => {
        setTrackOrderList(x?.data?.Response);
      })
      .catch((err) => console.log(err));
  }
  const handleCompleteRefresh =() => {
    apiCAll({}, "/orders/completed", authToken)
    .then((x) => {
      setCompleteData(x?.data?.Response);
      console.log(1111111, completeData);
    })
    .catch((err) => console.log(err));
  }
  return (
    <>
     {showLoader ? <Loader/> : null}
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="homepage pt-3">
          <Header profileDetails={profileDetails} />
        </div>
        <div className="banner mt-3">
          <div className="rounded relative w-full" >
            {/* <SimpleImageSlider
              width={"100%"}
              height={"40vh"}
              images={bannerImages}
              showBullets={true}
              showNavs={true}
            /> */}
            {bannerImages.length >= 1 ? (
              <Slide>
                {bannerImages.map((slideImage, index) => (
                  <div className="each-slide" key={index}>
                    <img src={slideImage.url} />
                  </div>
                ))}
              </Slide>
            ) : (
              null
            )}
          </div>
          {/* {storeBanner.map((item: any, index) => (
            <img src={item['desktop_banner']?item['desktop_banner']:item['mobile_banner']} alt="home-banner" className="rounded w-full" />
          ))} */}

          {/* <img
            src={window.location.origin + "/home-banner.png"}
            alt="home-banner"
            className="rounded w-full"
          /> */}
        </div>
        {/* 
<div id="carouselExampleIndicators" className="carousel slide relative" data-bs-ride="carousel">
  <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
    <button
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide-to="0"
      className="active"
      aria-current="true"
      aria-label="Slide 1"
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide-to="1"
      aria-label="Slide 2"
    ></button>
    <button
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide-to="2"
      aria-label="Slide 3"
    ></button>
  </div>
  <div className="carousel-inner relative w-full overflow-hidden">
    <div className="carousel-item active float-left w-full">
      <img
        src="https://mdbcdn.b-cdn.net/img/new/slides/041.webp"
        className="block w-full"
        alt="Wild Landscape"
      />
    </div>
    <div className="carousel-item float-left w-full">
      <img
        src="https://mdbcdn.b-cdn.net/img/new/slides/042.webp"
        className="block w-full"
        alt="Camera"
      />
    </div>
    <div className="carousel-item float-left w-full">
      <img
        src="https://mdbcdn.b-cdn.net/img/new/slides/043.webp"
        className="block w-full"
        alt="Exotic Fruits"
      />
    </div>
  </div>
  <button
    className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div> */}

        <div className="home-tabs mt-4">
          <ul className="flex justify-between shadow-secondary text-center rounded-full ">
            {tabsData.map((item, index) => (
              <li
                className={` last: border-color text-xxs sm:text-sm flex-1 hover: cursor-pointer ${
                  active === index + 1 ? "bg-primary text-white " : ""
                }${
                  index === 0
                    ? "first: rounded-l-full"
                    : index + 1 === tabsData.length
                    ? "last: rounded-r-full"
                    : ""
                }`}
                key={index + 1}
                onClick={handleTabs}
              >
                <Link to={item.link} className="p-2 py-3 sm:p-3 block">
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <Search />

        <div className=" content   w-full">
          {active === 1 ? (
            <>
              <div className="flex flex-col">
                {cartList?.length >= 1 ? (
                  <button
                    className="mt-4 w-full justify-end  flex  text-sm  hover:text-gray-600"
                    onClick={handleEmptyCart}
                  >
                    <img src="/empty-cart.png" width="20px" className="mr-1" />
                    Empty Cart
                  </button>
                ) : null}
                <div
                  className={`${
                    cartList.length > 0 ? "overflow-x-scroll" : ""
                  }`}
                >
                  <NewOrder
                    data={cartList}
                    updatedCartList={updatedCartListCallback}
                  />
                </div>
              </div>
            </>
          ) : null}
          {active === 2 ? (
           
            <div
              className={`${
                trackOrderList.length > 0 ? "overflow-x-scroll" : ""
              }`}
            >
              {" "}
              <TrackOrder data={trackOrderList} />
            </div>
         
          ) : null}

          {active === 3 ? (
           
            <div
              className={`${
                completeData.length > 0 ? "overflow-x-scroll" : ""
              }`}
            >
              <CompletedOrder data={completeData} />
            </div>
        
          ) : null}
        </div>
        <div className="note mx-4 mt-3">
          <ul className="list-disc text-xs ">
            <li className="mb-1">{storeInstrucation}</li>
            {/* <li>More than 500 Show Delivery Free</li> */}
          </ul>
        </div>
        <div className="checkout flex mt-4 bg-active rounded shadow-primary  p-3">
          <div className="amount flex-1 self-end">
            <p className="text-lg leading-3 ">
              Total Amount <span className="font-bold text-xl">&#8377; {totalPrice || 0}</span>
            </p>

            <Popup
              trigger={
                <button className="text-primary underline cursor-pointer text-sm">
                  {" "}
                  Price Breakup{" "}
                </button>
              }
              modal
            >
              {(close) => (
                <div className="modal">
                  <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                    Price Breakup{" "}
                    <button
                      className="close cursor-pointer  text-2xl"
                      onClick={close}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="content mx-2 my-2">
                    <div className="mb-3">
                      <p className="my-1 justify-between flex font-medium">
                        Sub Total: <span>&#8377; {subTotal || 0}</span>
                      </p>
                      <p className="my-1 justify-between flex font-medium">
                        Shipping Charges: <span>{shippingPrice || 'Free'}</span>
                      </p>
                      <p className="my-1 justify-between flex font-medium">
                        Total: <span>&#8377; {totalPrice || 0}</span>
                      </p>
                    </div>
                    <button
                      className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full"
                      onClick={close}
                    >
                      CONFIRM
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
          <div className=" w-2/5">
            {/* <button
              className="rounded-full bg-primary text-md h-12 text-white  w-full"
              onClick={handlePayment}
            >
              
            </button> */}
            <Popup
              trigger={
                <button
                  className={` ${
                    totalPrice == null ? "disabled:opacity-50" : ""
                  } rounded-full bg-primary text-md h-12 text-white  w-full`}
                  disabled={totalPrice == null}
                >
                  {" "}
                  Order Now
                </button>
              }
              modal
            >
              {(close) => (
                <div className="modal">
                  {cartList.some(
                    (x) => x?.["avaliable_stock"] < x?.["quantity"]
                  ) ? (
                    <div className="px-2 pb-1  text-sm flex flex-col"> 
                    <button
                      className="close cursor-pointer  text-2xl text-right"
                      onClick={close}
                    >
                      &times;
                    </button>
                    <p className="text-red-500 ">Some Items are Out of Stock in Cart. Please empty cart and add items again.</p></div>
                  ) : (
                    <div>
                      <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                        {/* Select payment mode */}
                        Place order
                        <button
                          className="close cursor-pointer  text-2xl"
                          onClick={close}
                        >
                          &times;
                        </button>
                      </div>

                      <div className="content mx-2 my-2">
                        <div className="mb-3">
                          <div className="choose-option flex justify-around mt-3">
                            {selectOption.map((item, index) => (
                              <>
                                <div className="radio-tile-group">
                                
                                  <div className="input-container">
                               
                                    <input
                                      id={`radioReturn-${index + 1}`}
                                      className="radio-button"
                                      type="radio"
                                      name="radio"
                                      onChange={() =>
                                        handlePaymentMode(item.id)
                                      }
                                      value={item.value}
                                    />
                                    <div className="radio-tile">
                                    <img src={item?.img} alt="img" className="w-14" />
                                      <label
                                        htmlFor={`radioReturn-${index + 1}`}
                                        className="radio-tile-label text-xs"
                                      >
                                        {" "}
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                        <button
                          className={`rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full ${
                            
                           paymentMode == null ? "disabled:opacity-50" : ""
                          }`}
                          onClick={() => updatePaymentMode(close)}
                          // disabled={paymentMode == null}
                        >
                          Place Order
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Popup>
          </div>
        </div>
        <Footer />
      </div>

      <style jsx>
        {`
          .popup-content {
            border-radius: 10px;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .radio-tile-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .input-container {
            position: relative;
            height: 7.5rem;
            width: 8rem;
            margin: 0.5rem;
          }

          .radio-button {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            margin: 0;
            cursor: pointer;
          }

          .radio-tile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border: 2px solid #9c1e60;
            border-radius: 5px;

            transition: transform 300ms ease;
          }

          .radio-tile-label {
            text-align: center;
            margin-top:10px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #9C1E60;
          }

          .radio-button:checked + .radio-tile {
            box-shadow:0px 0px 4px rgba(156, 30, 96, 0.4);
            border: 2px solid #9c1e60;

            transform: scale(1.1, 1.1);
          }
          .radio-button:checked + .radio-tile .radio-tile-label {
           
          }
        `}
      </style>
    </>
  );
};

export default Home;
function createOrder(params: any) {
  throw new Error("Function not implemented.");
}
