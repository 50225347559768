import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";
const selectOption = [
    {
      name: "Home",
      value: "Home",
      id: "Home",
    },
    {
      name: "Office",
      value: "Office",
      id: "Office",
    },
    {
      name: "Others",
      value: "Others",
      id: "Others",
    },
  ];

const EditAddress = (props) => {
    const [address, setAddress] = useState({});
    const authToken = localStorage.getItem("token") || "";

    useEffect(() => {
        apiCAll({address_id: props?.location?.state?.id}, "/address/edit", authToken).then((x) => {
            if (x?.data?.ErrorCode === 0) {
                setAddress(x?.data?.Response[0])
                setReturnType(x?.data?.Response[0].address_type)
            }
        })
    }, [])

    const handleChange = (e) => {
     
        setAddress({ ...address, [e?.target?.name]: e?.target?.value });
    }

    const submitAddressDetails = () => {
        let obj = {
            address_id: props?.location?.state?.id,
            address: address["address"],
            landmark: address["landmark"],
            pincode: address["pincode"],
            name: address["name"],
            mobile_number: address["mobile_number"],
            address_type: returnType,
        }
        apiCAll(obj, "/address/update", authToken).then((x) => {
            if (x?.data?.ErrorCode === 0) {
               alert("Address has been updated successfully")
            }
        })

    }
   
   
    const [returnType, setReturnType] = useState("Home");
     

   
 

  
    return (
        <>
            <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
                <div className="flex mx-2">
                    <Link to="/home/complete-order" className="self-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                        </svg>
                    </Link>
                    <h3 className="text-2xl font-bold text-center py-4 flex-1">
                        Edit Address
                    </h3>
                </div>
                <div className="wrapper bg-white p-4 px-6">
                    <div className="order-id flex flex-col">
                        <label>Address*</label>
                        <input
                            // minLength={3}
                            value={address["address"]}
                            onChange={handleChange}

                            name="address"
                            placeholder="Enter your address here"
                            className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                        />
                        {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                    </div>
                    <div className="order-id flex flex-col">
                        <label>Landmark*</label>
                        <input
                            // minLength={3}
                            value={address["landmark"]}
                            onChange={handleChange}

                            name="landmark"
                            placeholder="Enter your landmark here"
                            className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                        />
                        {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                    </div>
                    <div className="order-id flex flex-col">
                        <label>Pincode*</label>
                        <input
                            // minLength={3}
                            value={address["pincode"]}
                            onChange={handleChange}

                            name="pincode"
                            placeholder="Enter your pincode here"
                            className="my-3 w-full  p-4 bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 focus:outline-none  text-xs"
                        />
                        {/* {error ? (
                                <p className="text-xs mt-2 text-red-600">{error}</p>
                            ) : null} */}
                             <div className="flex flex-col">
                          <div className="return-replacement mt-6 text-center">
                            <h5>Choose your address type</h5>
                            <div className="choose-option flex justify-around mt-3">
                              {selectOption.map((item, index) => (
                                
                                <>
                                  <div className="w-1/2">

                                    <div className="radio">
                                      <input
                                        id={`radioReturn-${index + 1}`}
                                        name="addressType"
                                        type="radio"
                                       
                                        checked={returnType === item.value}
                                        onChange={e => setReturnType(e.currentTarget.value)}
                                        value={item.value}
                                        className="cursor-pointer"
                                      />
                                      
                                      <label
                                        htmlFor={`radioReturn-${index + 1}`}
                                        className="radio-label cursor-pointer"
                                      >
                                        {" "}
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                    </div>
                    <footer className="text-center mt-10">
                        <button
                            className="w-3/6 rounded-full bg-primary text-md h-12 text-white cursor-pointer"
                        //   disabled={!returnType || !returnReason}
                          onClick={submitAddressDetails}
                        >
                            Update
                        </button>
                        <p className="footer opacity-75 mt-5 text-center">
                        Copyright© 2022 <b>Glen Appliances Pvt. Ltd. </b>. Designed By{" "}
                              <b><a href="https://techstreet.in">TechStreet.in</a></b>
                        </p>
                    </footer>
                </div>
            </div>
        </>
    )
}
export default EditAddress;