import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./App.css";

import RouterMain from "./Router";
export const App = (): JSX.Element => {
  const [mobiScreen, setMobileScreen] = useState(false);

  useEffect(() => {
    if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
    }
  });

  return (
    <>
      <>
        {/* <a href="#" target="_blank">
          <img
            src={window.location.origin + "/whatsapp-icon.gif"}
            alt="Loader"
            className={` rounded-3xl w-12 m-auto mb-3 fixed  z-50 hover:shadow-primary hover:cursor-pointer ${
              mobiScreen ? "right-3 bottom-14" : "left-3 top-1/2"
            }
            `}
          />
        </a> */}
      </>
      <RouterMain />
    </>
  );
};

export default App;
