import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { apiCAll } from "../api/api";
import { Loader } from "../shared-components";
import ReactImageMagnify from "react-image-magnify";

interface SearchPageProps {
  data: any;
}

const SearchPage = ({ data }: SearchPageProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState("");
  const [productList, setProductList] = useState(data);
  const [countEditable, setCountEditable] = useState([]);
  const authToken = localStorage.getItem("token") || "";
  const [showFeatureTitle, setShowFeatureTitle] = useState(true);
  const [sorryTemplate, setSorryTemplate] = useState(false);
  const [cart, setCart] = useState(null);
  const [showSearchLoader, setShowSearchLoader] = useState(false);
  const [totalItem, setTotalItem] = useState(null);

  const [counter, setCounter] = useState({});

  const [categoryData, setCategoryData] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [variant, setVariant] = useState([])
  const [productType, setProductType] = useState([])
  const [productId, setProductId] = useState('')

  useEffect(() => {
    let count = {};
    for (var input = 0; input <= productList?.length; input++) {
      count[input] = 0;
    }

    setCounter(count);
  }, [productList]);
  const [showFixedBar, setShowFixedBar] = useState(false);

  useEffect(() => {
    apiCAll({}, "/cart", authToken)
      .then((x) => {
        if (x?.data?.ErrorCode === 0) {
          if (x?.data?.Response?.items) {
            setShowFixedBar(true);
            setCartTotalValue(x?.data?.Response?.total_price);
            let a = x?.data?.Response.items.map((x) => x.quantity);

            setTotalItem(eval(a.join("+")));
          } else {
            setShowFixedBar(false);
          }
        } else {
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const searchInputDownHandler = (e) => {
    setShowSearchLoader(true);

    const { value } = e.target;

    setProductList([]);

    setSearchValue(value);
    setShowFeatureTitle(false);
    // setSorryTemplate(false);
    if (value.length >= 3) {
      apiCAll({ search: searchValue, category_id: productId }, "/products", authToken)
        .then((x) => {
          setShowSearchLoader(false);
          setProductList(x?.data?.ItemResponse);
          setCountEditable(
            x?.data?.ItemResponse.map((X: any) => {
              return X.quantity;
            })
          );
          if (x?.data?.ItemResponse.length == 0) {
            setSorryTemplate(true);
            setShowSearchLoader(false);
          } else {
            setSorryTemplate(false);
            setShowSearchLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (value.length <= 2) {
      setProductList([]);
      setCart(null);
      setShowSearchLoader(false);
    }
    if (value.length === 0) {
      if(productId)
      {        
        setShowLoader(true);
        getProductListByCategory(productId);      
        setShowLoader(false);
      }
      else{
        /*
        // featured products
  
        setShowFeatureTitle(true);
        setShowLoader(true);
        apiCAll({}, "/featured-product", authToken).then((x) => {
          if (x?.data?.ErrorCode === 0) {
            setProductList(x?.data.ItemResponse.data);
            setProductData(x?.data.ItemResponse);
            setPageNo(x?.data.ItemResponse.next_page_url);
            setShowSearchLoader(false);
            setShowLoader(false);
          }
        });
        */
      }

    }
  };
  const getProductListByCategory_1 = (id: any) => {
    const obj = {
      category_id: id
    }

    setShowLoader(true);
    apiCAll(obj, "/category-productlist", authToken).then(async (x) => {
      setShowLoader(false);
      let response = await x
      if (response?.data?.ErrorCode === 0) {
        setProductList(x?.data.ItemResponse.category_products);
        // setProductData(x?.data.ItemResponse);
        // setPageNo(x?.data.ItemResponse.next_page_url);

      }
    });
  }


  const addToCart = (product, index) => {
    setCart(null);



    if (product?.current_stock === product?.quantity + counter[index]) {
      setCounter({ ...counter, [index]: counter[index] });
    } else {
      setCounter({ ...counter, [index]: counter[index] + 1 });
    }

    // setCounter(counter+1)
    // let obj = {
    //   offer_price: parseFloat(product.discount_price),
    //   rate: parseFloat(product.mrp),
    //   quantity: product.quantity + 1,
    //   product_id: product.product_id,
    // };
    // let searchData = {
    //   search: searchValue,
    //   is_featured: product["is_featured"],
    // };
    // apiCAll(obj, "/cart-add", authToken).then((x) => {
    //   if (x?.data?.ErrorCode === 0) {
    //     apiCAll(searchData, "/products", authToken)
    //       .then((x) => {
    //         setProductList(x?.data?.ItemResponse);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // });
  };

  const decrement = (product, id) => {
    setCart(null);


    if (product?.quantity + counter[id] === 0) {
      setCounter({ ...counter, [id]: counter[id] });

    } else {
      setCounter({ ...counter, [id]: counter[id] - 1 });

    }

    // let obj = {
    //   offer_price: parseFloat(product.discount_price),
    //   rate: parseFloat(product.mrp),
    //   quantity: product.quantity - 1,
    //   product_id: product.product_id,
    // };
    // let searchData = {
    //   search: searchValue,
    //   is_featured: product["is_featured"],
    // };
    // apiCAll(obj, "/cart-add", authToken).then((x) => {
    //   if (x?.data?.ErrorCode === 0) {
    //     apiCAll(searchData, "/products", authToken)
    //       .then((x) => {
    //         setProductList(x?.data?.ItemResponse);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // });
  };

  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorColor, setErrorColor] = useState(null);
  const [cartTotalValue, setCartTotalValue] = useState(null);

  const updateEditCount = (product, close) => {
    let obj = {
      offer_price: parseFloat(product.discount_price),
      rate: parseFloat(product.mrp),
      quantity: inputValue,
      product_id: product.product_id,
    };
    apiCAll(obj, "/cart-add", authToken).then((x) => {
      setErrorColor(x?.data?.ErrorCode);
      if (x?.data?.ErrorCode === 0) {
        setErrorMessage("Quantity added successfully");
        apiCAll({}, "/cart", authToken)
        .then((x) => {
          if (x?.data?.ErrorCode === 0) {
            if (x?.data?.Response?.items) {
              setShowFixedBar(true);
              setCartTotalValue(x?.data?.Response?.total_price);
              let a = x?.data?.Response.items.map((x) => x.quantity);
  
              setTotalItem(eval(a.join("+")));
            } else {
              setShowFixedBar(false);
            }
          } else {
          }
        })
        .catch((err) => console.log(err));

        getProductListByCategory_1(productId);    
        close();
        setErrorMessage("");
        setInputValue("");
    /*
        let searchData = {
          search: searchValue,
          is_featured: product["is_featured"],
        };
        apiCAll(searchData, "/products", authToken)
          .then((x) => {
    
            setProductList(x?.data?.ItemResponse);
            close();
            // setProductList(x?.data.ItemResponse.data);
            // setProductData(x?.data.ItemResponse);
            // setPageNo(x?.data.ItemResponse.next_page_url);
            setErrorMessage("");
            setInputValue("");
          })
          .catch((err) => {
            console.log(err);
          }); */
      } else if (x?.data?.ErrorCode === -117) {
        setErrorMessage(x?.data?.ErrorMessage);
      }
    });
  };

  useEffect(() => {
    setShowLoader(true);
    apiCAll({}, "/featured-product", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setProductList(x?.data.ItemResponse.data);
        setProductData(x?.data.ItemResponse);
        setPageNo(x?.data.ItemResponse.next_page_url);
      }
      setShowLoader(false);
    });
  }, []);

  const [storeInstruction, setStoreInstrucation] = useState("");

  useEffect(() => {
    apiCAll({}, "/store-setting", authToken)
      .then((x) => {
        if (window.navigator.userAgent.toLowerCase().includes("mobi")) {
          setStoreInstrucation(x?.data?.Response?.search_instruction_mob);
        } else {
          setStoreInstrucation(x?.data?.Response?.search_instruction_full);
        }

        // setBannerImages(imgs);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddToCart = (product, index, id) => {
    let obj = {
      offer_price: parseFloat(product.discount_price),
      rate: parseFloat(product.mrp),
      quantity: product?.quantity + counter[index],
      product_id: product.product_id,
    };

    if (product?.quantity + counter[index] >= 1) {
      apiCAll(obj, "/cart-add", authToken).then((x) => {
        if (x?.data?.ErrorCode === 0) {
          if (id === product?.product_id) {
            setCart(index);
            setCartUpdated(true);
            apiCAll({}, "/cart", authToken)
            .then((x) => {
              if (x?.data?.ErrorCode === 0) {
                if (x?.data?.Response?.items) {
                  setShowFixedBar(true);
                  setCartTotalValue(x?.data?.Response?.total_price);
                  let a = x?.data?.Response.items.map((x) => x.quantity);
      
                  setTotalItem(eval(a.join("+")));
                } else {
                  setShowFixedBar(false);
                }
              } else {
              }
            })
            .catch((err) => console.log(err));
          }
        }
      });
    }
  };
  const [cartUpdated, setCartUpdated] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [productData, setProductData] = useState([]);
  const [pageNo, setPageNo] = useState("");

  const loadMoreList = () => {
    setShowLoader(true);

    if (pageNo != null) {
      apiCAll({}, `/featured-product?${pageNo.split("?")[1]}`, authToken).then(
        (x) => {
          if (x?.data?.ErrorCode === 0) {
            setProductList([...productList, ...x?.data?.ItemResponse?.data]);
            setPageNo(x?.data?.ItemResponse.next_page_url);
            setShowLoader(false);
          }
        }
      );
    }
  };

  const addToCartSuccessfully = () => {
    setCartUpdated(true);

    setTimeout(() => {
      setCartUpdated(false);
    }, 1200);
  }

  useEffect(() => {
    getAllCategory();
  }, []);

  const getAllCategory = async () => {
    setShowLoader(true);
    apiCAll({}, "/category", authToken).then(async (x) => {
      setShowLoader(false);
      let data = await x
      if (data.status === 200) {
        if (data.data.ErrorCode === 0) {
          setCategoryData(data.data.Response.category)

        }
      }
    });
  }

  const getProductListByCategory = (id: any) => {
    const obj = {
      category_id: id
    }

    setShowLoader(true);
    apiCAll(obj, "/category-productlist", authToken).then(async (x) => {
      setShowLoader(false);
      let response = await x
      if (response?.data?.ErrorCode === 0) {
        setProductList(x?.data.ItemResponse.category_products);
        // setProductData(x?.data.ItemResponse);
        // setPageNo(x?.data.ItemResponse.next_page_url);
        setSubCategory(x?.data.ItemResponse.sub_categories)
        setVariant([])
        setProductType([])
      }
    });
  }

  const subCategoryHandler = (id: any) => {

    const obj = {
      category_id: id
    }
    setShowLoader(true);
    apiCAll(obj, "/category-productlist", authToken).then(async (x) => {
      setShowLoader(false);
      let response = await x
      if (response?.data?.ErrorCode === 0) {
        setProductList(response?.data.ItemResponse.category_products);
        setVariant(response?.data.ItemResponse.sub_categories)
        setProductType([])
      }
    })
  }

  const setFinalProductList = (id: any) => {
    const obj = {
      category_id: id
    }
    setShowLoader(true);
    apiCAll(obj, "/category-productlist", authToken).then(async (x) => {
      setShowLoader(false);
      let response = await x
      if (response?.data?.ErrorCode === 0) {
        setProductList(response?.data.ItemResponse.category_products);
        setProductType([])
      }
    })
  }
  const setFinalProductTypeList = (id: any) => {
    const obj = {
      category_id: id
    }
    setShowLoader(true);
    apiCAll(obj, "/category-productlist", authToken).then(async (x) => {
      setShowLoader(false);
      let response = await x
      if (response?.data?.ErrorCode === 0) {
        setProductList(response?.data.ItemResponse.category_products);
      }
    })
  }
  
  const resetCatData = () => {
    setProductList([])
    setCategoryData([])
    setSubCategory([])
    setVariant([])
    setProductType([])
    getAllCategory()
    setProductId("");
  }

  return (
    <>
      <div className="search-page m-3 sm:m-5">
        {/* <h3 className="text-2xl text-center text-primary">
            <img
              src="GlenService-logo.png"
              alt="GlenService"
              style={{ width: "300px", display: "inline-block" }}
              className="rounded-full w-full h-full"
            />
          </h3> */}
        {storeInstruction ? (
          <img
            className="w-full hidden"
            src={storeInstruction}
            alt="search-page banner"
          />
        ) : null}

        <div className="select_search_page flex flex-wrap flex-lg-unwrap justify-content-center">
          <div className="select_inner md:mr-5 w-full md:w-1/6  bg-white rounded-full px-5 mt-2">
            <select onChange={(e) => { getProductListByCategory(e.target.value); setProductId(e.target.value) }} className="my-3 mt-4 pe-3 placeholder-black placeholder-opacity-40 focus:outline-none  text-s w-full text-center">
              <option value="Product Category">Product Category</option>
              {categoryData ? categoryData.map((val: any, index: number) => {
                return <option key={index} value={val.id}>{val.category_name}</option>

              }) : null}
            </select>
          </div>
          <div className="select_inner md:mr-5 w-full md:w-1/6 bg-white rounded-full px-5 mt-2">
            <select onChange={(e) => { subCategoryHandler(e.target.value); setProductId(e.target.value) }} className="my-3 mt-4 pe-3 placeholder-black placeholder-opacity-40 focus:outline-none  text-s w-full text-center">
              <option value="Product">Product</option>
              {subCategory ? subCategory.map((val: any, index: number) => {
                return <option key={index} value={val.id}>{val.category_name}</option>

              }) : null}
            </select>
          </div>
          <div className="select_inner md:mr-5 w-full md:w-1/6 bg-white rounded-full px-5 mt-2">
            <select onChange={(e) => { setFinalProductList(e.target.value); setProductId(e.target.value) }} className="my-3 mt-4 pe-3 placeholder-black placeholder-opacity-40 focus:outline-none  text-s w-full text-center">
              <option value='Product Variant'>Product Variant</option>
              {variant ? variant.map((val: any, index: number) => {
                return <option key={index} value={val.id}>{val.category_name}</option>

              }) : null}
            </select>
          </div>
          <div className="select_inner md:mr-5 w-full md:w-1/6 bg-white rounded-full px-5 mt-2">
            <select onChange={(e) => { setFinalProductTypeList(e.target.value); setProductId(e.target.value) }} className="my-3 mt-4 pe-3 placeholder-black placeholder-opacity-40 focus:outline-none  text-s w-full text-center">
              <option value='Product Type'>Type</option>
              {productType ? productType.map((val: any, index: number) => {
                return <option key={index} value={val.id}>{val.category_name}</option>

              }) : null}
            </select>
          </div>
          <p className="m-0 text-primary flex items-center flip pt-3 justify-left md:justify-left w-full md:w-auto md:w-1/12 underline cursor-pointer" onClick={() => { resetCatData() }}>Reset</p>
          <Link to="/home" className="flex items-center flip pt-3 justify-right md:justify-end w-full md:w-1/6 go_to_cart">
            <div className="text-xl font-bold">
              Go to Cart
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
            </svg>
          </Link>
        </div>

        <div className="search flex mt-5">
          <input
            autoFocus
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyUp={searchInputDownHandler}
            className="bg-white w-full rounded-l-full h-10 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
            placeholder="Search*"
          />
          <span className="bg-primary shadow-primary text-white rounded-r-full w-20 h-10 cursor-pointer flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </span>
        </div>
        <ul className="search-suggestions relative pb-11">
          {showSearchLoader ? (
            <img
              src={window.location.origin + "/loader.gif"}
              alt="Loader"
              width="80px"
              className="rounded  m-auto left-0 bottom-0 top-0 right-0 z-top"
            />
          ) : null}
          {/* {showFeatureTitle ? (
            <h3 className="mt-4 font-bold">Featured Products</h3>
          ) : null} */}

          {productList && <><div className="m-0 py-5 flex px-5">
            <b className="text-md sm:text-md w-1/5">Image</b>
            <b className="text-md sm:text-md w-1/3 ml-7 pl-3">Spare Name</b>
            <b className="text-md sm:text-md w-1/5">Price</b>
            <b className="text-md sm:text-md w-1/5 ml-5">Quantity</b>
          </div></>
          }

          {productList &&
            productList?.map((product: any, index: number) => (
              <li className="bg-white flex border my-1 py-2 px-1 md:px-4 justify-between">
                <div className="photo w-1/5">
                  <Popup
                    className="image-popup"
                    trigger={
                      <button className="text-primary  cursor-pointer text-sm h-full">
                        {" "}
                        <img
                          src={product["product_image"]}
                          className="hover:shadow-primary w-20 md:w-12"
                          alt="product_image"
                        />
                      </button>
                    }
                    modal
                  >
                    {(close) => (
                      <div className="modal">
                        <div className="pr-2 font-bold text-base border-black flex justify-end items-center">
                          <button
                            className="close cursor-pointer  text-2xl"
                            onClick={close}
                          >
                            &times;
                          </button>
                        </div>
                        <div className="zoomed_product_img">
                          {/* <img
                            src={product["product_image"]}
                            className="m-auto h-full"
                            alt="product_image"
                          /> */}
                          <ReactImageMagnify {...{
                              smallImage: {
                                  alt: 'product_image',
                                  width: 250,
                                  height: 250,
                                  src: product.product_image
                              },
                              largeImage: {
                                  src: product.product_image,
                                  width: 800,
                                  height: 800
                              },
                              isHintEnabled: false,
                              shouldHideHintAfterFirstActivation: false,
                              enlargedImageContainerDimensions:{
                                width: 350,
                                height: 350
                              }
                             
                          }} />
                        </div>
                          <p className="m-0 mt-10">Hover on image to zoom view.</p>
                      </div>
                    )}
                  </Popup>
                </div>
                <div className="flex flex-col self-center w-1/4">
                  <h2 className="text-xs sm:text-sm">
                    <b> {product["product_name"]}</b>
                  </h2>
                  {product["short_description"] ? (
                    <p className="text-xs ">{product["short_description"]}</p>
                  ) : (
                    ""
                  )}
                  {product["sap_code"] ? (
                    <p className="text-xs "><b>Part Number:</b> {product["sap_code"]}</p>
                  ) : (
                    ""
                  )}
                  <p className="text-xs flex items-center sm:text-sm">
                    {product["expiry_date"] ? (
                      <span>
                        <b>Exp: {product["expiry_date"]}</b>
                      </span>
                    ) : (
                      ""
                    )}

                    {/* <img
                      src={window.location.origin + "/expire-icon.jpg"}
                      alt="expiry date"
                      title="Expiry Date"
                      className="rounded w-12 mr-2 md:w-6"
                    /> */}
                  </p>
                </div>
                <div className="w-1/2  flex justify-between self-center flex-col md:flex-row">
                  <div className="price mb-1 md:mb-0 text-center md:self-center text-lg sm:text-md font-bold w-full md:w-1/4">
                    ₹ {parseInt(product["mrp"])!=parseInt(product["discount_price"])?                    
                    <div className="-mt-2 text-right md:text-center">
                      <span className="line-through mr-2 text-xs text-gray-500">
                        ₹ {product["mrp"]}{" "}
                      </span>
                      <span className=" text-green-600 text-xs">
                        {product["item_discount"]}
                      </span>
                    </div>
                    :product["mrp"]
                    }


                    <div
                      dangerouslySetInnerHTML={{
                        __html: product["group_price"],
                      }}
                      className="text-xxs text-green-600 text-left leading-4 pl-4"
                    />
                  </div>
                  {product["current_stock"] <= 2 &&
                    product["current_stock"] >= 1 ? (
                    <div className="text-red-500 text-center text-xxs sm:text-sm mb-2 self-center">
                      Only {product["current_stock"]} left!
                    </div>
                  ) : null}

                  {product["is_stock"] != 0 ? (
                    <>
                      <div className="md:w-1/2 md:flex justify-between">
                        <div className=" flex justify-end md:justify-center">
                          <div className="counter self-center font-bold text-md border  cursor-pointer  flex justify-center  items-center text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              onClick={() => decrement(product, index)}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M20 12H4"
                              />
                            </svg>
                          </div>
                          <div className="self-center mx-7 md:mx-10 text-xs sm:text-sm font-bold">
                            <Popup
                              className="search-popup"
                              trigger={
                                <button className="text-primary  cursor-pointer text-sm">
                                  {" "}
                                  {product?.["quantity"] + counter[index] || 0}
                                </button>
                              }
                              modal
                            >
                              {(close) => (
                                <div className="modal">
                                  <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                    Enter the Quantity here{" "}
                                    <button
                                      className="close cursor-pointer  text-2xl"
                                      onClick={close}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="content mx-2 my-2">
                                    <div className="mb-3">
                                      <input
                                        min="0"
                                        type="number"
                                        className="phone bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                        placeholder="Quantity"
                                        value={inputValue}
                                        onChange={(e) =>
                                          setInputValue(e?.target?.value)
                                        }
                                      />
                                      {errorMessage ? (
                                        <p
                                          className={`${errorColor === 0
                                            ? "text-green-500"
                                            : "text-red-500"
                                            } text-xs`}
                                        >
                                          {errorMessage}
                                        </p>
                                      ) : null}
                                    </div>

                                    <button
                                      className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full"
                                      onClick={() =>
                                        updateEditCount(product, close)
                                      }
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>

                          <div className="counter self-center font-bold text-md border  cursor-pointer  flex justify-center  items-center text-center h-6 w-6 ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              viewBox="0 0 20 20"
                              fill="black"
                              onClick={() => addToCart(product, index)}
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="justify-end md:justify-center flex">
                          {" "}
                          <button
                            onClick={() =>
                              handleAddToCart(
                                product,
                                index,
                                product?.["product_id"]
                              )
                            }
                            className=" w-4/4 px-3 rounded-sm items-center justify-center bg-primary text-xs h-10 mt-1 mb-1 text-white flex hover:shadow-secondary"
                          >
                            <img
                              src="./cart.png"
                              alt="img"
                              width="22px"
                              className="mr-2"
                            />
                            {cart === index ? (
                              <div className="self-center text-xxs sm:text-sm font-bold" onClick={addToCartSuccessfully}>
                                Add to cart
                              </div>
                            ) : (
                              <div className="self-center text-xxs sm:text-sm font-bold" onClick={addToCartSuccessfully}>
                                Add to cart
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="counter md:self-center font-bold text-sm md:text-md  text-center text-red-600 md:w-1/3 ">
                      Out of Stock
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
        {sorryTemplate ? (
          <div className="text-center mt-10">
            <h3 className="text-sm lg:text-md  font-bold">
              Sorry, no results found!
            </h3>
            <p>Please check the spelling or try searching for something else</p>
            <Link
              to="/request-product"
              className="block py-3 m-auto rounded-full mt-5 bg-primary text-md h-12 text-white md:w-1/3"
            >
              Request Product
            </Link>
          </div>
        ) : null}
        {showLoader ? <Loader /> : null}
        {productList?.length > 1 ? (
          <div>
            {pageNo != null ? (
              <div className={`${showFixedBar ? "mb-24 " : ""} text-center `}>
                <button
                  className="bg-primary rounded-sm text-white px-12 py-3 text-sm"
                  onClick={loadMoreList}
                >
                  Load More
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      {cartUpdated ? (
        <div
          className="green-alert text-center w-full bottom-16 fixed px-4 py-3 rounded  animate__animated animate__fadeOut animate__delay-1s"
          role="alert"
        >
          <strong>Cart updated successfully!</strong>
        </div>
      ) : null}
      {showFixedBar ? (
        <div className="fixed-bottom-div bg-primary fixed bottom-0 w-full text-white rounded-sm">
          <div className="flex justify-between p-2">
            <div className="left text-sm pl-12">
              <p>{totalItem} Item</p>
              <p>&#8377; {cartTotalValue || null}</p>
            </div>
            <div className="right self-center text-md flex items-center pr-12">
              <Link to="/home" className="flex">
                {" "}
                Next{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      <style jsx>
        {`
          .green-alert {
            color: #155724;

            background-color: #d4edda;
            border-color: #c3e6cb;
          }
          .search-popup-content {
            width: 40%;
          }
          @media screen and (max-width: 640px) {
            .search-popup-content {
              width: 80%;
            }
          }
          .image-popup-content {
            height: 55%;
            
          }
          @media screen and (max-width: 640px) {
            .image-popup-content {
              width: 80%;
            }
          }

          .zoomed_product_img{
            // width:500px;
            // height:auto;
            display: flex;
            // justify-content: center;           
          }
          
        `}
      </style>
    </>
  );
};

export default SearchPage;
