import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { useEffect, useState } from "react";
import { apiCAll } from "../api/api";

const Rating = (params): JSX.Element => {
  const authToken = localStorage.getItem("token") || "";
  const [ratingAnswer, setRatingAnswer] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  let order_id = params?.location?.state?.orderId;

  const ratingChanged = (newRating) => {
    setShowPopup(false);
    apiCAll({}, "/rating/list", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        x?.data?.Response.filter((x) => {
          if (x?.id == newRating) {
            setRatingAnswer(x);
          }
        });
      }
    });
    let obj = {
      order_id: order_id,
      emoji_rating: newRating,
    };
    apiCAll(obj, "/userrating/add", authToken).then((x) => {
      if (x?.data?.ErrorCode === 0) {
        setShowPopup(true);
      }
    });
  };

  return (
    <>
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
        <div className="flex mx-2">
          <Link to="/home/complete-order" className="self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </Link>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">Rating</h3>
        </div>
        <div className="bg-white p-4 px-6 relative">
          <h3 className="text-xl font-bold text-center pt-4 pb-2 flex-1">
            Please rate your experience here.
          </h3>
          <div className="flex justify-center w-full">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={50}
              activeColor="#ffd700"
              classNames="rating-component justify-around flex"
            />
          </div>
          <p className="text-center">{ratingAnswer["title"]}</p>
          {showPopup ? (
            <div className="text-center flex justify-center mt-2">
              <img
                className="animate__animated animate__zoomIn w-1/4"
                src={ratingAnswer["icon"]}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Rating;
