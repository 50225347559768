import { useEffect, useState } from "react";
import { apiCAll } from "../api/api";
import { useHistory } from "react-router"
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { Link, useLocation, useParams } from "react-router-dom";
import { Loader } from "../shared-components"
import { Type } from "typescript";

const AnnualReturnItems = (): JSX.Element => {

    let { id }: any = useParams();
    const history = useHistory()
    const [annualReturnItemsForQty, setAnnualReturnItemsForQty] = useState([]) as any;
    const [annualReturnItems, setAnnualReturnItems] = useState([]) as any;
    const [showLoader, setShowLoader] = useState(false)
    const [cartUpdated, setCartUpdated] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const authToken = localStorage.getItem("token") || "";

    useEffect(() => {
        handleAnnualReturnItems(id)
    }, [])

    const handleAnnualReturnItems = async (id: any) => {
        apiCAll({ product_id: id }, "/item-order-list", authToken)
            .then((x) => {
                if (x?.status === 200) {
                    let createAnnualItemArr = x?.data?.Response.map((v: any) => {
                        return {
                            ...v,
                            returnQuantity: v.quantity
                        }
                    })
                    setAnnualReturnItems(createAnnualItemArr);
                }
                else {
                    setShowLoader(true)
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const increment = (index: number) => {
       
        let copyAnnualReturnItems = [...annualReturnItems]
        
        let findItemByIndex = copyAnnualReturnItems[index];
        
        findItemByIndex.quantity = Number(findItemByIndex.returnQuantity) <= Number(findItemByIndex.quantity) ? Number(findItemByIndex.quantity) : Number(findItemByIndex.quantity) + 1;
        setAnnualReturnItems(copyAnnualReturnItems)
    };

    const decrement = (index: number) => {
        let copyAnnualReturnItems = [...annualReturnItems]
        let findItemByIndex = copyAnnualReturnItems[index];
        findItemByIndex.quantity = Number(findItemByIndex.quantity) > 0 ? Number(findItemByIndex.quantity) - 1 : 0;
        setAnnualReturnItems(copyAnnualReturnItems)
    };

    const handleBack = () => {
        history.push("/home/annual-return");
    };

    const addToReturnCart = async (item: any) => {
        const obj = {
            order_id: item.order_id,
            product_id: id,
            quantity: item.quantity,
        }

        const addToCartSuccessfully = () => {
            setCartUpdated(true);

            setTimeout(() => {
                setCartUpdated(false);
            }, 1200);
        }

        const myPromise = await new Promise<String>((resolve, reject) => {
            apiCAll(obj, '/return-cart-add', authToken)
                .then((x) => {
                    
                    resolve('success')
                })
                .catch((err) => {
                    console.log(err)
                })
        })

        if (myPromise === "success") {
            addToCartSuccessfully()
        }
    }   

    const updateEditCount = (index) => {
        let copyAnnualReturnItems = [...annualReturnItems]
        let findDataByIndex = copyAnnualReturnItems[index]
        if (findDataByIndex.returnQuantity >= inputValue) {
            findDataByIndex.quantity = inputValue;
            setAnnualReturnItems(copyAnnualReturnItems)
            setInputValue('');
        }
    }
 
    return (
        <>
            {showLoader ? <Loader /> : null}
            <div className="annual-return_item">
                <div className="flex mx-2">
                    <button className="self-center" onClick={handleBack}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                        </svg>
                    </button>
                    <h3 className="text-2xl font-bold text-center py-4 flex-1">
                    Ok Spare Return Items
                    </h3>
                </div>
                <div className="bg-white py-10 h-screen">
                    <div className=" flex justify-center">
                        <div className="block">
                            <table className="shadow-primary md:w-4/12">
                                {annualReturnItems.length >= 1 ? (
                                    <thead>
                                        <tr className="flex">
                                            {annualReturnItems ? (
                                                <th className="border text-center px-1 py-2 text-sm md:w-1/12">S.No.</th>
                                            ) : null}

                                            {annualReturnItems ? (
                                                <th className="border text-center px-2 py-2 text-sm md:w-64">Order No.</th>
                                            ) : null}

                                            {annualReturnItems ? (
                                                <th className="border text-center px-2 py-2 text-sm md:w-64">Available Quantity</th>
                                            ) : null}

                                            {annualReturnItems ? (
                                                <th className="border text-center px-2 py-2 text-sm md:w-64">Return Quantity</th>
                                            ) : null}

                                            {annualReturnItems ? (
                                                <th className="border text-center px-2 py-2 text-sm md:w-64">Add to Cart</th>
                                            ) : null}
                                        </tr>
                                    </thead>
                                ) : null}



                                {annualReturnItems.map((item: any, index) => (
                                    <tr className="tabe-row flex" key={index}>
                                        {item.id ? (
                                            <td className="border text-center px-1 py-2 text-sm w-1/12">{index + 1} </td>
                                        ) : null}


                                        {item.order_id ? (
                                            <td className="border px-2 py-2 text-sm w-64 text-center">
                                                <Link className="hyperlink" to={`/annual-return/${id}`}>
                                                    {item.order_id}
                                                </Link>
                                            </td>
                                        ) : null}

                                        {item.order_id ? (
                                            <td className="border px-2 py-2 text-sm w-64 text-center">
                                                {item.returnQuantity}
                                            </td>
                                        ) : null}

                                        {(
                                            <td className="border-x-1 sm: border text-center  px-2 py-2 text-sm pt-4 w-64">

                                                <div className="text-center flex justify-around">
                                                    <span
                                                        className="cursor-pointer self-center"
                                                        onClick={() => decrement(index)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-4 w-4 cursor-pointer"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="M20 12H4"
                                                            />
                                                        </svg>
                                                    </span>
                                                    {/* {item.quantity} */}
                                                    <Popup
                                                className="search-popup"
                                                trigger={
                                                    <button className="text-primary  cursor-pointer text-sm">
                                                        {item.quantity}
                                                    </button>
                                                }
                                                modal
                                            >
                                                {(close) => (
                                                    <div className="modal">
                                                        <div className="border-b mx-2 py-1 font-bold text-base border-black flex justify-between items-center">
                                                            Enter the Quantity here{" "}
                                                            <button
                                                                className="close cursor-pointer  text-2xl"
                                                                onClick={close}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                        <div className="content mx-2 my-2">
                                                            <div className="mb-3">
                                                                <input
                                                                    min="0"
                                                                    type="number"
                                                                    className="phone bg-white rounded-full h-10 mb-2 mt-4 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                                                                    placeholder="Quantity"
                                                                    value={inputValue}
                                                                    onChange={(e) =>
                                                                        setInputValue(e?.target?.value)
                                                                    }
                                                                />
                                                            </div>

                                                            <button
                                                                className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-full"
                                                                onClick={() => {
                                                                    if (inputValue) {
                                                                        updateEditCount(index);
                                                                    }
                                                                    close();
                                                                    setInputValue('');
                                                                }

                                                                }
                                                            >
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                                    <span
                                                        className="cursor-pointer self-center"
                                                        onClick={() => {
                                                            increment(index);
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-4 w-4 cursor-pointer"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="M12 4v16m8-8H4"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </td>
                                        )}
                                        <td className="border px-2 py-2 text-sm w-64 text-center">
                                            <button type="button"
                                                className="rounded-full bg-primary text-md h-8 px-6 text-white cursor-pointer"
                                                onClick={() => { addToReturnCart(item) }}>
                                                Add to return
                                            </button>
                                        </td>

                                    </tr>
                                ))}

                            </table>

                            <div className="flex justify-end">
                                <button type="button" className="mt-4 rounded-full bg-primary text-md h-12 text-white px-8 cursor-pointer flex items-center" onClick={() => { history.push(`/home/annual-return/${id}/view-return`) }}>
                                <img
                                src="./cart.png"
                                alt="img"
                                width="27px"
                                className="mr-2"
                                />
                                    View Return Cart
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {cartUpdated ? (
                    <div
                        className="green-alert text-center w-full bottom-16 fixed px-4 py-3 rounded  animate__animated animate__fadeOut animate__delay-1s"
                        role="alert"
                    >
                        <strong>Cart updated successfully!</strong>
                    </div>
                ) : null}
            </div>
            <style jsx>
                {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245 , 0.20);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
          .return_msg_box{
            outline:none !important;
            resize:none;
          }

          .green-alert {
            color: #155724;

            background-color: #d4edda;
            border-color: #c3e6cb;
          }
          
        `}
            </style>
        </>
    );
};

export default AnnualReturnItems;
