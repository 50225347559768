import { Footer, Header } from "../shared-components";

const AboutUS = (): JSX.Element => {
  return (
    <>
      <div className="px-4  lg:w-1/2 my-0 md:mx-auto mt-20">
        <h1 className="font-bold text-2xl mb-10">
          About <span className="text-primary">Us</span>
        </h1>
        <p>
          GlenService’s vision is to create India’s most reliable and frictionless marketplace
          ecosystem that creates life-changing experiences for buyers and
          sellers.
        </p>
        <p className="mt-5 mb-8">
        GlenService is the preferred choice of hundreds of thousands of Customers given its mammoth assortment of 500+ products, quick delivery even to theremotest corners of India.
        </p>
        <h1 className="font-bold text-2xl mb-10">
          Our <span className="text-primary">Story</span>
        </h1>
        <p className="mt-5 mb-8">
        GlenService is the shopping destination for Dentists across the country, Currently delivering to Haryana And Delhi NCR in India. Online Shopping – A Boon The trend of online shopping is becoming a household name and so is GlenService.
        </p>

        <h1 className="font-bold text-2xl mb-10">
          What we <span className="text-primary">offers</span>
        </h1>
        <p>
        Shopping online particularly at GlenService is a child’s play; all you need is a mobile phone or laptop or tablet with Internet connection to get started. Simply log into GlenService.in and browse through the wide assortment of products across categories. </p>
        <p className="mt-5 mb-8">
        Once you have zeroed in on your favourite products, simply place the order by filling in the details; the products will be delivered right at your doorstep. Shop on the Go – Install GlenService WebApp Today! You can shop for your favourite products at GlenService.in.
         </p>

        <ul className="list-disc">
          <li>
            <strong>
              Delhi NCR and Haryana+{" "}
              <span className="text-primary font-bold"> City</span>
            </strong>
          </li>

          <li>
            <strong>
              39+ <span className="text-primary font-bold"> Top Brands</span>
            </strong>
          </li>

          <li>
            <strong>
              500+ <span className="text-primary font-bold"> Products</span>
            </strong>
          </li>
        </ul>

        <h1 className="font-bold text-2xl mb-10 mt-10">
          Our Core <span className="text-primary">Values</span>
        </h1>
        <ul className="list-disc">
          <li>
            <strong>ETHICS</strong>
          </li>
        </ul>
        <p>
        We own our behaviour, keep our promises, value honesty, honor the community environment in which we operate and embrace diversity </p>
        <ul className="list-disc mt-4">
          <li>
            <strong>TEAM AND DEDICATION</strong>
          </li>
        </ul>
        <p>
        Dedication to team achieves best-in-class solutions in products, processes and people</p>
        <ul className="list-disc mt-4">
          <li>
            <strong>CUSTOMER FOCUS</strong>
          </li>
        </ul>
        <p>
        Customer satisfaction is at the center of what we do everyday.We are a trusted partner of our customers. </p>
        <ul className="list-disc mt-4">
          <li>
            <strong>EMPOWER AND DELIBRATE</strong>
          </li>
        </ul>
        <p>
        Employees are encouraged to take action and ensure timely delivery of expectations.
        </p>
        <ul className="list-disc mt-4">
          <li>
            <strong>SAFETY, QUALITY &amp; WELL BEING</strong>
          </li>
        </ul>
        <p>
        Exceed Industry standards for safety and performance by leveraging our core values.
        </p>
        <p>&nbsp;</p>
        <p></p>
        <p></p>

        <Footer />
      </div>
    </>
  );
};
export default AboutUS;
