/* eslint-disable */
import { profile } from "console";
import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { apiCAll } from "../api/api";
type sidebarProps = {
  className: string;
  profileDetails?: any;
  sideBar: (...args: unknown[]) => unknown;
};
const photo = window.location.origin + "/user.jpg";
const SideBar = ({
  className = "0",
  sideBar,
  profileDetails,
}: sidebarProps): JSX.Element => {
  let history = useHistory();
  const [NavBarwidth, setNavBarWidth] = useState(className);
  const [profilePic, setProfilePic] = useState(profileDetails["profile_image"]);
  // const [profilePic, setProfilePic] = useState(photo);

  const closeSideBar = () => {
    setNavBarWidth("0");

    sideBar(false);
  };

  const profilePicChange = (e) => {
    const authToken = localStorage.getItem("token") || "";

    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];

      setProfilePic(URL.createObjectURL(img));
      const formData = new FormData();
      formData.append("profile_image", e.target.files[0]);

      apiCAll(formData, "/profile_update", authToken).then((img) => {
        if (img?.data?.ErrorCode === 0) {
        }
      });
    }
  };

  const handleClick = () => {
    let element: HTMLElement = document.getElementById(
      "profilePic"
    ) as HTMLElement;
    element.click();
  };

  const logout = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <>
      <div
        className={` ${
          NavBarwidth > "0" ? "ModalOpen" : "ModalClosed"
        }  sidepanel bg-active shadow-primary h-full fixed z-50 top-0 left-0 overflow-x-hidden pt-10 `}
      >
        <a
          href="javascript:void(0)"
          className="closebtn absolute top-0 text-primary hover:opacity-75 right-6 text-4xl"
          onClick={closeSideBar}
        >
          ×
        </a>
        <div className="flex profile border-b-2 border-primary px-5 pb-6">
          <div className="w-20 h-20 md:w-36 md:h-36 relative shadow-primary rounded-full">
            {/* <img src={profilePic}  className="rounded-full w-full h-full" /> */}
            <img
              src={profilePic}
              alt="profile-icon"
              className="rounded-full w-full h-full"
            />

            <input
              type="file"
              onChange={profilePicChange}
              className="hidden"
              id="profilePic"
            />

            <span
              onClick={handleClick}
              className="edit-icon bg-base shadow-primary w-7 h-7 rounded-full absolute bottom-2 right-1 cursor-pointer flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </span>
          </div>
          <div className="user-details self-center ml-6 flex-1">
            <h2 className="text-primary font-bold text-md md:text-3xl">
              {profileDetails["name"]}
            </h2>
            <h3 className="text-sm">{profileDetails["email"]}</h3>
          </div>
        </div>
        <ul className="list px-5 pt-4">
          <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link to="/edit-profile">Profile</Link>
          </li>
          <li className="cursor-pointer text-primary font-500 mb-2  md:mb-5 md:text-xl">
            <Link
              to={{
                pathname: "/home/new-order",
                state: {
                  active: 1,
                },
              }}
            >
              <span onClick={closeSideBar}>New Order</span>
            </Link>
          </li>
          <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link
              to={{
                pathname: "/home/track-order",
                state: {
                  active: 2,
                },
              }}
            >
              <span onClick={closeSideBar}>Track Order</span>
            </Link>
          </li>
          <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link
              to={{
                pathname: "/home/complete-order",
                state: {
                  active: 3,
                },
              }}
            >
              <span onClick={closeSideBar}>Completed Order</span>
            </Link>
          </li>
          <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link
              to={{
                pathname: "/home/annual-return",
                // state: {
                //   active: 4,
                // },
              }}
            >
              <span onClick={closeSideBar}>Ok Spare Return</span>
            </Link>
          </li>
          <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link
              to={{
                pathname: "/home/GSMA-return",
                // state: {
                //   active: 4,
                // },
              }}
            >
              <span onClick={closeSideBar}>Warranty Defective Return</span>
            </Link>
          </li>
          <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link
              to={{
                pathname: "/contactus",
                state: {
                  profileDetails: profileDetails,
                },
              }}
            >
              Contact Us
            </Link>
          </li>
          {/* <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl"><Link to={{
                        pathname: "/return-replacement",
                        state: {
                            // profileDetails: profileDetails,


                        }
                    }}>Return & Replacement</Link></li> */}
          {/* <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">Notification</li> */}
          {/* <li className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl">
            <Link to="/support/glenService-product">Warranty Support</Link>
          </li> */}
          <li
            className="cursor-pointer text-primary font-500 mb-2 md:mb-5 md:text-xl"
            onClick={logout}
          >
            Logout
          </li>
        </ul>
      </div>
      <style jsx>
        {`
          .ModalOpen {
            animation: openModal 0.4s ease-out forwards;
          }

          .ModalClosed {
            animation: closeModal 0.4s ease-in forwards;
          }

          @keyframes openModal {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }

          @keyframes closeModal {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-100%);
            }
          }

          @media (min-width: 1100px) {
            .sidepanel {
              width: 30% !important;
            }
          }
          .sidepanel {
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default SideBar;
