import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiCAll } from "../api/api";

const Support = (): JSX.Element => {
    const authToken = localStorage.getItem("token") || "";
    const [warrantyProduct, setWarrantyProduct] = useState([]);
    const [productQuestion, setProductQuestion] = useState<any[]>([]);
    const [srList, setSRList] = useState([]);
    const existedWarrantyList = () => {

        apiCAll({ user_id: localStorage.getItem('user_id') }, "/warranty_product/list", authToken).then((x) => {
            if (x?.data?.ErrorCode === 0) {
                setWarrantyProduct(x?.data?.Response?.glenService_warranty_product);
                setProductQuestion(x?.data?.Response?.glenService_warranty_question);
            }
        });

    }
    const SRList = () => {
        apiCAll({}, "/warranty_request/list", authToken).then((x) => {
            if (x?.data?.ErrorCode === 0) {


                setSRList(x?.data?.Response.filter((X) => { return X.warranty_type !== 3 }));

            }
        });
    }
    useEffect(() => {
        existedWarrantyList();
        SRList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
                <div className="flex mx-2">
                    <Link to="/home" className="self-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M10 19l-7-7m0 0l7-7m-7 7h18"
                            />
                        </svg>
                    </Link>
                    <h3 className="text-2xl font-bold text-center py-4 flex-1">Warranty and Repair </h3>
                </div>
                <div className="bg-white p-4 px-6">
                    <div className="home-tabs mt-4">
                        <ul className="flex justify-between shadow-secondary text-center rounded-full ">
                            <Link to="/support/glenService-product" className="flex-1 p-2 py-3 sm:p-3 bg-primary text-white rounded-l-full ">   <li
                                className="  border-color text-xs sm:text-sm flex-1 hover: cursor-pointer
                  "

                            >
                              GlenService Product   
                              <p className="text-small">(Purchased on - GlenService.in)</p> 
                            </li>
                            </Link>
                            <Link to="/support/non-glenService-warranty" className="flex-1 p-2 py-3 sm:p-3"> <li
                                className=" border-color text-xs sm:text-sm flex-1 hover: cursor-pointer
                 text-black"

                            >
                               Other Products
                               <p className="text-small">(Purchased on- Other Platforms )</p> 
                               <p className="text-small">(This service is on chargeable basis)</p> 
                            </li>
                            </Link>

                        </ul>
                    </div>
                    <div className="content mt-4">
                        <div className="w-full mt-2">

                            <div className="shadow-md">
                                <div className="tab w-full overflow-hidden border-t">
                                    <input className="absolute opacity-0" id="tab-single-two" type="radio" name="tabs2" />
                                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-single-two">Your Service Requests</label>
                                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-primary leading-normal">
                                        <ul className="search-suggestions mt-3 ml-1">
                                            {srList && srList.map((product, index): any => (


                                                <li className="bg-white flex border my-4 py-2 px-4 justify-between hover:bg-pink-50 cursor-pointer hover:shadow-secondary">
                                                    <div className=" self-center">
                                                        <p>Service Request: <b>{product["request_no"]}</b></p>
                                                        <p>Name: <b>{product["item_name"]}</b></p>
                                                        <p>Quantity: <b>{product["quantity"]}</b></p>
                                                        <p>Reason: <div dangerouslySetInnerHTML={{ __html: product["reason"] }} /></p>                                                        
                                                        <p>Status: <b>{product["status"]}</b></p>
                                                        <p>Total: <b>{product["total"]}</b></p>
                                                        <p>Service Charge: <b>{product["service_charge"]}</b> </p>
                                                    </div>

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab w-full overflow-hidden border-t">
                                    <input className="absolute opacity-0" id="tab-single-one" type="radio" name="tabs2" />
                                    <label className="block p-5 leading-normal cursor-pointer" htmlFor="tab-single-one">Your Product List</label>
                                    <div className="tab-content overflow-hidden border-l-2 bg-gray-100 border-primary leading-normal">
                                        <ul className="search-suggestions mt-3 ml-1">

                                            {warrantyProduct && warrantyProduct.map((product, index): any => (


                                                <li className="bg-white text-xs sm:text-sm  pb-8 flex flex-col  md:flex-row  border my-4 py-2 px-4 justify-between hover:bg-pink-50 cursor-pointer hover:shadow-secondary">
                                                    <div className=" self-center">
                                                        <p className="py-1">Product Name: <b>{product["item_name"]}</b></p>
                                                        <p className="py-1">Brand Name: <b>{product["brand_name"]}</b></p>
                                                        <p className="py-1">Order Id: <b>{product["order_number"]}</b></p>
                                                        <p className="py-1">Purchased Date: <b>{product["orderdate"]}</b></p>
                                                        <p className="py-1">Warranty Tenure: <b>{product["warranty_month"]} Months</b></p>
                                                        <p className="py-1">Warranty Expires On: <b>{product["warranty_tenure"]} </b></p>
                                                        <p className="py-1">Warranty Status: <b>{product["warranty_type"] === 1 ? "In Warranty" : "Out of warranty"}</b></p>
                                                       
                                                    </div>
                                                    <div className="self-center mt-5 ">
                                                       
                                                        <Link
                                                            to={{
                                                                pathname: "/support/IW/Initate",
                                                                state: {
                                                                    active: 0,
                                                                    orderDetails: product,
                                                                    productQuestion: productQuestion,
                                                                    order_id: product["order_id"]

                                                                }
                                                            }}
                                                            className="rounded-full w-full  px-20 sm:px-8  text-md py-2.5 text-black border  filter drop-shadow-sm hover:shadow-lg hover:border-gray-400">Initate</Link>
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                 .tab-content {
                    
                    max-height: 0;
                    -webkit-transition: max-height .35s;
                    -o-transition: max-height .35s;
                    transition: max-height .35s;
                    }
                    /* :checked - resize to full height */
                    .tab input:checked ~ .tab-content {
                    max-height: 100vh;
                    }
                    /* Label formatting when open */
                    .tab input:checked + label{
                    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
                    font-size: 1.25rem; /*.text-xl*/
                    padding: 1.25rem; /*.p-5*/
                    border-left-width: 2px; /*.border-l-2*/
                    border-color: #9C1E60; /*.border-indigo*/
                    background-color: #f8fafc; /*.bg-gray-100 */
                    color: #9C1E60; /*.text-indigo*/
                    }
                    /* Icon */
                    .tab label::after {
                    float:right;
                    right: 0;
                    top: 0;
                    display: block;
                    width: 1.5em;
                    height: 1.5em;
                    line-height: 1.5;
                    font-size: 1.25rem;
                    text-align: center;
                    -webkit-transition: all .35s;
                    -o-transition: all .35s;
                    transition: all .35s;
                    }
        .tab input[type=checkbox] + label::after {
            content: "+";
            font-weight:bold; /*.font-bold*/
            border-width: 1px; /*.border*/
            border-radius: 9999px; /*.rounded-full */
            border-color: #b8c2cc; /*.border-grey*/
            }
                 .tab input[type=radio] + label::after {
                    content: "+";
                    font-weight:bold; /*.font-bold*/
                    border-width: 1px; /*.border*/
                    border-radius: 9999px; /*.rounded-full */
                    border-color: #b8c2cc; /*.border-grey*/
                    }
                 .tab input[type=checkbox]:checked + label::after {
                    transform: rotate(315deg);
                    background-color: #9C1E60; /*.bg-indigo*/
                    color: #f8fafc; /*.text-grey-lightest*/
                    }                            
                   
                .tab input[type=radio]:checked + label::after {
                    transform: rotatez(225deg);
                    background-color: #9C1E60; /*.bg-indigo*/
                    color: #f8fafc; /*.text-grey-lightest*/
                    }
                `}
            </style>

        </>

    );
}

export default Support;