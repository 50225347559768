import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { apiCAll } from "../api/api";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { Loader } from "../shared-components";

const AnnualReturnList = (): JSX.Element => {
  const [annualReturnData, setAnnualReturnData] = useState([]);
  const [annualReturnItems, setAnnualReturnItems] = useState([]) as any;
  const [showLoader, setShowLoader] = useState(false);
  const [returnRequestData, setReturnRequestData] = useState([]);
  const [fromDate, setFromData] = useState("");
  const [toDate, setToData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [counter, setCounter] = useState({});
  const authToken = localStorage.getItem("token") || "";

  useEffect(() => {
    handleAnnualReturn();
  }, []);

  const history = useHistory();
  const handleBack = () => {
    history.push("/home");
  };

  const handleAnnualReturn = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-items-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            console.log(x?.data?.Response);
            let response = x?.data?.Response;
            response = Object.values(response);
            setAnnualReturnData(response);
          }
          resolve("success");
        })
        .catch((err) => {
          reject("error");
          console.log(err);
        });
    });

    if (myPromise === "success") {
      handleAnnualReturnRequest();
    }
  };

  const handleAnnualReturnRequest = async () => {
    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll({}, "/annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setReturnRequestData(x?.data?.Response);
          }
          resolve("success");
        })

        .catch((err) => {
          console.log(err);
        });
    });
  };
  const filteredList = async () => {
    setShowLoader(true);
    const myPromise = await new Promise<String>((resolve, reject) => {
      const obj = { from_date: fromDate, to_date: toDate };
      apiCAll(obj, "/annual-return-list", authToken)
        .then((x) => {
          if (x.status === 200) {
            setShowLoader(false);
            setReturnRequestData(x?.data?.Response);
          }
          resolve("success");
        })

        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });
    });
  };

  useEffect(() => {
    let count = {};
    for (var input = 0; input <= annualReturnData?.length; input++) {
      count[input] = 0;
    }

    setCounter(count);

    console.log(counter);
  }, [annualReturnData]);

  const addToReturnCart = async (item: any, index) => {
    const obj = {
      order_id: item.order_id,
      product_id: counter[index],
      quantity: item.quantity,
    };

    const myPromise = await new Promise<String>((resolve, reject) => {
      apiCAll(obj, "/return-cart-add", authToken)
        .then((x) => {
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const increment = (product, index) => {
    console.log(counter);

    if (product?.quantity <= counter[index]) {
      setCounter({ ...counter, [index]: counter[index] });
    } else {
      setCounter({ ...counter, [index]: counter[index] + 1 });
    }

    addToReturnCart(product, index);
  };

  const decrement = (product, index) => {
    if (counter[index] === 0) {
      setCounter({ ...counter, [index]: counter[index] });
    } else {
      setCounter({ ...counter, [index]: counter[index] - 1 });
    }
    addToReturnCart(product, index);
  };

  const updateEditCount = (product, index) => {
    setCounter({ ...counter, [index]: Number(inputValue) });

    addToReturnCart(product, index);
  };

  const returnSpareRequest = async () => {
    var obj = {
      annual_return_items: annualReturnData,
    };

    const mypromise = await new Promise<String>((resolve, response) => {
      apiCAll(obj, "/return-placeorder", authToken)
        .then((x) => {
          if (x.data.ErrorCode === 103) {
            // setResponseMsg(x.data.ErrorMessage +" Request No is " +x.data.Response.request_no)
          }
          resolve("success");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDataFilter = (e) => {
    if (e) {
      const copyAnnualReturnData = [...annualReturnData];
      const data = copyAnnualReturnData?.filter((val: any, index) =>
        e ? 
        (val?.product_name?.toLowerCase().indexOf(e.toLowerCase()) >= 0 || val?.product_code?.indexOf(e) >= 0)
        : true 
      );
      setAnnualReturnData(data);
    } else {
      handleAnnualReturn();
    }
  };

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="annual-return">
        <div className="flex mx-2">
          <button
            className="self-center"
            onClick={handleBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h3 className="text-2xl font-bold text-center py-4 flex-1">
            Ok Spare Request List
          </h3>
        </div>

        <div className="bg-white pt-4">
          <div className="py-10 flex justify-center">
            <div className="bg-white w-8/12 justify-left">
              <label className="w-1/6 rounded input_style font-bold ">
                From Date
              </label>
              <input
                type="date"
                className="border w-1/4 rounded px-12 input_style"
                onChange={(e) => setFromData(e.target.value)}
              />
              <label className="w-1/6 rounded input_style font-bold">
                To Date
              </label>
              <input
                type="date"
                className="border w-1/4 rounded px-12 input_style"
                onChange={(e) => setToData(e.target.value)}
              />
              <button
                type="button"
                className={`px-8 text-sm rounded bg-primary text-white font-semibold py-2 mt-5 d-flex content-center w-1/6 rounded input_style`}
                onClick={filteredList}
              >
                Filter
              </button>
              <div className="text-right">
                <Link
                  className="text-sm hyperlink underline cursor-pointer"
                  to={{
                    pathname: "/home/annual-return",
                  }}
                >
                  Ok Spare Items List
                </Link>
              </div>
            </div>
          </div>

          <div className="pb-10 flex justify-center overflow-auto">
            <table className="shadow-primary bg-white w-8/12">
              {annualReturnData?.length ? (
                <tr>
                  {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Sr.No.
                    </th>
                  ) : null}

                  {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Request No.
                    </th>
                  ) : null}

                  {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Quantity
                    </th>
                  ) : null}

                  {/* {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Reason
                    </th>
                  ) : null}

                  {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Remark
                    </th>
                  ) : null} */}

                  {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Request Date
                    </th>
                  ) : null}

                  {returnRequestData ? (
                    <th className="border text-left px-4 py-2 text-sm">
                      Status
                    </th>
                  ) : null}
                </tr>
              ) : null}
              {returnRequestData.map((item: any, index) => (
                <tr
                  className="tabe-row"
                  key={index}
                >
                  <td className="border px-4 py-2 text-sm ">{index + 1} </td>
                  <td
                    className="border px-4 py-2 text-sm cursor-pointer"
                    onClick={() => {
                      history.push({
                        pathname: `/home/annual-return/${item.request_no}`,
                        // state: item.items
                      });
                    }}
                  >
                    <p className="m-0 link_text">
                      {item.request_no ? item.request_no : "---"}
                    </p>
                  </td>
                  <td className="border px-4 py-2 text-sm ">
                    {item.quantity ? item.quantity : "---"}{" "}
                  </td>
                  {/* <td className="border px-4 py-2 text-sm ">
                    {item.reason ? item.reason : "---"}{" "}
                  </td>
                  <td className="border px-4 py-2 text-sm ">
                    {item.qc_remark ? item.qc_remark : "---"}{" "}
                  </td> */}
                  <td className="border px-4 py-2 text-sm ">
                    {item.request_date ? item.request_date : "---"}{" "}
                  </td>
                  <td className="border px-4 py-2 text-sm ">
                    {item.status ? item.status : "---"}{" "}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .hyperlink {
            color: #0211c0;
          }
          tr:nth-child(even) {
            // background: rgba(231, 139, 188, 0.29);
            background: rgba(17, 149, 245, 0.2);
          }
          .border {
            border-color: #c3c3c3;
          }
          .popup-content {
            border-radius: 10px;
            width: 40%;
          }
          .border-color {
            border-color: #c3c3c3;
          }
          .link_text {
            color: #0211c0;
          }
          .input_style {
            height: 40px;
            line-height: 25px;
            margin-right: 10px;
          }
          @media screen and (max-width: 640px) {
            .popup-content {
              width: 80%;
            }
          }
        `}
      </style>
    </>
  );
};

export default AnnualReturnList;
