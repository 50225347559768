/* eslint-disable */
import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import { apiCAll } from "../api/api";
import {
  INPUT_KEYS_NOT_ALLOWED,
  REGEX_ONLY_DIGITS,
  REGEX_ONLY_DIGITS_NO,
  REGEX_ONLY_ALPHA,
  REGEX_ONLY_EMAIL,
} from "../api/constants";
import { Footer, Loader } from "../shared-components";

const data = [
  {
    label: "Keep me signed in on this device",
    linkName: "",
    link: "",
  },
  // {
  //   label: "You grant opt-in on WhatsApp to GlenService and partners",
  //   linkName: "",
  //   link: "",
  // },

  {
    label: "By Logging in you agree to our",
    linkName: " Privacy Policy",
    link: "/privacy-policy",
  },

  // {
  //   label: "By Logging in you agree to our",
  //   linkName: " Returns and Refund Policy",
  //   link: "/return-refund-policy",
  // },
  // {
  //   label: "By Logging in you agree to our",
  //   linkName: " Terms & Condition",
  //   link: "/terms-and-condition",
  // },
];

type LoginProps = {
  defaultValue?: any;
};
export const Login = ({ defaultValue = [] }: LoginProps): JSX.Element => {
  const [inputValue, setInputValue] = useState("");
  const [PasswordValue, setPasswordValue] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(' Password should have atleast 4 characters.')
  const [showPwdError, setShowPwdError] = useState(false);
  const [disable, setDisable] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  let history = useHistory();
  const goToRegistration = () => {

    if (PasswordValue.length > 3) {

      setShowLoader(true);

      let obj: any = {
        email: inputValue,
        password: PasswordValue,
      };

      apiCAll(obj, "/login-with-email").then(async (x) => {
        let data = await x.data;
        if (data) {
          if (data?.ErrorCode === 0 && data?.ErrorMessage == "success") {
            setShowLoader(false);
            localStorage.setItem("email", inputValue);
            localStorage.setItem("token", data?.token);
            history.push("/home");
          }
          else if (data?.ErrorCode === 1 ) {
            setShowLoader(false);
            setShowPwdError(true)
            setErrorMsg(data?.Response)
          }

        }

      });
    }


  };


  const onInputKeyDown = (event) => {

    const {
      target: { value, selectionStart },
      key,
    } = event;

    if (!REGEX_ONLY_EMAIL.test(key + value)) {
      setError(true);
      setInputValue(value)
    }
    else {
      setError(false);
      setInputValue(value)
    }
  };

  // !error || PasswordValue.length > 3 ? setDisable(false) : ''

  const changeHandler = (event) => {
    const { value } = event.target;

    // setInputValue(value);
    onInputKeyDown(event)

  };
  const [testOtp, setTestOtp] = useState("");

  const sendOTP = () => {
    let data = {
      email: inputValue,
    };

    setShowLoader(true);

    apiCAll(data, "/otp").then((x) => {
      setTestOtp(x?.data?.Response?.otp);

      if (x.status === 200) {
        if (x.data.ErrorCode === 100) {
          setShowOTP(true);
          setShowLoader(false);
        }
      }
    });
  };


  const focusNextSibling = (e) => {
    const getId = e.target.id;
    const ref = document.getElementById(getId);
    const nextSibling =
      ref?.parentElement?.nextElementSibling || ref?.parentElement;
    const elementWithInput = nextSibling?.querySelectorAll("input");
    if (e.target?.value?.length >= "1") {
      elementWithInput?.[0].focus();
    }
  };

  const [checkedState, setCheckedState] = useState(
    new Array(data.length).fill(true)
  );
  const [showLogin, setShowLogin] = useState(true);
  const checkboxChangehandler = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    if (updatedCheckedState.includes(false)) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     history.push("/home");
  //   }
  // }, [showOTP, otpInputValue]);

  return (
    <>
      {showLoader ? <Loader /> : null}
      <div className="login-page mt-10">
        <header>
          <h3 className="text-2xl text-center text-primary">
            <img
              src="glen-logo.png"
              alt="GlenService"
              style={{ width: "100px", display: "inline-block" }}
              className="rounded-full w-full h-full mb-5"
            />
          </h3>
          <h3 className="text-center font-bold text-xl md:text-2xl mb-6">
            A Dedicated Marketplace Only for Glen Service Providers
          </h3>
          {/* text-gray-600  */}
          {/* <ul className="mx-5 md:w-2/6 my-0 md:mx-auto text-sm ">
            <p className="my-3">
              All glen service products delivered to doorsteps in 2-3 hours
            </p>
            <li>1. Login with WhatsApp Number</li>
            <li>2. Add items to the cart</li>
            <li>3. Choose Cash On Delivery or Make Payment</li>
            <li>4. Get the products delivered in max 3 hours</li>
          </ul> */}
        </header>
        <div className="login-section mt-5 text-center mx-5 md:w-2/6 my-0 md:mx-auto">
          <form>
            <div className="email-field flex flex-col text-left">
              <label className="text-xs mb-4">Email*</label>
              <input
                className="email bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                placeholder="Enter your email*"
                value={inputValue}
                onChange={changeHandler}
                // onKeyPress={onInputKeyDown}
                type="email"
              />
              {error ? (
                <p className="text-xs mt-2 text-red-600">Enter a valid email.</p>
              ) : null}
            </div>

            <div className="email-number flex flex-col text-left mt-5">
              <label className="text-xs mb-4">Password*</label>
              <input
                className="email bg-white rounded-full h-10 shadow-primary placeholder-black placeholder-opacity-40 text-xs focus:outline-none p-4"
                placeholder="Enter your Password*"
                type="password"
                value={PasswordValue}
                onChange={(e) =>
                  setPasswordValue(e?.target?.value)
                }
              />
              <Link className="hyperlink text-xxs flex justify-around sm:justify-between text-primary font-bold flex-wrap my-1" to={`/forget-password/`}>
                Forget Password?
              </Link>              

              {(showPwdError) || (PasswordValue && PasswordValue.length < 4) ? (
                <p className="text-xs mt-2 text-red-600">
                  {errorMsg}
                </p>
              ) : null}
            </div>
          </form>
          <div className="text-left my-4 ml-5">
            <ul className="list-disc text-sm">
              <li>Faster ordering & shipping</li>
              {/* <li>Products repair available</li> */}
              <li> End to end delivery tracking for your orders</li>
              {/* <li>No minimum order</li> */}
            </ul>
          </div>
          <div className="login-subscription text-left mt-6">
            {data.map((item, index) => (
              <div className="w-full flex" key={index}>
                <div className="checkbox py-1 flex items-center">
                  <input
                    type="checkbox"
                    id={`checkbox${index + 1}`}
                    checked={checkedState[index]}
                    onChange={() => checkboxChangehandler(index)}
                    className="mr-3 cursor-pointer"
                  />
                  <label
                    className="text-sm cursor-pointer"
                    htmlFor={`checkbox${index + 1}`}
                  >
                    {item.label}
                    <Link to={item.link} target="_blank">
                      <b className="underline">{item.linkName}</b>
                    </Link>
                  </label>
                </div>
              </div>
            ))}

            <div className="button-list mt-6 flex flex-col">
              <button
                className={`login rounded-full bg-primary text-md h-12 text-white ${error || PasswordValue.length < 4 ? "disabled:opacity-50" : ""
                  }`}
                disabled={error || PasswordValue.length < 4}
                onClick={() => {
                  // history.push("/home")
                  // console.log("doneeeeee")
                  goToRegistration()
                }}
              >
                LOGIN
              </button>
              {/* <a
                href="#"
                className="contact-support rounded-full bg-white text-md py-3 text-center mt-16 font-bold shadow-secondary text-primary"
              >
                Contact us on WhatsApp
              </a> */}
              <Footer />
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
  .showOtp{
    
  
    display:block;
    animation: 0.4s slide-right  ease;
    
  }
  .hideOtp{
     visibility:hidden;
  }
  @keyframes slide-right {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0;
    }
  }

  `}
      </style>
    </>
  );
};

export default Login;
