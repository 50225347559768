import { Link } from "react-router-dom";
const selectOption = [
  {
    name: "COD",
    value: "COD",
    id: "1",
  },
  {
    name: "Pre-Paid",
    value: "Pre-Paid",
    id: "2",
  },
];

const Success = (props): JSX.Element => {
  return (
    <>
      <div className="m-3 sm:m-5">
        <Link to="/home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </Link>
        <div className="success  text-center mt-20">
          <img
            src={window.location.origin + "/green-tick.svg"}
            alt="Loader"
            className=" w-12 m-auto mb-3"
          />
          <h2 className="text-2xl mb-10 font-bold">Order Confirmation</h2>
          <h2 className="text-xl mb-2 font-bold">Thank you for your order.</h2>
          {/* <p className="mb-2">
            We are following up with the store to get your order accepted
          </p> */}
          {props.location.state?.orderid ? (
            <p className="font-bold">
              Order Id:{" "}
              <span className="ml-3"> {props.location.state?.orderid}</span>
            </p>
          ) : null}
          {props.location.state?.transactionId ? (
            <p className="font-bold">
              Transaction Id:{" "}
              <span className="ml-3">
                {" "}
                {props.location.state?.transactionId}
              </span>
            </p>
          ) : null}
          <Link to="/home">
            <button className=" rounded-full bg-primary text-md h-10 mt-4 mb-1 text-white w-1/2 sm:w-1/4">
              {" "}
              Continue Shopping
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Success;
