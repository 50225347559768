import { Footer } from "../shared-components"

const ReturnRefundCondition = ():JSX.Element => {
    return(
        <>
            <div className="px-4  lg:w-1/2 my-0 md:mx-auto">
 
            <p><strong>RETURN, REFUND, CANCELLATION, AND SHIPPING CHARGES:</strong></p>
<p>We offer returns and refund on the products and services ordered by You on the Website which are subject to further terms and conditions as detailed in the cancellation, shipping charges, returns, and refund policy (&ldquo;Return and Refund Policy&rdquo;). The&nbsp;Return and Refund Policy&nbsp;forms an integral part of these Terms and the Users are requested to carefully read the same.</p>
<p>&nbsp;</p>
<p><strong>Cancellation Policy</strong></p>
<ol>
<li><strong> How can I cancel an order?</strong></li>
</ol>
<p>1.1&nbsp;<u>Equipment</u></p>
<p>Order for healthcare products can be canceled from the &ldquo;Order Page&rdquo; screen on the GlenService website/ app (&lsquo;Platform&rsquo;) or by contacting our Customer Support before it is marked as &ldquo;Accepted&rdquo;. Else, you can refuse it at the time of delivery and a refund will be processed as per the Refund Policy.</p>
<p>In any case of exceptions, the company can cancel the order, the company will not be answerable for it.</p>
<p><strong><u>Return Policy</u></strong></p>
<ol aria-start="2">
<li><strong> What is the time period for the return of orders?</strong></li>
</ol>
<p>1.1&nbsp;<u>Equipment</u></p>
<p>We have a product-specific return policy, return window may differ for product wise. The return window is 10 days from the date of delivery. The information regarding the return policy can be found on the sign-in page. Please check the return policy before placing an order.</p>
<p><em>[Note- Currently, the Company is not charging any shipping charges for returns from you.]</em></p>
<p>&nbsp;</p>
<p><strong>Additional Note &ndash;</strong></p>
<ol aria-start="3">
<li><strong> Items whose return window has expired cannot be returned.</strong></li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>Refund Policy</u></strong></p>
<ol>
<li><strong> When can I expect the amount to be refunded?</strong></li>
</ol>
<p>1.1 Please note that the refund amount mentioned at the time of return on the Platform is an estimate and will be finalized after completion of verification. Once the pick-up is completed and the product is verified by the enterprise, a refund of the total amount paid for the products returned by you will be initiated for eligible returns as mentioned above.</p>
<p>1.2 Refund will be initiated only post successful verification of the products by the Enterprise. Please note that the verification process may take:</p>
<p>For Local Cities*: 48 hours from the time of pick up from your location.</p>
<p>&nbsp;</p>

<p>1.3 You understand and acknowledge that we reserve the right to claim back any refund that has been processed by us as a result of fraudulent activities such as the return of wrong products or the wrong quantity of the products.</p>
<p>&nbsp;</p>

<Footer />
</div>
        </>
    )
}
export default ReturnRefundCondition;